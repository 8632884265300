const formatQueryString = (queryObject) => {
  const queryEntries = Object.entries(queryObject);
  const queryParams = new URLSearchParams();

  const filteredQueryEntries = queryEntries.filter((queryEntry) => {
    if (typeof queryEntry[1] === 'undefined' || queryEntry[1] === null) {
      return false;
    }
    if (typeof queryEntry[1] === 'string' && queryEntry[1].trim().length === 0) {
      return false;
    }
    if (Array.isArray(queryEntry[1]) && queryEntry[1].length === 0) {
      return false;
    }
    return true;
  });

  filteredQueryEntries.forEach((queryEntry) => {
    if (Array.isArray(queryEntry[1])) {
      queryEntry[1].forEach((queryEntryItem) => {
        if (typeof queryEntryItem === 'string' && queryEntryItem.trim().length > 0) {
          queryParams.append(queryEntry[0], queryEntryItem);
        }
      });
    } else {
      queryParams.append(queryEntry[0], queryEntry[1]);
    }
  });
  const queryString = `?${queryParams.toString()}`;

  return queryString;
};

export { formatQueryString };
