import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Step.styles';

const Step = ({ name, isComplete, stepNumber }) => {
  return (
    <S.Container>
      {isComplete ? <S.SuccessIcon /> : <S.Number>{stepNumber}</S.Number>}
      <S.Name>{name}</S.Name>
    </S.Container>
  );
};

Step.propTypes = {
  name: PropTypes.string,
  isComplete: PropTypes.bool,
  stepNumber: PropTypes.string,
};

export default Step;
