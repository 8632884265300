import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Loader.styles';

const Loader = ({ size, paddingY }) => {
  return (
    <S.Container paddingY={paddingY}>
      <S.Spinner size={size} />
    </S.Container>
  );
};

Loader.propTypes = {
  size: PropTypes.number.isRequired,
  paddingY: PropTypes.string,
};

export default Loader;
