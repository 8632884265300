import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../routes/utils';

class NetworkErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    if (process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' && error.name === 'ChunkLoadError') {
      return {
        hasError: true,
      };
    }

    return {
      hasError: false,
    };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <Redirect to={ROUTES.NO_INTERNET_CONNECTION} />;
    }

    return children;
  }
}

NetworkErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(NetworkErrorBoundary);
