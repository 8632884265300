import React from 'react';
import PropTypes from 'prop-types';
import * as S from './LoadMoreButton.styles';

const LoadMoreButton = ({ onClick, isLoading }) => {
  return (
    <S.Button onClick={onClick} disabled={isLoading}>
      {!isLoading && <S.Text>Carregar mais</S.Text>}
      {isLoading && <S.Spinner />}
    </S.Button>
  );
};

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
