import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfirmationModal, Loader, SellerList } from '../../shared/components';
import { Footer, Header } from './components';
import { selectCart } from '../../store/utils';
import {
  clearCartErrors,
  addProductToRemoteCart,
  removeProductFromRemoteCart,
} from '../../store/ducks/cart';
import { isPending, isRejected } from '../../libs/responseStatus';
import {
  tagBagButtonClick,
  tagProductRemovalModalButtonClick,
  tagAdditionOfProductToCart,
  tagRemovalOfProductFromCart,
} from '../../gtm';
import { ROUTES } from '../../routes/utils';
import * as S from './BagDrawer.styles';
import { useGtagProduct } from '../../gtm/ga4/hooks/useGtagProduct';
import { tagSelectContentModalSacola } from '../../gtm/ga4/events';
import { useModal } from '../../shared/hooks/useModal';

const BagDrawer = ({ open, onClose }) => {
  // Initial values
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    sellers,
    productsPrice,
    cartStatus,
    cartError,
    totalPrice,
    hasDiscount,
    discountPrice,
    productsQuantity,
  } = useSelector(selectCart);
  const [relevantProduct, setRelevantProduct] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [exceededModalData, setExceededModalData, unsetExceededModalData] = useModal();

  // tagueamento
  const { handleGtagAddToCart, handleGtagRemoveFromCart } = useGtagProduct();

  // Closes the bag drawer
  const handleCloseDrawerIconClick = () => {
    tagBagButtonClick(false, 'Fechar');
    onClose();
  };

  // Closes the confirmation modal
  const handleClose = () => {
    tagProductRemovalModalButtonClick('Fechar');
    setIsConfirmationModalOpen(false);
  };

  // Cancels product removal
  const handleCancel = () => {
    tagProductRemovalModalButtonClick('Cancelar');
    setIsConfirmationModalOpen(false);
  };

  // Confirms product removal
  const handleConfirm = () => {
    tagProductRemovalModalButtonClick('Remover');
    setIsConfirmationModalOpen(false);
    const { product, seller } = relevantProduct;
    handleGtagRemoveFromCart(product, seller, 'code', 0);
    tagRemovalOfProductFromCart(product, seller, product.quantity);
    dispatch(removeProductFromRemoteCart(product.sku, seller.id, product.quantity));
  };

  // Increments the quantity of the product
  const handleIncrement = (productToIncrement, quantity = 1) => {
    tagBagButtonClick(false, 'Aumentar quantidade de item');
    const { product, seller } = productToIncrement;
    handleGtagAddToCart(product, seller, 'code', product.quantity + 1);
    tagAdditionOfProductToCart(product, seller, 1);
    dispatch(addProductToRemoteCart(product.sku, seller.id, quantity));
  };

  // Decrements the quantity of the product
  const handleDecrement = (productToDecrement, quantity) => {
    tagBagButtonClick(false, 'Diminuir quantidade de item');
    const { product, seller } = productToDecrement;
    handleGtagRemoveFromCart(product, seller, 'code', product.quantity - 1);
    tagRemovalOfProductFromCart(product, seller, 1);
    dispatch(removeProductFromRemoteCart(product.sku, seller.id, quantity));
  };

  // Removes the product with confirmation
  const handleRemovalWithConfirmation = (productToRemove) => {
    tagBagButtonClick(false, 'Remover item');
    setIsConfirmationModalOpen(true);
    setRelevantProduct(productToRemove);
  };

  // Closes the alert by clearing all errors in the cart
  const handleAlertClose = () => {
    dispatch(clearCartErrors());
  };

  // Closes the bag drawer
  const handleKeepBuying = () => {
    tagBagButtonClick(false, 'Continuar comprando');
    tagSelectContentModalSacola({
      content_type: 'Continuar comprando',
    });
    onClose();
  };

  // Closes the bag drawer and navigates to "/checkout"
  const handleProceedToCheckout = () => {
    tagBagButtonClick(false, 'Finalizar compra');
    tagSelectContentModalSacola({
      content_type: 'Finalizar compra',
    });
    onClose();
    history.push(ROUTES.CHECKOUT);
  };

  return (
    <S.Container open={open} onClose={onClose}>
      {isPending(cartStatus) && <Loader size={80} />}
      {!isPending(cartStatus) &&
        (sellers.length > 0 ? (
          <>
            <ConfirmationModal
              open={!!exceededModalData}
              onClose={unsetExceededModalData}
              message={`Infelizmente só temos ${exceededModalData?.remainingUnits} em estoque`}
              cancelButtonLabel="Cancelar"
              confirmationButtonLabel="Ok"
              cancelButtonCallback={handleCancel}
              confirmationButtonCallback={unsetExceededModalData}
              disableButtonCancel={true}
            />
            <ConfirmationModal
              open={isConfirmationModalOpen}
              onClose={handleClose}
              message="Tem certeza de que quer remover esse item da sua sacola?"
              cancelButtonLabel="Cancelar"
              confirmationButtonLabel="Remover"
              cancelButtonCallback={handleCancel}
              confirmationButtonCallback={handleConfirm}
            />
            <Header onCloseDrawerIconClick={handleCloseDrawerIconClick} />
            <SellerList
              sellers={sellers}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
              onRemovalWithConfirmation={handleRemovalWithConfirmation}
              onReachMax={setExceededModalData}
            />
            <S.Divider />
            {isRejected(cartStatus) && (
              <S.ErrorAlert onClose={handleAlertClose}>
                <S.ErrorAlertTitle>Ocorreu um erro inesperado!</S.ErrorAlertTitle>
                {cartError?.message && cartError.message}
              </S.ErrorAlert>
            )}
            <Footer
              totalPrice={totalPrice}
              productsPrice={productsPrice}
              discountPrice={hasDiscount && discountPrice}
              onKeepBuying={handleKeepBuying}
              productsQuantity={productsQuantity}
              onProceedToCheckout={handleProceedToCheckout}
            />
          </>
        ) : (
          <>
            <Header onCloseDrawerIconClick={handleCloseDrawerIconClick} />
            <S.MessageContainer>
              <S.Message>Você não tem itens na sua sacola</S.Message>
            </S.MessageContainer>
          </>
        ))}
    </S.Container>
  );
};

BagDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BagDrawer;
