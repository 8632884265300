import React from 'react';
import { ReactComponent as NoResultsIcon } from '../../../assets/images/vector/noResultsIcon.svg';
import * as S from './NoResults.styles';

const NoResults = () => {
  return (
    <S.Container>
      <S.IconContainer>
        <NoResultsIcon />
      </S.IconContainer>
      <S.Title>Não encontramos nenhum resultado para a sua pesquisa</S.Title>
      <S.Description>Por favor, tente novamente</S.Description>
    </S.Container>
  );
};

export default NoResults;
