import React from 'react';
import PropTypes from 'prop-types';
import * as S from './RemainingUnits.styles';
import { ReactComponent as FireIcon } from '../../../assets/images/vector/Fire.svg';

const RemainingUnits = ({ message }) => {
  if (!message) return <></>;
  return (
    <S.Container>
      <FireIcon />
      <S.Message>{message}</S.Message>
    </S.Container>
  );
};

RemainingUnits.propTypes = {
  message: PropTypes.string.isRequired,
};

export default RemainingUnits;
