import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../../../assets/images/vector/checkIcon.svg';

const Container = styled.div`
  display: flex;
`;

const SuccessIcon = styled(CheckIcon)`
  width: 26px;

  path {
    fill: #008f46;
  }
`;

const Number = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 5px;
  border: 1px solid #999999;
  border-radius: 26px;
  font-size: 19px;
  text-align: center;
  color: #999999;
`;

const Name = styled.div`
  margin: 0 5px 0 5px;
  font-size: 19px;
  color: #717171;
`;

export { Container, SuccessIcon, Number, Name };
