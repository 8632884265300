import { removeDuplicateTerm } from './dedupe';
import { getTagsText } from './getTagsText';

export function listItemProductTagsWithSellers(sellers, splitter = ':') {
  const tags = sellers
    .reduce(
      (acc, curr) =>
        acc +
        // eslint-disable-next-line no-shadow
        curr.items.reduce((acc, { product }) => {
          const text = getTagsText(product.tags);
          return acc + (text ? `${text}${splitter}` : '');
        }, ''),
      '',
    )
    ?.replace(/:$/, '')
    ?.toLowerCase();
  return tags === '' ? undefined : removeDuplicateTerm(tags, splitter, /:$/);
}
