import styled from 'styled-components';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const Button = styled.button.attrs({
  type: 'button',
})`
  width: 300px;
  height: 54px;
  background-color: #000000;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
`;

const Text = styled.span`
  font-family: 'Arboria', sans-serif;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
`;

const Spinner = styled(MuiCircularProgress).attrs({
  size: 36,
  color: 'secondary',
})``;

export { Button, Text, Spinner };
