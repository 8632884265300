import SessionStorage from '../../../libs/sessionStorage';

const keycloakInterceptorSuccess = (config) => {
  const keycloakToken = SessionStorage.getItem('keycloakTokens')?.token;
  if (keycloakToken) {
    config.headers.Authorization = `Bearer ${keycloakToken}`;
  }

  return config;
};

const keycloakInterceptorError = (error) => {
  return Promise.reject(error);
};

const setupInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(keycloakInterceptorSuccess, keycloakInterceptorError);
};

export { keycloakInterceptorSuccess, keycloakInterceptorError, setupInterceptors };
