import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl, getColors, getSellerDisplayName } from '../../utils';
import * as S from './ProductCard.styles';
import { ColorsList } from './ColorsList/ColorsList';

const ProductCard = ({ product, onClick }) => {
  const imageUrl = getImageUrl(product?.medias, 'portrait', 200);
  const colors = getColors(product?.variationsGroup || product?.variations);

  const sellerDisplayName = getSellerDisplayName(product?.sellers[0]?.seller);

  return (
    <>
      {!imageUrl ? null : (
        <S.Container onClick={onClick}>
          <S.ImageContainer>
            <S.Image src={imageUrl} />
            {product?.hasDiscount && (
              <S.DiscountContainer>
                <S.DiscountText>
                  {`${(product?.percentageDiscount * 100)?.toFixed(0)}% OFF`}
                </S.DiscountText>
              </S.DiscountContainer>
            )}
            {product?.tags?.map(({ id, text, color }) => (
              <S.TagContainer key={id} color={color}>
                <S.TagText>{text}</S.TagText>
              </S.TagContainer>
            ))}
          </S.ImageContainer>
          <S.Title>{product?.title}</S.Title>
          <S.PriceContainer>
            {product?.hasDiscount && <S.ListPrice>{product?.listPriceAsText}</S.ListPrice>}
            <S.SalePrice hasDiscount={product?.hasDiscount}>{product?.salePriceAsText}</S.SalePrice>
          </S.PriceContainer>
          <S.SoldAndDeliveredBy name={sellerDisplayName} />
          <ColorsList colors={colors} />
        </S.Container>
      )}
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    listPriceAsText: PropTypes.string,
    salePriceAsText: PropTypes.string,
    hasDiscount: PropTypes.bool,
    percentageDiscount: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        text: PropTypes.string,
        color: PropTypes.string,
        position: PropTypes.string,
      }),
    ),
    medias: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        key: PropTypes.string,
        urlPortrait: PropTypes.string,
        urlSquare: PropTypes.string,
      }),
    ),
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
            soldOut: PropTypes.bool,
            color: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  onClick: PropTypes.func,
};

export default ProductCard;
