import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function ReactQueryProvider(props) {
  return <QueryClientProvider {...props} client={queryClient} />;
}

export default ReactQueryProvider;
