import { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { clearProducts } from '../../store/ducks/products';
import { clearProduct } from '../../store/ducks/product';

const useClearPurchaseStores = () => {
  const dispatch = useDispatch();
  const clearPurchaseStores = useCallback(() => {
    batch(() => {
      dispatch(clearProducts());
      dispatch(clearProduct());
    });
  }, []);

  return clearPurchaseStores;
};

export default useClearPurchaseStores;
