import React from 'react';
import PropTypes from 'prop-types';
import * as S from './TextLoader.styles';

const TextLoader = ({ text }) => {
  return (
    <S.Container>
      <S.Limiter>
        <S.Spinner />
        {text && <S.Text>{text}</S.Text>}
      </S.Limiter>
    </S.Container>
  );
};

TextLoader.propTypes = {
  text: PropTypes.string,
};

export default TextLoader;
