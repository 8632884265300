import styled from 'styled-components';

export const CountdownContainer = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
`;

export const Countdown = styled.span`
  color: #fff;
  font-size: 23px;
`;
