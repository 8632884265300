import React from 'react';
import PropTypes from 'prop-types';

import * as S from './ColorsList.styles';

export function ColorsList({ colors }) {
  const minToShow = 2;
  const maxToShow = 3;
  const total = colors.length;
  const shouldNotShowColors = total < minToShow;

  const colorsToShow = colors.slice(0, maxToShow);
  const extraColors = Math.max(total - maxToShow, 0);
  const showExtraColors = extraColors > 0;
  const moreText = `+${extraColors}`;

  if (shouldNotShowColors) return null;

  return (
    <S.List>
      {colorsToShow.map(({ key, color }) => (
        <S.Color key={key} hexColor={`#${color}`} />
      ))}
      {showExtraColors && <S.More>{moreText}</S.More>}
    </S.List>
  );
}

ColorsList.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
};
