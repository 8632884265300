import ResponseStatus from '../../libs/responseStatus';
import { getOffline } from './app';
import { tagLoginCallback } from '../../gtm';
import services from '../../shared/services';
import { tagLoginException } from '../../gtm/ga4/events/login';

// Action types
const TYPES = {
  // Store list
  SET_STORE_LIST: 'store/SET_STORE_LIST',
  SET_STORE_LIST_SUCCESS: 'store/SET_STORE_LIST_SUCCESS',
  SET_STORE_LIST_ERROR: 'store/SET_STORE_LIST_ERROR',
  CLEAR_STORE_LIST: 'store/CLEAR_STORE_LIST',
  // Store and device
  SET_STORE_AND_DEVICE: 'store/SET_STORE_AND_DEVICE',
  SET_STORE_AND_DEVICE_SUCCESS: 'store/SET_STORE_AND_DEVICE_SUCCESS',
  SET_STORE_AND_DEVICE_ERROR: 'store/SET_STORE_AND_DEVICE_ERROR',
  CLEAR_STORE_AND_DEVICE: 'store/CLEAR_STORE_AND_DEVICE',
  // General
  CLEAR_STORE: 'store/CLEAR_STORE',
};

// Initial state
const initialState = {
  // Store list
  storeList: [],
  lastUsedStoreId: null,
  storeListStatus: ResponseStatus.Idle,
  storeListError: null,
  // Store and device
  store: null,
  device: null,
  salesChannel: null,
  serialNumber: null,
  storeAndDeviceStatus: ResponseStatus.Idle,
  storeAndDeviceError: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Store list
    case TYPES.SET_STORE_LIST:
      return {
        ...state,
        storeListStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_STORE_LIST_SUCCESS:
      return {
        ...state,
        storeList: action.payload.storeList,
        lastUsedStoreId: action.payload.lastUsedStoreId,
        storeListStatus: ResponseStatus.Resolved,
        storeListError: null,
      };
    case TYPES.SET_STORE_LIST_ERROR:
      return {
        ...state,
        storeList: initialState.storeList,
        lastUsedStoreId: initialState.lastUsedStoreId,
        storeListStatus: ResponseStatus.Rejected,
        storeListError: action.payload.error,
      };
    case TYPES.CLEAR_STORE_LIST:
      return {
        ...state,
        storeList: initialState.storeList,
        lastUsedStoreId: initialState.lastUsedStoreId,
        storeListStatus: initialState.storeListStatus,
        storeListError: initialState.storeListError,
      };
    // Store and device
    case TYPES.SET_STORE_AND_DEVICE:
      return {
        ...state,
        storeAndDeviceStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_STORE_AND_DEVICE_SUCCESS:
      return {
        ...state,
        store: {
          brand: 'rchlo',
          ...action.payload.store,
        },
        device: action.payload.device,
        salesChannel: action.payload.salesChannel,
        serialNumber: action.payload.serialNumber,
        storeAndDeviceStatus: ResponseStatus.Resolved,
        storeAndDeviceError: null,
      };
    case TYPES.SET_STORE_AND_DEVICE_ERROR:
      return {
        ...state,
        storeAndDeviceStatus: ResponseStatus.Rejected,
        storeAndDeviceError: action.payload.error,
      };
    case TYPES.CLEAR_STORE_AND_DEVICE:
      return {
        ...state,
        store: initialState.store,
        device: initialState.device,
        salesChannel: initialState.salesChannel,
        serialNumber: initialState.serialNumber,
        storeAndDeviceStatus: initialState.storeAndDeviceStatus,
        storeAndDeviceError: initialState.storeAndDeviceError,
      };
    // General
    case TYPES.CLEAR_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
// Store list
const setStoreList = () => ({
  type: TYPES.SET_STORE_LIST,
});

const setStoreListSuccess = (storeList, lastUsedStoreId) => ({
  type: TYPES.SET_STORE_LIST_SUCCESS,
  payload: { storeList, lastUsedStoreId },
});

const setStoreListError = (error) => ({
  type: TYPES.SET_STORE_LIST_ERROR,
  payload: { error },
});

const clearStoreList = () => ({
  type: TYPES.CLEAR_STORE_LIST,
});

// Store and device
const setStoreAndDevice = () => ({
  type: TYPES.SET_STORE_AND_DEVICE,
});

const setStoreAndDeviceSuccess = (store, device, salesChannel, serialNumber) => ({
  type: TYPES.SET_STORE_AND_DEVICE_SUCCESS,
  payload: { store, device, salesChannel, serialNumber },
});

const setStoreAndDeviceError = (error) => ({
  type: TYPES.SET_STORE_AND_DEVICE_ERROR,
  payload: { error },
});

const clearStoreAndDevice = () => ({
  type: TYPES.CLEAR_STORE_AND_DEVICE,
});

// General
const clearStore = () => ({
  type: TYPES.CLEAR_STORE,
});

// Async action creators
// Store list
const getStoreList = () => (dispatch) => {
  dispatch(setStoreList());
  services.stores
    .getRemoteStoreList()
    .then((response) => {
      const { data } = response.data;
      if (!Array.isArray(data?.stores) || data.stores.length === 0) {
        throw new Error('Nenhuma loja recebida');
      }
      const projectedStoreList =
        data?.stores?.map((item) => ({
          ...item,
          label: `${item.prefix} - ${item.name}`,
        })) || [];
      dispatch(setStoreListSuccess(projectedStoreList, data?.lastUsedStoreId || null));
    })
    .catch((error) => {
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      dispatch(setStoreListError(error));
    });
};

// Store and device
const checkStoreAndDevice =
  (registrationId, store, device, salesChannel, isDesktop) => (dispatch) => {
    const date = new Date();
    dispatch(setStoreAndDevice());
    services.storeDevices
      .getDeviceBySerialNumber({
        serialNumber: device.serialNumber,
        isDesktop,
      })
      .then((response) => {
        const { data } = response.data;
        data.storePrefix === store.prefix
          ? date.setDate(date.getDate() + 1)
          : date.setDate(date.getDate() - 1);
        services.storeDevices
          .updateDevice({
            id: data.id,
            sellerId: registrationId,
            storeId: data.storeId,
            expirationAt: date.toISOString(),
          })
          .then((updateDeviceResponse) => {
            const { data: updateDeviceData } = updateDeviceResponse.data;
            tagLoginCallback(registrationId, salesChannel, true);
            dispatch(
              setStoreAndDeviceSuccess(
                store,
                device,
                salesChannel,
                updateDeviceData?.serialNumber || null,
              ),
            );
          })
          .catch((error) => {
            tagLoginException({
              description: 'Erro ao atualizar dispositivo',
              fatal: false,
            });
            // If the app goes offline, change the network status accordingly
            if (
              process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
              error.message === 'Network Error'
            ) {
              dispatch(getOffline());
            }
            tagLoginCallback(registrationId, salesChannel, false, 'Loja e dispositivo inválidos');
            dispatch(setStoreAndDeviceError(error));
          });
      })
      .catch(() => {
        date.setDate(date.getDate() + 1);
        services.storeDevices
          .setDevice({
            sellerId: registrationId,
            storeId: store.id,
            brand: device?.brand,
            model: device.model,
            serialNumber: device.serialNumber,
            expirationAt: date.toISOString(),
            isDesktop,
          })
          .then((setDeviceResponse) => {
            const { data: setDeviceData } = setDeviceResponse.data;
            tagLoginCallback(registrationId, salesChannel, true);
            dispatch(
              setStoreAndDeviceSuccess(
                store,
                device,
                salesChannel,
                setDeviceData?.serialNumber || null,
              ),
            );
          })
          .catch((postError) => {
            tagLoginException({
              description: 'Loja e dispositivo inválidos',
              fatal: false,
            });
            // If the app goes offline, change the network status accordingly
            if (
              process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
              postError.message === 'Network Error'
            ) {
              dispatch(getOffline());
            }
            tagLoginCallback(registrationId, salesChannel, false, 'Loja e dispositivo inválidos');
            dispatch(setStoreAndDeviceError(postError));
          });
      });
  };

export {
  reducer as default,
  // Store list
  setStoreList,
  setStoreListSuccess,
  setStoreListError,
  clearStoreList,
  getStoreList,
  // Store and device
  setStoreAndDevice,
  setStoreAndDeviceSuccess,
  setStoreAndDeviceError,
  clearStoreAndDevice,
  checkStoreAndDevice,
  // General
  clearStore,
};
