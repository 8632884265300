import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../../assets/images/vector/copyIcon.svg';
import { ReactComponent as TickIcon } from '../../../assets/images/vector/tickIcon.svg';

const Button = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
`;

const CopyTextIcon = styled(CopyIcon)`
  width: 22px;

  path {
    fill: #000000;
  }
`;

const SuccessIcon = styled(TickIcon)`
  width: 22px;

  path {
    fill: #008f46;
  }
`;

const Label = styled.span`
  font-size: 18px;
  text-decoration: underline;
`;

export { Button, CopyTextIcon, SuccessIcon, Label };
