import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import SessionStorage from './libs/sessionStorage';
import Routes from './routes';
import styledTheme from './styles/styledTheme';
import muiTheme from './styles/muiTheme';
import authClient, { defaultInitOptions } from './config/keycloak';
import store from './store';
import { STATES } from './store/utils';
import { setAuthEvent } from './store/ducks/app';
import * as serviceWorker from './serviceWorker';
import ReactQueryProvider from './shared/libs/react-query';

// Watches changes in the store and stores in Session Storage the states that should be persisted
store.subscribe(() => {
  const appState = store.getState()[STATES.APP];
  const storeState = store.getState()[STATES.STORE];
  const cartState = store.getState()[STATES.CART];
  const shippingState = store.getState()[STATES.SHIPPING];
  SessionStorage.setItem(STATES.APP, appState);
  SessionStorage.setItem(STATES.STORE, storeState);
  SessionStorage.setItem(STATES.CART, cartState);
  SessionStorage.setItem(STATES.SHIPPING, shippingState);
});

// Stores Keycloak events in Redux's store
const handleEvent = (event) => {
  store.dispatch(setAuthEvent(event));
};

// Stores Keycloak tokens in session storage
const handleTokens = (tokens) => {
  SessionStorage.setItem('keycloakTokens', tokens);
};

ReactDOM.render(
  <KeycloakProvider
    authClient={authClient}
    initOptions={defaultInitOptions}
    onEvent={handleEvent}
    onTokens={handleTokens}
  >
    <ReactQueryProvider>
      <ReduxProvider store={store}>
        <StyledThemeProvider theme={styledTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <Routes />
          </MuiThemeProvider>
        </StyledThemeProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  </KeycloakProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
