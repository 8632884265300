import styled, { css } from 'styled-components';
import { Limit } from '../Limit';
import { DEVICE } from '../../../styles/styledTheme';

const Container = styled.header`
  width: 100%;
  background-color: transparent;
  ${({ variant }) =>
    variant === 'contained' &&
    css`
      background: #ffffff;
      border-bottom: 1px solid #cccccc;
    `}
`;

const InnerContainer = styled(Limit)`
  padding-top: 28px;
  padding-bottom: 28px;
  display: grid;
  grid-auto-flow: column;
  grid-template: 'left center right' / 1fr 4fr 1fr;
  align-items: center;
  gap: 20px;

  ${DEVICE.Tablet} {
    grid-template:
      'left right'
      'center center'
      / 1fr 1fr;
  }
`;

export { Container, InnerContainer };
