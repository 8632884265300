import { useCallback, useMemo, useState } from 'react';

export default function usePagination({ initialPageIndex = 0, itemsPerPage }) {
  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const currentPage = pageIndex + 1;

  const offset = useMemo(() => {
    if (pageIndex === 0) {
      return 0;
    }

    return pageIndex * itemsPerPage + 1;
  }, [pageIndex, itemsPerPage]);

  const loadMore = useCallback(() => {
    setPageIndex((prevPage) => prevPage + 1);
  }, []);

  const resetPagination = useCallback(() => {
    setPageIndex(0);
  }, []);

  return {
    pageIndex: pageIndex,
    page: currentPage,
    offset,
    isPaginating: offset > 0,
    loadMore,
    resetPagination,
  };
}
