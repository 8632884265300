import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#d64c46',
    },
    info: {
      main: '#0071a3',
    },
    success: {
      main: '#008f46',
    },
  },
  typography: {
    fontFamily: ['Arboria', 'sans-serif'].join(','),
    fontSize: 16,
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#707070',
        textTransform: 'none',
        '&.MuiButton-text': {
          textDecoration: 'underline',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#707070',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: '#707070',
        },
        '&$focused $notchedOutline': {
          borderColor: '#707070',
        },
      },
    },
    MuiTableContainer: {
      root: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#EFEFEF',
        borderLeft: '1px solid #EFEFEF',
        borderRight: '1px solid #EFEFEF',
      },
    },
    MuiTableBody: {
      root: {
        borderLeft: '1px solid #E4E4E4',
        borderRight: '1px solid #E4E4E4',
      },
    },
    MuiTableRow: {
      root: {
        '&:not(.MuiTableRow-head):nth-of-type(odd)': {
          backgroundColor: '#FFF',
        },
        '&:not(.MuiTableRow-head):nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 700,
      },
    },
  },
});

export default muiTheme;
