import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  overflow: auto;
`;

export { Container };
