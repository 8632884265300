import styled from 'styled-components';

export const Container = styled('div')(() => ({
  height: 40,
  width: '100%',
  display: 'flex',
  borderRadius: 4,
  color: '#2083B0',
  alignItems: 'center',
  background: '#D0F0FF',
  justifyContent: 'center',
}));

export const Message = styled.span`
  margin-left: 8px;
  font-weight: 500;
`;
