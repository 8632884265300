import { catalogApi } from '../../libs/axios';

const productsService = () => {
  /**
   * @typedef {Object} GetRemoteProductsData
   * @property {string | string[]} [business-id]
   * @property {string} [location-id]
   * @property {string} [seller]
   * @property {boolean} [cached]
   * @property {boolean} [rich-result]
   * @property {string | string[]} [query]
   * @property {string} [sku-group]
   * @property {string} [sku-group-nin]
   * @property {string} [sku]
   * @property {string} [sku-nin]
   * @property {string} [slug-nin]
   * @property {string} [slug]
   * @property {string} [category]
   * @property {number} [min-price]
   * @property {number} [max-price]
   * @property {boolean} [sold-out]
   * @property {string | string[]} [attributes]
   * @property {string} [fields]
   * @property {string} [sort]
   * @property {number} [offset]
   * @property {number} [limit]
   * @param {GetRemoteProductsData} data
   */

  const getRemoteProducts = async (data, config = {}) => {
    return catalogApi.post('v3/products', data, { ...config });
  };

  return {
    getRemoteProducts,
  };
};

export default productsService;
