import { useDispatch } from 'react-redux';
import { getOffline } from '../../store/ducks/app';
import { useCallback } from 'react';
import { ErrorUtils } from '../utils';

export default function useGetOffline() {
  const dispatch = useDispatch();

  const showOfflineScreenOnError = useCallback(
    (error) => {
      if (ErrorUtils.shouldShowOfflinePage(error)) {
        dispatch(getOffline());
      }
    },
    [dispatch],
  );

  return [showOfflineScreenOnError];
}
