import styled from 'styled-components';
import { DEVICE } from '../../../../../styles/styledTheme';

const Container = styled.div`
  display: grid;
  gap: 20px;
`;

const Title = styled.p`
  font-size: 20px;
  color: #000000;
  text-align: left;

  ${DEVICE.Totem} {
    text-align: center;
  }
`;

const Content = styled.div`
  display: grid;
`;

export { Container, Title, Content };
