import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from '../../../assets/images/vector/searchIcon.svg';
import * as S from './SearchField.styles';

const SearchField = ({ userQuery, placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState(userQuery || '');

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.TextInput onChange={handleChange} value={searchTerm} placeholder={placeholder} />
      <S.SubmitButton>
        <SearchIcon />
      </S.SubmitButton>
    </S.Form>
  );
};

SearchField.propTypes = {
  userQuery: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchField;
