import { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { clearApp } from '../../store/ducks/app';
import { clearStore } from '../../store/ducks/store';
import { clearProducts } from '../../store/ducks/products';
import { clearProduct } from '../../store/ducks/product';
import { clearCart } from '../../store/ducks/cart';
import { clearShipping } from '../../store/ducks/shipping';
import { clearQrCode } from '../../store/ducks/qrCode';

const useClearAllStores = () => {
  const dispatch = useDispatch();
  const clearAllStores = useCallback(() => {
    batch(() => {
      dispatch(clearApp());
      dispatch(clearStore());
      dispatch(clearProducts());
      dispatch(clearProduct());
      dispatch(clearCart());
      dispatch(clearShipping());
      dispatch(clearQrCode());
    });
  }, []);

  return clearAllStores;
};

export default useClearAllStores;
