import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
`;

export const Color = styled.li`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ hexColor }) => hexColor};
`;

export const More = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray};
`;
