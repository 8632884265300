import styled, { css } from 'styled-components';
import { variations } from './Typography.utils';
import { DEVICE } from '../../../styles/styledTheme';

const Typography = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Arboria';
  transition: all 0.3s ease-in;

  ${({ variation }) =>
    variation === variations.BODY1 &&
    css`
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.285714286;
    `}

  ${({ variation }) =>
    variation === variations.BODY2 &&
    css`
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.285714286;
    `}

  ${({ variation }) =>
    variation === variations.MAIN_TITLE &&
    css`
      font-weight: 700;
      font-size: 32px;

      ${DEVICE.Totem} {
        font-size: 24px;
      }
    `}

  ${({ variation }) =>
    variation === variations.ERROR_TITLE &&
    css`
      font-weight: 700;
      font-size: 26px;

      ${DEVICE.Totem} {
        font-size: 22px;
      }
    `}
`;

export { Typography };
