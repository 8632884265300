import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Right.styles';

const Right = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

Right.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Right;
