export function listOriginalPriceWithSellers(sellers, splitter = ':') {
  return sellers
    .reduce(
      (acc, { items }) =>
        acc +
        // eslint-disable-next-line no-shadow
        items.reduce((acc, curr) => {
          const value = curr?.total.toString();
          return `${acc}${value ? `${value}${splitter}` : ''}`;
        }, ''),
      '',
    )
    ?.replace(/:$/g, '')
    ?.toLowerCase();
}
