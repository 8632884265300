import ResponseStatus from '../../libs/responseStatus';
import { getOffline } from './app';
import { tagBagCallback, handleFreightSuccess, tagFreightException } from '../../gtm';
import { SALES_CHANNELS, getBusinessId, getLocationId } from '../utils';
import services from '../../shared/services';
import { getAppliedCoupon, filterValidCoupon, getErrorMessage } from '../../shared/utils';

const CART_CATALOG_ERROR = 'está esgotado no catálogo corporativo para';

// Utils
const CART_POST_ERROR_MESSAGES = {
  400: 'Quantidade desejada indisponível no momento',
  404: 'Produto não existe no catálogo',
  500: 'Falha ao se conectar ao servidor',
  default: 'Falha ao adicionar o produto na sacola. Por favor, tente novamente.',
};

const CART_DELETE_ERROR_MESSAGES = {
  500: 'Falha ao se conectar ao servidor',
  default: 'Falha ao remover produto do carrinho',
};

const getQuantities = (sellers) => {
  if (
    typeof sellers === 'undefined' ||
    sellers === null ||
    (Array.isArray(sellers) && sellers.length === 0)
  ) {
    return [0, 0];
  }
  const itemsQuantity = sellers.reduce((accumValue, curSeller) => {
    return accumValue + curSeller.items.length;
  }, 0);
  const productsQuantity = sellers.reduce((accumValue, curSeller) => {
    const curQuantity = curSeller.items.reduce((accumItemValue, curItem) => {
      return accumItemValue + curItem.quantity;
    }, 0);
    return accumValue + curQuantity;
  }, 0);
  return [itemsQuantity, productsQuantity];
};

// Action types
const TYPES = {
  UPDATE_CART: 'cart/UPDATE_CART',
  UPDATE_CART_SUCCESS: 'cart/UPDATE_CART_SUCCESS',
  UPDATE_CART_ERROR: 'cart/UPDATE_CART_ERROR',
  CLEAR_CART_ERRORS: 'cart/CLEAR_CART_ERRORS',
  CLEAR_CART: 'cart/CLEAR_CART',
  CART_COUPON_ERROR: 'cart/CART_COUPON_ERROR',
  CLEAR_CART_COUPON_ERROR: 'cart/CLEAR_CART_COUPON_ERROR',
};

// Initial state
const initialState = {
  cartId: null,
  correlationId: null,
  sellers: [],
  shippingGroups: [],
  itemsQuantity: 0,
  productsQuantity: 0,
  productsPrice: 'R$ 0,00',
  hasDiscount: false,
  discountPrice: 'R$ 0,00',
  totalPrice: 'R$ 0,00',
  cartStatus: ResponseStatus.Idle,
  cartError: null,
  couponError: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_CART:
      return {
        ...state,
        cartStatus: ResponseStatus.Pending,
      };
    case TYPES.UPDATE_CART_SUCCESS:
      return {
        ...state,
        cartId: action.payload.cartId,
        correlationId: action.payload.correlationId,
        sellers: action.payload.sellers,
        shippingGroups: action.payload.shippingGroups,
        itemsQuantity: action.payload.itemsQuantity,
        productsQuantity: action.payload.productsQuantity,
        productsPrice: action.payload.productsPrice,
        hasDiscount: action.payload.hasDiscount,
        discountPrice: action.payload.discountPrice,
        totalPrice: action.payload.totalPrice,
        cartStatus: ResponseStatus.Resolved,
        coupons: action.payload.coupons,
        cartError: null,
      };
    case TYPES.UPDATE_CART_ERROR:
      return {
        ...state,
        cartStatus: ResponseStatus.Rejected,
        cartError: action.payload.error,
      };
    case TYPES.CLEAR_CART_ERRORS:
      return {
        ...state,
        cartStatus: ResponseStatus.Idle,
        cartError: null,
      };
    case TYPES.CART_COUPON_ERROR:
      return {
        ...state,
        cartStatus: ResponseStatus.Idle,
        couponError: action.payload.error,
      };
    case TYPES.CLEAR_CART_COUPON_ERROR:
      return {
        ...state,
        cartStatus: ResponseStatus.Idle,
        couponError: null,
      };
    case TYPES.CLEAR_CART:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
const updateCart = () => ({
  type: TYPES.UPDATE_CART,
});

const updateCartSuccess = (
  cartId = null,
  correlationId = null,
  sellers = [],
  shippingGroups = [],
  itemsQuantity = 0,
  productsQuantity = 0,
  productsPrice = 'R$ 0,00',
  hasDiscount = false,
  discountPrice = 'R$ 0,00',
  totalPrice = 'R$ 0,00',
  coupons = [],
) => ({
  type: TYPES.UPDATE_CART_SUCCESS,
  payload: {
    cartId,
    correlationId,
    sellers,
    shippingGroups,
    itemsQuantity,
    productsQuantity,
    productsPrice,
    hasDiscount,
    discountPrice,
    totalPrice,
    coupons,
  },
});

const updateCartError = (error) => ({
  type: TYPES.UPDATE_CART_ERROR,
  payload: { error },
});

const clearCartErrors = () => ({
  type: TYPES.CLEAR_CART_ERRORS,
});

const cartCouponError = (error) => ({
  type: TYPES.CART_COUPON_ERROR,
  payload: { error },
});

const clearCartCouponError = () => ({
  type: TYPES.CLEAR_CART_COUPON_ERROR,
});

const clearCart = () => ({
  type: TYPES.CLEAR_CART,
});

// Async action creators
/**
 * @param {string} sku
 * @param {string} sellerId
 * @param {number} [quantity]
 */
const addProductToRemoteCart =
  (sku, sellerId, quantity = 1) =>
  (dispatch, getState) => {
    dispatch(updateCart());
    const { store, cart, shipping } = getState();
    services.cartGuest
      .addProduct({
        businessId: getBusinessId(store?.store?.parameters),
        locationId: getLocationId(store?.store?.parameters),
        sellerId,
        cartId: cart.cartId,
        correlationId: cart.correlationId,
        storePrefix: store.store?.prefix,
        sku,
        quantity,
        zipCode: shipping.shippingAddress?.postalCode,
        isWhatsApp: store.salesChannel === SALES_CHANNELS.WHATSAPP,
        coupon: getAppliedCoupon(cart?.coupons),
      })
      .then((response) => {
        tagBagCallback(true);

        const { data } = response.data;

        // Here "productsQuantity" is equivalent to "data.cart.quantity"
        const [itemsQuantity, productsQuantity] = getQuantities(data.cart.seller);
        dispatch(
          updateCartSuccess(
            data.cart.cartId,
            data.correlationId,
            data.cart.seller,
            data.shippingGroups,
            itemsQuantity,
            productsQuantity,
            data.cart.totalAsText,
            data.hasProgressiveDiscount,
            data.progressiveDiscountAsText,
            data.totalAsText,
            filterValidCoupon(data.coupons),
          ),
        );
      })
      .catch((error) => {
        let errorMessage = error?.response?.data?.errorMessages?.[0]?.message;
        if (errorMessage?.search(new RegExp(CART_CATALOG_ERROR)) > -1) {
          errorMessage = CART_POST_ERROR_MESSAGES.default;
        }
        if (!errorMessage) {
          errorMessage =
            CART_POST_ERROR_MESSAGES[error?.response?.status] || CART_POST_ERROR_MESSAGES.default;
        }

        tagBagCallback(false, errorMessage);

        // If the app goes offline, change the network status accordingly
        if (
          process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
          error.message === 'Network Error'
        ) {
          dispatch(getOffline());
        }

        dispatch(updateCartError({ ...error, message: errorMessage }));
      });
  };

// Async action creators
/**
 * @param {string} coupon
 */
const getCartWithCouponAction = (coupon) => (dispatch, getState) => {
  dispatch(updateCart());
  const { cart, shipping, store } = getState();
  services.cartGuest
    .getCartById({
      businessId: getBusinessId(store?.store?.parameters),
      locationId: getLocationId(store?.store?.parameters),
      cartId: cart.cartId,
      correlationId: cart.correlationId,
      isWhatsApp: store.salesChannel === SALES_CHANNELS.WHATSAPP,
      storePrefix: store.store?.prefix,
      zipCode: shipping.shippingAddress?.postalCode,
      coupon,
    })
    .then((response) => {
      dispatch(clearCartCouponError());
      const { data } = response.data;

      // Here "productsQuantity" is equivalent to "data.cart.quantity"
      const [itemsQuantity, productsQuantity] = getQuantities(data.cart.seller);
      const errorMessage = getErrorMessage(data.coupons);
      if (errorMessage) {
        dispatch(cartCouponError({ message: errorMessage }));
      }
      dispatch(
        updateCartSuccess(
          data.cart.cartId,
          data.correlationId,
          data.cart.seller,
          data.shippingGroups,
          itemsQuantity,
          productsQuantity,
          data.cart.totalAsText,
          data.hasProgressiveDiscount,
          data.progressiveDiscountAsText,
          data.totalAsText,
          filterValidCoupon(data.coupons),
        ),
      );
    })
    .catch((error) => {
      const message = coupon
        ? 'Ocorreu um erro, por favor tente novamente'
        : 'Erro ao remover cupom, por favor tente novamente';
      dispatch(cartCouponError({ ...error, message }));
    });
};

/**
 * @param {string} sku
 * @param {string} sellerId
 * @param {number} [quantity]
 */
const removeProductFromRemoteCart =
  (sku, sellerId, quantity = 1) =>
  (dispatch, getState) => {
    dispatch(updateCart());
    const { store, cart, shipping } = getState();
    services.cartGuest
      .deleteProduct({
        businessId: getBusinessId(store?.store?.parameters),
        locationId: getLocationId(store?.store?.parameters),
        sellerId,
        cartId: cart.cartId,
        correlationId: cart.correlationId,
        storePrefix: store.store?.prefix,
        sku,
        quantity,
        zipCode: shipping.shippingAddress?.postalCode,
        isWhatsApp: store.salesChannel === SALES_CHANNELS.WHATSAPP,
        coupon: getAppliedCoupon(cart?.coupons),
      })
      .then((response) => {
        tagBagCallback(true);

        const { data } = response.data;

        // Here "productsQuantity" is equivalent to "data.cart.quantity"
        const [itemsQuantity, productsQuantity] = getQuantities(data.cart.seller);
        dispatch(
          updateCartSuccess(
            data.cart.cartId,
            data.correlationId,
            data.cart.seller,
            data.shippingGroups,
            itemsQuantity,
            productsQuantity,
            data.cart.totalAsText,
            data.hasProgressiveDiscount,
            data.progressiveDiscountAsText,
            data.totalAsText,
            filterValidCoupon(data.coupons),
          ),
        );
      })
      .catch((error) => {
        const errorMessage =
          CART_DELETE_ERROR_MESSAGES[error?.response?.status] || CART_DELETE_ERROR_MESSAGES.default;

        tagBagCallback(false, errorMessage);

        // If the app goes offline, change the network status accordingly
        if (
          process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
          error.message === 'Network Error'
        ) {
          dispatch(getOffline());
        }
        dispatch(updateCartError(error));
      });
  };

const getUpdatedCart = () => (dispatch, getState) => {
  dispatch(updateCart());
  const { store, cart, shipping } = getState();
  services.cartGuest
    .getCartById({
      businessId: getBusinessId(store?.store?.parameters),
      locationId: getLocationId(store?.store?.parameters),
      cartId: cart.cartId,
      correlationId: cart.correlationId,
      storePrefix: store.store?.prefix,
      zipCode: shipping.shippingAddress?.postalCode,
      isWhatsApp: store.salesChannel === SALES_CHANNELS.WHATSAPP,
      coupon: getAppliedCoupon(cart?.coupons),
    })
    .then((response) => {
      const { data } = response.data;

      const freightData = data.shippingGroups;

      if (freightData) {
        handleFreightSuccess(freightData);
      }

      // Here "productsQuantity" is equivalent to "data.cart.quantity"
      const [itemsQuantity, productsQuantity] = getQuantities(data.cart.seller);
      dispatch(
        updateCartSuccess(
          data.cart.cartId,
          data.correlationId,
          data.cart.seller,
          data.shippingGroups,
          itemsQuantity,
          productsQuantity,
          data.cart.totalAsText,
          data.hasProgressiveDiscount,
          data.progressiveDiscountAsText,
          data.totalAsText,
          filterValidCoupon(data.coupons),
        ),
      );
    })
    .catch((error) => {
      tagFreightException(error.message);
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      dispatch(updateCartError(error));
    });
};

export default reducer;
export {
  updateCart,
  getCartWithCouponAction,
  updateCartSuccess,
  updateCartError,
  clearCartErrors,
  cartCouponError,
  clearCartCouponError,
  clearCart,
  addProductToRemoteCart,
  removeProductFromRemoteCart,
  getUpdatedCart,
};
