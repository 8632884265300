import { dispatchToGtm, handleString } from '../utils';

const tagVariationSelection = (variation) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:produto',
    eventAction: 'callback:sem-selecao',
    eventLabel: `selecionar:${handleString(variation)}`,
    noInteraction: '1',
  };
  dispatchToGtm(params);
};

export { tagVariationSelection };
