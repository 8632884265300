import { useCallback, useState } from 'react';

export default function useProductsList() {
  const [products, setProducts] = useState([]);

  const addProducts = useCallback((newProducts) => {
    setProducts((oldProducts) => [...oldProducts, ...newProducts]);
  }, []);

  const handleProducts = useCallback(
    (newProducts, isPaginating = false) => {
      if (isPaginating) {
        addProducts(newProducts);
        return;
      }

      setProducts(newProducts);
    },
    [addProducts],
  );

  return {
    products,
    setProducts,
    addProducts,
    handleProducts,
  };
}
