import React from 'react';
import { InfoCard, LegalTerms } from './components';
import rchloCardsExampleUrl from '../../../assets/images/raster/rchloCardsExample.png';
import creditCardsExampleUrl from '../../../assets/images/raster/creditCardsExample.png';
import eBitSealUrl from '../../../assets/images/raster/eBitSeal.png';
import ra1000SealUrl from '../../../assets/images/raster/ra1000Seal.png';
import certiSignSealUrl from '../../../assets/images/raster/certiSignSeal.png';
import * as S from './Footer.styles';

const Footer = () => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Line />
        <S.InfoContainer>
          <InfoCard title="Formas de Pagamento">
            <S.PaymentMethodContent>
              <S.PaymentMethodRow>
                <S.PaymentMethodLabel>Cartão Riachuelo</S.PaymentMethodLabel>
                <S.PaymentMethodImage src={rchloCardsExampleUrl} />
              </S.PaymentMethodRow>
              <S.PaymentMethodRow>
                <S.PaymentMethodLabel>Cartão de Crédito</S.PaymentMethodLabel>
                <S.PaymentMethodImage src={creditCardsExampleUrl} />
              </S.PaymentMethodRow>
              <S.PaymentMethodRow>
                <S.PaymentMethodLabel>Boleto</S.PaymentMethodLabel>
                <S.BilletIcon />
              </S.PaymentMethodRow>
            </S.PaymentMethodContent>
          </InfoCard>
          <InfoCard title="Ajuda">
            <S.SupportContent>
              <S.SupportArea>
                <S.SupportCall>
                  <S.CallIcon />
                  <S.SupportNumber>4003-0515</S.SupportNumber>
                </S.SupportCall>
                <S.SupportText>Capitais e regiões metropolitanas</S.SupportText>
              </S.SupportArea>
              <S.SupportArea>
                <S.SupportCall>
                  <S.CallIcon />
                  <S.SupportNumber>0800-729-0515</S.SupportNumber>
                </S.SupportCall>
                <S.SupportText>Demais localidades</S.SupportText>
              </S.SupportArea>
            </S.SupportContent>
          </InfoCard>
          <InfoCard title="Segurança">
            <S.SecurityContent>
              <S.SecurityImage src={eBitSealUrl} />
              <S.SecurityImage src={ra1000SealUrl} />
              <S.SecurityImage src={certiSignSealUrl} />
            </S.SecurityContent>
          </InfoCard>
        </S.InfoContainer>
        <LegalTerms />
      </S.InnerContainer>
    </S.Container>
  );
};

export default Footer;
