import { useCallback, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { useSalesChannels } from '../../../hooks';

const useIdle = (shouldNotRedirectRoutes) => {
  const { pathname } = useLocation();

  const [isIdle, setIsIdle] = useState(false);

  const EXPIRATION_TIME_IN_SECONDS = 60;

  const { isWhatsappSalesChannel } = useSalesChannels();

  const handleOnIdle = () => setIsIdle(true);

  const handleOnActive = () => setIsIdle(false);

  const parseSecondsToMilliseconds = (seconds) => seconds * 1000;

  const { reset } = useIdleTimer({
    timeout: parseSecondsToMilliseconds(EXPIRATION_TIME_IN_SECONDS),
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const handleResetTimer = () => {
    reset();
  };

  const isEnabledIdleFunctions = useCallback(() => {
    let isEnabledFeatureFlag = false;

    const shouldRouteRedirect = !shouldNotRedirectRoutes.includes(pathname);

    if (isWhatsappSalesChannel) {
      isEnabledFeatureFlag = process.env.REACT_APP_FF_EXPIRATION_TIME_WHATSAPP === 'true';
    } else {
      isEnabledFeatureFlag = process.env.REACT_APP_FF_EXPIRATION_TIME_DESKTOP === 'true';
    }

    return isEnabledFeatureFlag && shouldRouteRedirect;
  }, [isWhatsappSalesChannel, shouldNotRedirectRoutes]);

  return {
    isIdle,
    handleResetTimer,
    isEnabledIdleFunctions,
  };
};

export default useIdle;
