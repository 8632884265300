import { useQuery } from 'react-query';
import services from '../services';
import { useSearchDefaultParams } from './useSearchDefaultParams';
import axios from 'axios';

export default function useCategoryQuery(
  { category, attributes, offset, sort, paramFilters, route, originId },
  { onSuccess, onError },
) {
  const defaultParams = useSearchDefaultParams();

  const isPaginating = offset > 0;
  const keepPreviousData = isPaginating;

  const categoryQuery = useQuery(
    ['category', category, attributes, offset, sort, paramFilters, route, originId],
    ({ signal }) => {
      // Cancelamento de chamada com cancelToken devido a versão antiga do axios
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener('abort', () => {
        source.cancel('Query aborted');
      });

      return services.products.getRemoteProducts(
        {
          ...defaultParams,
          category,
          attributes,
          offset,
          sort,
          route,
          originId,
          ...paramFilters,
        },
        { cancelToken: source.token },
      );
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      keepPreviousData,
      onSuccess,
      onError,
    },
  );

  const response = categoryQuery?.data?.data?.data;

  return {
    ...categoryQuery,
    products: response?.response || [],
    filters: response?.filters || [],
  };
}
