/* eslint-disable react-hooks/rules-of-hooks */
import {
  useAddToCartDispatch,
  useViewItemDesktopDispatch,
  useSelectContentDispatch,
  useRemoveFromCartDesktopDispatch,
} from '@estore/gtm-v4';
import { FLOW } from '../helpers/constants';
import { GtmException } from '../utils';

export const tagViewItem = (params) => {
  try {
    useViewItemDesktopDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'ProductException');
  }
};

export const tagAddToCart = (params) => {
  try {
    useAddToCartDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'ProductException');
  }
};

export const tagRemoveFromCart = (params) => {
  try {
    useRemoveFromCartDesktopDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'ProductException');
  }
};

export const tagSelectContentModalSacola = (params) => {
  try {
    useSelectContentDispatch({
      ...params,
      flow: FLOW.MODAL_SACOLA,
    });
  } catch (error) {
    throw new GtmException(error, 'tagSelectContentModalSacola');
  }
};
