import { engineApi } from '../../libs/axios';

const cartGuestService = () => {
  /**
   * @typedef {Object} AddProductData
   * @property {string} businessId
   * @property {string} locationId
   * @property {string} sellerId
   * @property {string} [cartId]
   * @property {string} correlationId
   * @property {string} storePrefix
   * @property {string} sku
   * @property {string} quantity
   * @property {string} [zipCode]
   * @property {boolean} isWhatsApp
   * @param {AddProductData} data
   */
  const addProduct = async (data) => {
    return engineApi.post('v4/carts/guest/', data);
  };

  /**
   * @typedef {Object} DeleteProductData
   * @property {string} locationId
   * @property {string} sellerId
   * @property {string} cartId
   * @property {string} correlationId
   * @property {string} storePrefix
   * @property {string} sku
   * @property {string} quantity
   * @property {string} [zipCode]
   * @property {boolean} isWhatsApp
   * @param {DeleteProductData} data
   */
  const deleteProduct = async ({
    businessId,
    locationId,
    sellerId,
    cartId,
    correlationId,
    storePrefix,
    sku,
    quantity,
    zipCode,
    isWhatsApp,
    coupon,
  }) => {
    return engineApi.delete(
      `v4/carts/${cartId}/guest/${locationId}/${sellerId}/${sku}/${quantity}`,
      {
        params: {
          businessId,
          correlationId,
          storePrefix,
          zipCode,
          isWhatsApp,
          coupon,
        },
      },
    );
  };

  /**
   * @typedef {Object} GetCartByIdData
   * @property {string} cartId
   * @property {string} [correlationId]
   * @property {string} coupon
   * @property {string} storePrefix
   * @property {string} [zipCode]
   * @property {boolean} [isWhatsApp]
   * @param {GetCartByIdData} data
   */
  const getCartById = async ({
    cartId,
    correlationId,
    storePrefix,
    zipCode,
    isWhatsApp,
    coupon,
    businessId,
    locationId,
  }) => {
    return engineApi.get(`/v4/carts/guest/${cartId}`, {
      params: { correlationId, storePrefix, zipCode, isWhatsApp, coupon, businessId, locationId },
    });
  };

  return {
    addProduct,
    deleteProduct,
    getCartById,
  };
};

export default cartGuestService;
