import {
  dispatchToGtm,
  getValue,
  getCurrencyValue,
  getProperty,
  getList,
  getFirstLevelCategory,
  getEcommerceExclusivity,
  getTagsText,
} from '../utils';

const tagProductsImpression = (products, initialIndex, categories) => {
  const params = {
    event: 'productImpression',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'impressions',
    noInteraction: '1',
    ecommerce: {
      impressions: products.map((product, index) => ({
        id: getValue(product.skuGroup),
        name: getValue(product.title),
        list: getList(categories),
        price: getCurrencyValue(product.salePrice),
        brand: getValue(getProperty(product.attributes, 'brand', 'key')),
        category: getFirstLevelCategory(product.categories),
        position: (initialIndex + index + 1).toString(),
        dimension16: getTagsText(product.tags),
        dimension17: getEcommerceExclusivity(product.sellers?.[0]?.seller),
        dimension19: getValue(getProperty(product.attributes, 'gm', 'key')),
        dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'key')),
        dimension22: getValue(getProperty(product.attributes, 'gender', 'key')),
      })),
    },
  };
  dispatchToGtm(params);
};

const tagProductClick = (product, index, categories) => {
  const params = {
    event: 'productClick',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'click',
    ecommerce: {
      click: {
        actionField: {
          list: getList(categories),
        },
        products: [
          {
            id: getValue(product.skuGroup),
            name: getValue(product.title),
            price: getCurrencyValue(product.salePrice),
            brand: getValue(getProperty(product.attributes, 'brand', 'key')),
            category: getFirstLevelCategory(product.categories),
            position: (index + 1).toString(),
            dimension16: getTagsText(product.tags),
            dimension17: getEcommerceExclusivity(product.sellers?.[0]?.seller),
            dimension19: getValue(getProperty(product.attributes, 'gm', 'key')),
            dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'key')),
            dimension22: getValue(getProperty(product.attributes, 'gender', 'key')),
          },
        ],
      },
    },
  };
  dispatchToGtm(params);
};

const tagProductDetailImpression = (product) => {
  const params = {
    event: 'productDetail',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'detail',
    noInteraction: '1',
    ecommerce: {
      detail: {
        products: [
          {
            id: getValue(product.skuGroup),
            name: getValue(product.title),
            price: getCurrencyValue(product.salePrice),
            brand: getValue(getProperty(product.attributes, 'brand', 'key')),
            category: getFirstLevelCategory(product.categories),
            variant: getValue(getProperty(product.attributes, 'dco', 'key')),
            dimension16: getTagsText(product.tags),
            dimension17: getEcommerceExclusivity(product.sellers?.[0]?.seller),
            dimension19: getValue(getProperty(product.attributes, 'gm', 'key')),
            dimension20: getCurrencyValue(product.listPrice),
            dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'key')),
            dimension22: getValue(getProperty(product.attributes, 'gender', 'key')),
          },
        ],
      },
    },
  };
  dispatchToGtm(params);
};

const tagAdditionOfProductToCart = (product, seller, quantity) => {
  const params = {
    event: 'addToCart',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'add',
    ecommerce: {
      add: {
        products: [
          {
            id: getValue(product.skuGroup),
            name: getValue(product.title),
            price: getCurrencyValue(product.salePrice),
            brand: getValue(getProperty(product.attributes, 'brand', 'code')),
            category: getFirstLevelCategory(product.categories),
            variant: getValue(getProperty(product.attributes, 'dco', 'code')),
            quantity: quantity.toString(),
            dimension15: getValue(getProperty(product.variations, 'color', 'key')),
            dimension16: getTagsText(product.tags),
            dimension17: getEcommerceExclusivity(seller),
            dimension18: getValue(getProperty(product.variations, 'size', 'key')),
            dimension19: getValue(getProperty(product.attributes, 'gm', 'code')),
            dimension20: getCurrencyValue(product.listPrice),
            dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'code')),
            dimension22: getValue(getProperty(product.attributes, 'gender', 'code')),
          },
        ],
      },
    },
  };
  dispatchToGtm(params);
};

const tagRemovalOfProductFromCart = (product, seller, quantity) => {
  const params = {
    event: 'removeFromCart',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'remove',
    ecommerce: {
      remove: {
        products: [
          {
            id: getValue(product.skuGroup),
            name: getValue(product.title),
            price: getCurrencyValue(product.salePrice),
            brand: getValue(getProperty(product.attributes, 'brand', 'code')),
            category: getFirstLevelCategory(product.categories),
            variant: getValue(getProperty(product.attributes, 'dco', 'code')),
            quantity: quantity.toString(),
            dimension15: getValue(getProperty(product.variations, 'color', 'key')),
            dimension16: getTagsText(product.tags),
            dimension17: getEcommerceExclusivity(seller),
            dimension18: getValue(getProperty(product.variations, 'size', 'key')),
            dimension19: getValue(getProperty(product.attributes, 'gm', 'code')),
            dimension20: getCurrencyValue(product.listPrice),
            dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'code')),
            dimension22: getValue(getProperty(product.attributes, 'gender', 'code')),
          },
        ],
      },
    },
  };
  dispatchToGtm(params);
};

const tagCheckoutFlowPageMount = (checkoutStep, products) => {
  const params = {
    event: 'checkout',
    eventCategory: 'estore:enhanced-ecommerce',
    eventAction: 'checkout',
    noInteraction: '1',
    dimension10: undefined,
    dimension11: undefined,
    dimension12: undefined,
    dimension14: undefined,
    ecommerce: {
      checkout: {
        actionField: {
          step: checkoutStep,
        },
        products: products.map((product) => ({
          id: getValue(product.skuGroup),
          name: getValue(product.title),
          price: getCurrencyValue(product.salePrice),
          brand: getValue(getProperty(product.attributes, 'brand', 'code')),
          category: getFirstLevelCategory(product.categories),
          variant: getValue(getProperty(product.attributes, 'dco', 'code')),
          quantity: product.quantity?.toString(),
          dimension13: getValue(product.sku),
          dimension15: getValue(getProperty(product.variations, 'color', 'key')),
          dimension16: getTagsText(product.tags),
          dimension17: getEcommerceExclusivity(product.seller),
          dimension18: getValue(getProperty(product.variations, 'size', 'key')),
          dimension19: getValue(getProperty(product.attributes, 'gm', 'code')),
          dimension20: getCurrencyValue(product.listPrice),
          dimension21: getValue(getProperty(product.attributes, 'lifestyle', 'code')),
          dimension22: getValue(getProperty(product.attributes, 'gender', 'code')),
          dimension24: undefined,
        })),
      },
    },
  };
  dispatchToGtm(params);
};

export {
  tagProductsImpression,
  tagProductClick,
  tagProductDetailImpression,
  tagAdditionOfProductToCart,
  tagRemovalOfProductFromCart,
  tagCheckoutFlowPageMount,
};
