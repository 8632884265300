import { convertToLowerCase } from '../../../../libs/handleValue';

export const getItemListName = () => {
  const { search, pathname } = window.location;

  const category = pathname.split('/').at(-1);
  const modifiedSearch = convertToLowerCase(search.substring(3)).replace(/[+]/g, '-');

  if (search) return `busca:${modifiedSearch}`;
  return category;
};
