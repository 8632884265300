const getSellerDisplayName = (seller) => {
  return seller?.id === 'RCHLO' ? 'Riachuelo' : seller?.businessDisplayName;
};

const getImageUrl = (medias, format, size) => {
  const URL_BY_FORMAT = {
    portrait: 'urlPortrait',
    square: 'urlSquare',
  };
  const imageObject = medias.find((media) =>
    Object.prototype.hasOwnProperty.call(media, URL_BY_FORMAT[format]),
  );
  const imageUrl = imageObject ? `${imageObject[URL_BY_FORMAT[format]]}?imwidth=${size}` : null;
  return imageUrl;
};

const getColors = (variations) => {
  const colorsArray = variations.filter(({ id }) => id === 'color');
  let colors = [];
  if (colorsArray.length > 0) {
    colors = colorsArray[0].values.filter(({ soldOut }) => soldOut === false);
  }
  return colors;
};

export { getSellerDisplayName, getImageUrl, getColors };
