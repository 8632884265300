import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import NetworkErrorBoundary from '../../../containers/NetworkErrorBoundary';

const PublicRoute = ({ path, exact = false, component: Component }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <NetworkErrorBoundary {...props}>
          <Component {...props} />
        </NetworkErrorBoundary>
      )}
    />
  );
};

PublicRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.any.isRequired,
};

export default PublicRoute;
