import { CONSTANTS } from '../constants';
import { calculateDiscount } from '../number';
import { getFirstLevelCategory } from '../product';
import { getProperty, getValue } from '../text';

export const prepareItemProduct = (
  product,
  keyAttributes,
  affiliation = 'RCHLO',
  quantity = 1,
  index = 0,
) => ({
  item_id: product?.skuSubGroup || product?.skuGroup,
  item_name: getValue(product?.title),
  affiliation: getValue(affiliation),
  currency: CONSTANTS.CURRENCY,
  discount: calculateDiscount(product),
  index,
  item_brand: getValue(getProperty(product?.attributes, 'brand', keyAttributes)),
  item_category: getFirstLevelCategory(product?.categories),
  item_category2: getValue(getProperty(product?.attributes, 'gm', keyAttributes)),
  item_category3: getValue(product?.sku),
  item_category4: getValue(getProperty(product?.variations, 'size', 'key')),
  item_category5: getValue(getProperty(product?.variations, 'color', 'key')),
  item_list_name: getFirstLevelCategory(product?.categories),
  item_variant: getValue(getProperty(product?.attributes, 'dco', keyAttributes)),
  price: product?.salePrice,
  quantity,
});
