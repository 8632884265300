import { adminApi } from '../../libs/axios';

const storesService = () => {
  const getRemoteStoreList = async () => {
    return adminApi.get('/v2/stores/list');
  };

  return {
    getRemoteStoreList,
  };
};

export default storesService;
