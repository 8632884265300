import { useRef, useCallback, useEffect } from 'react';

const useOutsideClick = (callbackFunction) => {
  const elementRef = useRef(null);

  const handleClosure = useCallback(
    (evt) => {
      if (elementRef.current && !elementRef.current.contains(evt.target)) {
        callbackFunction();
      }
    },
    [elementRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClosure);
    document.addEventListener('touchstart', handleClosure);
    return () => {
      document.removeEventListener('mousedown', handleClosure);
      document.removeEventListener('touchstart', handleClosure);
    };
  }, [elementRef]);

  return elementRef;
};

export default useOutsideClick;
