import { SHIPPING_ORIGINS } from '../../../store/utils';

const getShippingErrorMessage = (exception, remainingUnits) => {
  switch (exception) {
    case SHIPPING_ORIGINS.UNAVAILABLE:
      return `Restam apenas ${remainingUnits} unidades deste item. Por favor, ajuste a quantidade antes de continuar`;
    case SHIPPING_ORIGINS.BREAKED:
      return 'Este produto está indisponível para sua região. Por favor, remova-o da sacola para continuar';
    default:
      return 'Este produto encontra-se indisponível. Por favor, remova-o da sacola para continuar';
  }
};

export { getShippingErrorMessage };
