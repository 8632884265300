import styled from 'styled-components';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  display: grid;
  justify-content: center;
  gap: 10px;
`;

const Limiter = styled.div`
  width: 400px;
  display: grid;
  justify-items: center;
  gap: 20px;
`;

const Spinner = styled(MuiCircularProgress).attrs({
  size: 200,
  color: 'primary',
})``;

const Text = styled.div`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

export { Container, Limiter, Spinner, Text };
