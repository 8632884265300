import React from 'react';
import PropTypes from 'prop-types';
import { SellerItem } from '../SellerItem';
import { SHIPPING_ORIGINS } from '../../../store/utils';
import * as S from './SellerList.styles';

const SellerList = ({
  sellers,
  soldOutShippingGroups,
  unavailableShippingGroups,
  breakedShippingGroups,
  wholeShippingGroups,
  onIncrement,
  onDecrement,
  onRemovalWithConfirmation,
  onSearchStoresForPickUp,
  onReachMax,
}) => {
  // Checks if the freight has already been calculated in order to display it
  let content;
  if (
    (Array.isArray(soldOutShippingGroups) && soldOutShippingGroups.length > 0) ||
    (Array.isArray(unavailableShippingGroups) && unavailableShippingGroups.length > 0) ||
    (Array.isArray(breakedShippingGroups) && breakedShippingGroups.length > 0) ||
    (Array.isArray(wholeShippingGroups) && wholeShippingGroups.length > 0)
  ) {
    content = (
      <>
        {soldOutShippingGroups.map((shippingGroup) => (
          <SellerItem
            key={shippingGroup.shippingGroupId}
            shippingGroup={shippingGroup}
            exception={SHIPPING_ORIGINS.SOLDOUT}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onRemovalWithConfirmation={onRemovalWithConfirmation}
            onSearchStoresForPickUp={onSearchStoresForPickUp}
            onReachMax={onReachMax}
          />
        ))}
        {unavailableShippingGroups.map((shippingGroup) => (
          <SellerItem
            key={shippingGroup.shippingGroupId}
            shippingGroup={shippingGroup}
            exception={SHIPPING_ORIGINS.UNAVAILABLE}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onRemovalWithConfirmation={onRemovalWithConfirmation}
            onSearchStoresForPickUp={onSearchStoresForPickUp}
            onReachMax={onReachMax}
          />
        ))}
        {breakedShippingGroups.map((shippingGroup) => (
          <SellerItem
            key={shippingGroup.shippingGroupId}
            shippingGroup={shippingGroup}
            exception={SHIPPING_ORIGINS.BREAKED}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onRemovalWithConfirmation={onRemovalWithConfirmation}
            onSearchStoresForPickUp={onSearchStoresForPickUp}
            onReachMax={onReachMax}
          />
        ))}
        {wholeShippingGroups.map((shippingGroup) => (
          <SellerItem
            key={shippingGroup.shippingGroupId}
            shippingGroup={shippingGroup}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onRemovalWithConfirmation={onRemovalWithConfirmation}
            onSearchStoresForPickUp={onSearchStoresForPickUp}
            onReachMax={onReachMax}
          />
        ))}
      </>
    );
  } else {
    content = (
      <>
        {sellers.map(({ seller, items }) => (
          <SellerItem
            key={seller.id}
            seller={seller}
            items={items}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onRemovalWithConfirmation={onRemovalWithConfirmation}
            onReachMax={onReachMax}
          />
        ))}
      </>
    );
  }

  return <S.Container>{content}</S.Container>;
};

SellerList.propTypes = {
  sellers: PropTypes.arrayOf(
    PropTypes.shape({
      seller: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      items: PropTypes.array.isRequired,
    }),
  ).isRequired,
  soldOutShippingGroups: PropTypes.array,
  unavailableShippingGroups: PropTypes.array,
  breakedShippingGroups: PropTypes.array,
  wholeShippingGroups: PropTypes.array,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onRemovalWithConfirmation: PropTypes.func.isRequired,
  onSearchStoresForPickUp: PropTypes.func,
  onReachMax: PropTypes.func,
};

export default SellerList;
