import styled from 'styled-components';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStepConnector from '@material-ui/core/StepConnector';
import MuiStep from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';

const Container = styled(MuiStepper)`
  &.MuiStepper-root {
    padding: 0;
    grid-area: right;
    justify-self: end;
    background-color: transparent;
  }
`;

const Step = styled(MuiStep)``;

const Label = styled(MuiStepLabel)`
  .MuiStepLabel-label {
    font-size: 18px;
  }
`;

const Connector = styled(MuiStepConnector)`
  &.MuiStepConnector-root {
    width: 60px;
  }
`;

export { Container, Step, Label, Connector };
