import axios from 'axios';
import {
  ADMIN_BASE_URL,
  CATALOG_BASE_URL,
  FACADE_BASE_URL,
  ENGINE_BASE_URL,
  CIANO_BASE_URL,
} from '../../../config/environments';
import { setupInterceptors } from './interceptors';

const adminApi = axios.create({
  baseURL: ADMIN_BASE_URL,
});

const catalogApi = axios.create({
  baseURL: CATALOG_BASE_URL,
});

const engineApi = axios.create({
  baseURL: ENGINE_BASE_URL,
});

const facadeApi = axios.create({
  baseURL: FACADE_BASE_URL,
});

const cianoApi = axios.create({
  baseURL: CIANO_BASE_URL,
});

setupInterceptors(adminApi);

setupInterceptors(catalogApi);

setupInterceptors(engineApi);

setupInterceptors(facadeApi);

export { adminApi, catalogApi, engineApi, facadeApi, cianoApi };
