import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '../../../assets/images/vector/errorIcon.svg';

export const Snackbar = styled(MuiSnackbar).attrs({
  TransitionProps: {
    timeout: {
      appear: 300,
      enter: 50,
      exit: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
})`
  &.MuiSnackbar-root {
    background-color: #b40612;
    padding-left: 5px;
    border-radius: 4px;
  }
`;

export const Alert = styled(MuiAlert).attrs({
  severity: 'error',
  iconMapping: {
    error: <ErrorIcon fontSize="inherit" />,
  },
  elevation: 6,
})`
  &.MuiAlert-root {
    border-radius: 0 4px 4px 0;
    &.MuiAlert-standardError {
      background-color: #ffffff;
      border: none;

      & .MuiAlert-icon {
        color: #b40612;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .MuiAlert-message {
        color: #b40612;
        background-color: #ffffff;
        font-family: 'Larsseit';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
