import styled from 'styled-components';
import { Limit } from '../Limit';

const ContentContainer = styled(Limit)`
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  gap: 40px;
`;

const CategoryContainer = styled.div`
  display: grid;
  gap: 20px;
`;

const LoadMoreContainer = styled.div`
  display: grid;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  text-align: center;
`;

const ErrorText = styled.p`
  color: #d64c46;
`;

export { ContentContainer, CategoryContainer, LoadMoreContainer, ErrorContainer, ErrorText };
