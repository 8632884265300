import React from 'react';
import PropTypes from 'prop-types';
import { Center, Left, Right } from './components';
import * as S from './Toolbar.styles';

const Toolbar = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
};

Toolbar.Left = Left;

Toolbar.Center = Center;

Toolbar.Right = Right;

export default Toolbar;
