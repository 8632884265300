import styled from 'styled-components';
import { DEVICE } from '../../../styles/styledTheme';

const Container = styled.div`
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding-left: 128px;
  padding-right: 128px;

  ${DEVICE.Desktop} {
    padding-left: 112px;
    padding-right: 112px;
  }

  ${DEVICE.Totem} {
    padding-left: 96px;
    padding-right: 96px;
  }

  ${DEVICE.Tablet} {
    padding-left: 80px;
    padding-right: 80px;
  }

  ${DEVICE.Smartphone} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export { Container };
