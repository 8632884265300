import styled from 'styled-components';
import MuiDivider from '@material-ui/core/Divider';
import { ReactComponent as CloseThinIcon } from '../../../../assets/images/vector/closeThinIcon.svg';

const Container = styled.div`
  width: 100%;
  padding-top: 8px;
  position: relative;
  display: grid;
  gap: 16px;
`;

const Title = styled.p`
  font-size: 28px;
  text-align: center;
`;

const CloseDrawerIcon = styled(CloseThinIcon)`
  width: 16px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const Line = styled(MuiDivider).attrs({
  variant: 'middle',
})``;

export { Container, Title, CloseDrawerIcon, Line };
