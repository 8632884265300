import styled from 'styled-components';
import MuiDivider from '@material-ui/core/Divider';
import { Limit } from '../Limit';
import { ReactComponent as BarCodeIcon } from '../../../assets/images/vector/barCodeIcon.svg';
import { ReactComponent as HeadsetIcon } from '../../../assets/images/vector/headsetIcon.svg';
import { DEVICE } from '../../../styles/styledTheme';

const Container = styled.footer`
  width: 100%;
  background-color: transparent;
`;

const InnerContainer = styled(Limit)`
  padding-bottom: 40px;
  display: grid;
  gap: 40px;
`;

const Line = styled(MuiDivider).attrs({
  variant: 'fullWidth',
})``;

const InfoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: none;
  align-items: start;
  gap: 20px;

  ${DEVICE.Totem} {
    grid-template-columns: none;
    grid-template-rows: repeat(3, auto);
    justify-content: center;
  }
`;

const PaymentMethodContent = styled.div`
  display: grid;
  gap: 12px;
`;

const PaymentMethodRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;

const PaymentMethodLabel = styled.span`
  font-size: 16px;
  color: #717171;
  white-space: nowrap;
`;

const PaymentMethodImage = styled.img``;

const BilletIcon = styled(BarCodeIcon)`
  width: 36px;
`;

const SupportContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;

const SupportArea = styled.div`
  display: grid;
  gap: 4px;
`;

const SupportCall = styled.div`
  padding: 4px 12px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CallIcon = styled(HeadsetIcon)`
  width: 28px;
`;

const SupportNumber = styled.span`
  font-size: 16px;
  color: #000000;
  white-space: nowrap;
`;

const SupportText = styled.p`
  font-size: 12px;
  color: #717171;
  text-align: center;
`;

const SecurityContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 20px;

  ${DEVICE.Totem} {
    justify-content: center;
  }
`;

const SecurityImage = styled.img`
  height: 56px;
`;

export {
  Container,
  InnerContainer,
  Line,
  InfoContainer,
  PaymentMethodContent,
  PaymentMethodRow,
  PaymentMethodLabel,
  PaymentMethodImage,
  BilletIcon,
  SupportContent,
  SupportArea,
  SupportCall,
  CallIcon,
  SupportNumber,
  SupportText,
  SecurityContent,
  SecurityImage,
};
