import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useIdlePopUp = (routesWithIdlePopUp) => {
  const { pathname } = useLocation();

  const isEnabledShowIdlePopUp = routesWithIdlePopUp.includes(pathname);

  const POPUP_COUNTDOWN_TIME_IN_SECONDS = 30;

  const formattedTimeClock = (seconds) => {
    const SECONDS_IN_AN_HOUR = 3600;

    const SECONDS_IN_AN_MINUTE = 60;

    const hours = Math.floor(seconds / SECONDS_IN_AN_HOUR);

    const TOTAL_HOURS_IN_SECONDS = hours * SECONDS_IN_AN_HOUR;

    const minutes = Math.floor((seconds - TOTAL_HOURS_IN_SECONDS) / SECONDS_IN_AN_MINUTE);

    const convertedSeconds = seconds - hours * SECONDS_IN_AN_HOUR - minutes * SECONDS_IN_AN_MINUTE;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = convertedSeconds.toString().padStart(2, '0');

    const timeClock = `${formattedMinutes}:${formattedSeconds}`;

    return timeClock;
  };

  const formattedTimePopUp = useMemo(() => {
    return formattedTimeClock(POPUP_COUNTDOWN_TIME_IN_SECONDS);
  }, [POPUP_COUNTDOWN_TIME_IN_SECONDS]);

  return {
    isEnabledShowIdlePopUp,
    formattedTimePopUp,
  };
};

export default useIdlePopUp;
