/* eslint-disable react-hooks/rules-of-hooks */
import { useSelectItemDesktopDispatch, useViewItemListDesktopDispatch } from '@estore/gtm-v4';
import { GtmException } from '../utils';

export const tagSelectItem = (params) => {
  try {
    useSelectItemDesktopDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'ProductsException');
  }
};

export const tagViewListItem = (params) => {
  try {
    useViewItemListDesktopDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'ProductsException');
  }
};
