const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  CATEGORY: '/category',
  PRODUCTS: '/products',
  PRODUCT: '/product',
  QRCODE_REPORT: '/qr-code-report',
  CHECKOUT: '/checkout',
  PAYMENT_INSTRUCTIONS: '/checkout/payment-instructions',
  QR_CODE: '/checkout/qr-code',
  NO_INTERNET_CONNECTION: '/no-internet-connection',
  REST_SCREEN: '/rest-screen',
};

export { ROUTES };
