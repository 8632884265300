import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/utils';
import useClearPurchaseStores from './useClearPurchaseStores';

export default function useSearchForm() {
  const history = useHistory();
  const clearPurchaseStores = useClearPurchaseStores();

  const handleSearch = (searchQuery) => {
    if (!searchQuery || searchQuery.trim() === '') return;

    clearPurchaseStores();
    const searchParams = new URLSearchParams({ q: searchQuery });
    const queryString = `?${searchParams.toString()}`;

    history.push(ROUTES.PRODUCTS.concat(queryString));
  };

  return {
    handleSearch,
  };
}
