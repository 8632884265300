import React from 'react';
import PropTypes from 'prop-types';

import QrCodeGenerator from 'qrcode.react';

const QrCode = ({ value, size }) => {
  return <QrCodeGenerator value={value} renderAs="svg" size={size} />;
};

QrCode.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default QrCode;
