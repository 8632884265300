import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    height: 80vh;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.wildSand};
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;

    &.keycloakPage {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  input, button {
    font-family: ${({ theme }) => theme.fonts.default}
  }
`;

export default GlobalStyle;
