export const CONSTANTS = {
  CURRENCY: 'BRL',
};

export const EVENTNAMES = {
  SEARCH: 'search',
  LOGIN: 'login',
};

export const FLOW = {
  HOME: 'home',
  PLP: 'plp',
  MODAL_SACOLA: 'modal:sacola',
  CHECKOUT_SACOLA: 'checkout:sacola-desktop',
  CHECKOUT_QRCODE: 'checkout:qr-code-desktop',
};
