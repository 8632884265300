import React from 'react';
import PropTypes from 'prop-types';
import * as S from './HighlightedContent.styles';

const HighlightedContent = ({ children, icon }) => {
  return (
    <S.Container>
      {icon && <S.IconContainer>{icon}</S.IconContainer>}
      {children}
    </S.Container>
  );
};

HighlightedContent.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
};

export default HighlightedContent;
