// Action types
const TYPES = {
  // Connection
  GET_ONLINE: 'app/GET_ONLINE',
  GET_OFFLINE: 'app/GET_OFFLINE',
  // Authentication
  LOG_IN: 'app/LOG_IN',
  LOG_OUT: 'app/LOG_OUT',
  SET_AUTH_EVENT: 'app/SET_AUTH_EVENT',
  CLEAR_AUTH_EVENT: 'app/CLEAR_AUTH_EVENT',
  // User
  SET_USER: 'app/SET_USER',
  CLEAR_USER: 'app/CLEAR_USER',
  // General
  CLEAR_APP: 'app/CLEAR_APP',
};

// Initial state
const initialState = {
  // Connection
  isOnline: true,
  // Authentication
  isLoggedIn: false,
  authEvent: null,
  // User
  user: null,
};

function getIsLoggedIn(event, state) {
  // evento emitido mesmo não autenticado
  if (event === 'onReady') return state;

  // eventos emitidos em para sessões válidas
  return ['onAuthSuccess', 'onAuthRefreshSuccess', 'onTokenExpired'].includes(event);
}

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Connection
    case TYPES.GET_ONLINE:
      return {
        ...state,
        isOnline: true,
      };
    case TYPES.GET_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };
    // Authentication
    case TYPES.LOG_IN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case TYPES.LOG_OUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    case TYPES.SET_AUTH_EVENT:
      return {
        ...state,
        authEvent: action.payload.authEvent,
        isLoggedIn: getIsLoggedIn(action.payload.authEvent, state.isLoggedIn),
      };
    case TYPES.CLEAR_AUTH_EVENT:
      return {
        ...state,
        authEvent: initialState.authEvent,
      };
    // User
    case TYPES.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case TYPES.CLEAR_USER:
      return {
        ...state,
        user: initialState.user,
      };
    // General
    case TYPES.CLEAR_APP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Action creators
// Connection
const getOnline = () => ({
  type: TYPES.GET_ONLINE,
});

const getOffline = () => ({
  type: TYPES.GET_OFFLINE,
});

// Authentication
const logIn = () => ({
  type: TYPES.LOG_IN,
});

const logOut = () => ({
  type: TYPES.LOG_OUT,
});

const setAuthEvent = (authEvent) => ({
  type: TYPES.SET_AUTH_EVENT,
  payload: { authEvent },
});

const clearAuthEvent = () => ({
  type: TYPES.CLEAR_AUTH_EVENT,
});

// User
const setUser = (user) => ({
  type: TYPES.SET_USER,
  payload: { user },
});

const clearUser = () => ({
  type: TYPES.CLEAR_USER,
});

// General
const clearApp = () => ({
  type: TYPES.CLEAR_APP,
});

export default reducer;
export {
  // Connection
  getOnline,
  getOffline,
  // Authentication
  logIn,
  logOut,
  setAuthEvent,
  clearAuthEvent,
  // User
  setUser,
  clearUser,
  // General
  clearApp,
};
