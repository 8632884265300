const allowedAsElements = {
  p: 'p',
  span: 'span',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  label: 'label',
};

const variations = {
  BODY1: 'BODY1',
  BODY2: 'BODY2',
  MAIN_TITLE: 'MAIN_TITLE',
  ERROR_TITLE: 'ERROR_TITLE',
};

export { allowedAsElements, variations };
