const legalTerms = [
  `© Copyright 2017 - 2019. Todos os direitos reservados. Lojas Riachuelo S/A. Rua Landri
  Sales, 1.070, G02 Anexo B, Guarulhos - SP - Brasil CEP 07250-130 | CNPJ 33.200.056/0441-97
  IE 796.420.926.112 | SAC 4003-0515. Direção de fotografia de produtos por Adriano Adrião.
  Pagamento com Cartão Riachuelo, parcelas mínimas de R$ 15,00. Para os demais cartões Visa,
  Mastercard, Elo, Diners, Hipercard e American Express, parcelas mínimas de R$ 30,00.`,
  `* COMPRE NO SITE e RETIRE NA LOJA com FRETE GRÁTIS, conforme lojas participantes. Consulte
  as lojas participantes nas opções de frete do seu pedido, conforme CEP de entrega
  cadastrado. FRETE ÚNICO DE R$ 4,99 para Sul e Sudeste em compras a partir de R$ 129,00.
  Demais regiões o valor estará sujeito a variações. Condição por tempo indeterminado, para
  tipo de entrega “normal”.`,
  `Preços, promoções, condições de pagamento, valores de frete e disponibilidade de estoque são
  aplicados somente nas compras efetuadas na loja online Riachuelo. Existe uma limitação de
  peças iguais no mesmo pedido que muda de acordo com o tipo de produto. Em caso de
  divergência de valores entre a página do produto e a Sacola de Compras, a Riachuelo se
  reserva a garantir o valor exibido na Sacola de Compras.`,
];

export { legalTerms };
