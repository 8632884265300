import React from 'react';
import PropTypes from 'prop-types';
import { CATEGORY_TITLE } from './CategoryList.utils';
import * as S from './CategoryList.styles';

const CategoryList = ({ level, categories, onSelect, selecteds = [] }) => {
  const isSelected = (id) => selecteds.includes(id);

  return (
    <S.Container>
      <S.Title>{CATEGORY_TITLE[level]}</S.Title>
      <S.ButtonGroup>
        {categories.map(({ id, label }) => {
          return (
            <S.Button
              key={id}
              className={isSelected(id) ? 'selected' : null}
              onClick={() => {
                onSelect(id);
              }}
            >
              {label}
            </S.Button>
          );
        })}
      </S.ButtonGroup>
    </S.Container>
  );
};

CategoryList.propTypes = {
  level: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selecteds: PropTypes.arrayOf(PropTypes.string),
};

export default CategoryList;
