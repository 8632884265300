import React from 'react';
import PropTypes from 'prop-types';
import { ProductList } from '../ProductList';
import { ShippingMethodList } from '../ShippingMethodList';
import { getSellerDisplayName } from '../../utils';
import * as S from './SellerItem.styles';

const SellerItem = ({
  seller,
  items,
  shippingGroup,
  exception,
  onIncrement,
  onDecrement,
  onRemovalWithConfirmation,
  onSearchStoresForPickUp,
  onReachMax,
}) => {
  // Checks if the freight has already been calculated in order to display it
  let content;
  if (shippingGroup) {
    const shippingGroupSeller = {
      id: shippingGroup.sellerId,
      businessDisplayName: shippingGroup.businessDisplayName,
    };
    content = (
      <>
        {!exception && (
          <S.ShippingCount>
            Entrega {shippingGroup.shippingGroupNumber} de {shippingGroup.shippingGroupsQuantity}
          </S.ShippingCount>
        )}
        <S.SoldAndDeliveredBy name={getSellerDisplayName(shippingGroupSeller)} />
        <ProductList
          seller={shippingGroupSeller}
          products={shippingGroup.products}
          exception={exception}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onRemovalWithConfirmation={onRemovalWithConfirmation}
          onReachMax={onReachMax}
        />
        <ShippingMethodList
          methods={shippingGroup.methods}
          shippingGroup={shippingGroup}
          onSearchStoresForPickUp={onSearchStoresForPickUp}
        />
      </>
    );
  } else {
    const sellerProducts = items.map(({ product, ...rest }) => ({ ...product, ...rest }));
    content = (
      <>
        <S.SoldAndDeliveredBy name={getSellerDisplayName(seller)} />
        <ProductList
          seller={seller}
          products={sellerProducts}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onRemovalWithConfirmation={onRemovalWithConfirmation}
          onReachMax={onReachMax}
        />
      </>
    );
  }

  return <S.Container>{content}</S.Container>;
};

SellerItem.propTypes = {
  seller: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  items: PropTypes.array,
  shippingGroup: PropTypes.object,
  exception: PropTypes.string,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onRemovalWithConfirmation: PropTypes.func.isRequired,
  onSearchStoresForPickUp: PropTypes.func,
  onReachMax: PropTypes.func,
};

export default SellerItem;
