import React from 'react';
import * as S from './KeycloakHeader.styles';

const KeycloakHeader = () => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Logo />
      </S.InnerContainer>
    </S.Container>
  );
};

export default KeycloakHeader;
