import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  gap: 20px;
`;

const Paragraph = styled.p`
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  color: #9d9d9d;
`;

export { Container, Paragraph };
