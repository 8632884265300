import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BagDrawer from '../../../containers/BagDrawer';
import {
  BackButton,
  Header,
  LoadMoreButton,
  Logo,
  NetworkChecker,
  NoResults,
  ProductGroup,
  ScrollToTopButton,
  SearchField,
  ShoppingBag,
  SortBy,
  TextLoader,
  Toolbar,
} from '../';
import { CategoryList, FilterList } from './components';
import { selectCart, selectHeader } from '../../../store/utils';
import { formatQueryString } from '../../utils';
import { tagNavigationToPreviousPage } from '../../../gtm';
import { ROUTES } from '../../../routes/utils';
import * as S from './Category.styles';

import { useCategoryViewModel } from './useCategoryViewModel';

const Category = ({ categorySlug, route, originId }) => {
  // Initial values
  const history = useHistory();
  const { itemsQuantity } = useSelector(selectCart);
  const { welcomeMessage, brand } = useSelector(selectHeader);

  const { models, operations } = useCategoryViewModel({ categorySlug, route, originId });

  // Navigates to "/"
  const handleLogoClick = () => {
    history.push(ROUTES.HOME);
  };

  // Navigates to the previous page
  const handleBack = () => {
    tagNavigationToPreviousPage('Categoria');
    history.goBack();
  };

  // Redirects to "/product" accordingly with the selected product
  const handleProductClick = (skuGroup, index) => {
    const queryString = formatQueryString({ q: skuGroup });
    history.push(ROUTES.PRODUCT.concat(queryString), { index });
  };

  return (
    <>
      <Header>
        <Header.Left>
          <Logo onClick={handleLogoClick} brand={brand} />
        </Header.Left>
        <Header.Center>
          <SearchField placeholder="O que você precisa?" onSearch={operations.handleSearch} />
        </Header.Center>
        <Header.Right>
          <ShoppingBag
            message={welcomeMessage}
            itemsQuantity={itemsQuantity}
            onBagClick={operations.toggleBagDrawer}
          />
        </Header.Right>
      </Header>
      <S.ContentContainer>
        {models.isLoading && <TextLoader text="Encontrando os melhores produtos pra você" />}

        {models.isSuccess && (
          <>
            <S.CategoryContainer>
              {models.categories?.length > 0 && (
                <CategoryList
                  level={2}
                  categories={models.categories}
                  selecteds={models.selectedCategories}
                  onSelect={operations.onChangeCategory}
                />
              )}

              {models.subCategories?.length > 0 && (
                <CategoryList
                  level={3}
                  categories={models.subCategories}
                  selecteds={models.selectedCategories}
                  onSelect={operations.onChangeSubcategory}
                />
              )}
            </S.CategoryContainer>

            <Toolbar>
              <Toolbar.Left>
                <BackButton onBack={handleBack} />
              </Toolbar.Left>
              <Toolbar.Center>
                {models.filters?.length > 0 && (
                  <FilterList filters={models.filters} onSelect={operations.toggleFilter} />
                )}
              </Toolbar.Center>
              <Toolbar.Right>
                <SortBy
                  defaultValue={models.sortBy}
                  onSelect={operations.handleSort}
                  hasCianoFilter={models.hasCianoFilter}
                />
              </Toolbar.Right>
            </Toolbar>

            {models.products?.length > 0 && (
              <ProductGroup
                page={models.currentPage}
                products={models.products}
                categories={{
                  firstLevel: models.selectedCategories[0],
                  secondLevel: models.selectedCategories?.[1],
                  thirdLevel: models.selectedCategories?.[2],
                }}
                onSelect={handleProductClick}
              />
            )}

            {models.products.length === 0 && <NoResults />}
            {models.hasNextPage && (
              <S.LoadMoreContainer>
                <LoadMoreButton isLoading={models.isFetching} onClick={operations.loadMore} />
              </S.LoadMoreContainer>
            )}
            <ScrollToTopButton />
          </>
        )}

        {models.isError && (
          <S.ErrorContainer>
            <S.ErrorText>Erro ao buscar os produtos. Tente novamente.</S.ErrorText>
          </S.ErrorContainer>
        )}

        <BagDrawer open={models.isBagDrawerOpen} onClose={operations.toggleBagDrawer} />
      </S.ContentContainer>
      <NetworkChecker />
    </>
  );
};

Category.propTypes = {
  categorySlug: PropTypes.string,
  route: PropTypes.string,
  originId: PropTypes.string,
};

export default Category;
