import { defaultCategories } from '../../containers/Home/Home.utils';
import { useGetStoreFront } from '../services/ciano/hooks';
import { isValidURL } from '../utils/url';

const urlToSplitPath = process.env.REACT_APP_CIANO_URL_TO_SPLIT_PATH;

function filterComponentsByMatchingProperties(properties, components = []) {
  return components.filter((component) =>
    Object.entries(properties).every(([key, value]) => component[key] === value),
  );
}

function getUrlCategorySlug(url) {
  if (!url) return null;
  const { origin, href } = new URL(url);
  if (origin === window.location.origin || origin.includes(urlToSplitPath)) {
    const path = href.replace(origin, '');
    return path;
  }
  return url;
}

function getMosaicProportion(subType, index) {
  const proportion = subType.replace('MOSAICO-', '').split('-');
  return proportion[index];
}

function mapMosaicItemToHomeMosaicItem(image, subType, index) {
  return {
    ...mapGalleryItemToCategoryItem(image),
    mosaicProportion: getMosaicProportion(subType, index),
  };
}

function mapGalleryItemToCategoryItem({ image: { title, image, targetUrl, target } }) {
  return {
    path: getUrlCategorySlug(targetUrl),
    label: title || '',
    imageUrl: image,
    target: target || '_blank',
  };
}

function filterGaleryImagesByVadildURL({ image }) {
  return image?.title && isValidURL(image?.targetUrl);
}

function parseComponents({ children, subType }) {
  if (subType === 'GALERIA') {
    return {
      subType,
      children: children.map(mapGalleryItemToCategoryItem),
    };
  }

  return {
    subType: 'MOSAICO',
    children: children.map((item, index) => {
      return mapMosaicItemToHomeMosaicItem(item, subType, index);
    }),
  };
}

function cianoStoreFrontToHomeComponents(storeFront, defaultData = []) {
  if (!storeFront) return defaultData;

  const { components } = storeFront;
  const homeImages = filterComponentsByMatchingProperties({ type: 'IMAGENS' }, components);

  if (homeImages.length === 0) return defaultData;

  const validHomeImages = homeImages?.filter(({ children, subType }) => {
    if (subType === 'GALERIA') return children.filter(filterGaleryImagesByVadildURL).length > 0;
    return true;
  });

  if (validHomeImages.length === 0) return defaultData;

  const validComponents = validHomeImages.map(parseComponents);

  const hasGalery = validComponents.find(({ subType }) => subType === 'GALERIA');
  if (!hasGalery) validComponents.unshift(defaultCategories[0]);

  return validComponents;
}

export default function useCianoCategories({ path }) {
  const cianoStoreFront = useGetStoreFront({
    path,
    config: {
      enabled: !!path,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  const finalComponents = cianoStoreFrontToHomeComponents(
    cianoStoreFront?.storeFront,
    defaultCategories,
  );

  return {
    isLoading: cianoStoreFront?.isLoading,
    isFetching: cianoStoreFront?.isFetching,
    components: finalComponents,
    showLoader: cianoStoreFront.isLoading || cianoStoreFront.isFetching,
  };
}
