import additionalInformationsService from './additionalInformations';
import addressByZipcodeService from './addressByZipcode';
import cartGuestService from './cartGuest';
import categoriesService from './categories';
import filtersService from './filters';
import productsService from './products';
import recoveryCartsService from './recoveryCarts';
import storeDevicesService from './storeDevices';
import storesService from './stores';

const services = {
  additionalInformations: additionalInformationsService(),
  addressByZipcode: addressByZipcodeService(),
  cartGuest: cartGuestService(),
  categories: categoriesService(),
  filters: filtersService(),
  products: productsService(),
  recoveryCarts: recoveryCartsService(),
  stores: storesService(),
  storeDevices: storeDevicesService(),
};

export default services;
