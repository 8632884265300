import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './Option.styles';

const Option = ({ value, children, onSelect }) => {
  const handleClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      onSelect(value);
    },
    [value],
  );

  return <S.Option onClick={handleClick}>{children}</S.Option>;
};

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Option;
