import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './CopyToClipboardButton.styles';

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    window.navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
    });
  };

  return (
    <S.Button onClick={handleClick}>
      {!isCopied ? <S.CopyTextIcon /> : <S.SuccessIcon />}
      <S.Label>{!isCopied ? 'copiar link' : 'link copiado'}</S.Label>
    </S.Button>
  );
};

CopyToClipboardButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
