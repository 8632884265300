/**
 * The purpose of this library is to improve session storage handling methods to return
 * usable data like parsed objects and null instead of undefined
 */

class SessionStorage {
  static setItem(itemName, itemValue) {
    window.sessionStorage.setItem(itemName, JSON.stringify(itemValue));
  }

  static getItem(itemName) {
    const item = window.sessionStorage.getItem(itemName);
    const parsedItem = item ? JSON.parse(item) : null;
    return parsedItem;
  }

  static removeItem(itemName) {
    window.sessionStorage.removeItem(itemName);
  }
}

export default SessionStorage;
