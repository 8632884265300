import React from 'react';
import PropTypes from 'prop-types';
import * as S from './ConfirmationModal.styles';

const ConfirmationModal = ({
  open,
  onClose,
  message,
  cancelButtonLabel,
  confirmationButtonLabel,
  cancelButtonCallback,
  confirmationButtonCallback,
  disableButtonCancel,
}) => {
  return (
    <S.Container open={open} onClose={onClose}>
      <S.Content>
        <S.CloseModalIcon onClick={onClose} />
        <S.Message>{message}</S.Message>
        <S.ControlContainer disableButtonCancel={disableButtonCancel}>
          {!disableButtonCancel && (
            <S.TextButton onClick={cancelButtonCallback}>{cancelButtonLabel}</S.TextButton>
          )}
          <S.ContainedButton onClick={confirmationButtonCallback}>
            {confirmationButtonLabel}
          </S.ContainedButton>
        </S.ControlContainer>
      </S.Content>
    </S.Container>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  confirmationButtonLabel: PropTypes.string.isRequired,
  cancelButtonCallback: PropTypes.func.isRequired,
  confirmationButtonCallback: PropTypes.func.isRequired,
  disableButtonCancel: PropTypes.bool,
};

export default ConfirmationModal;
