import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import SessionStorage from '../libs/sessionStorage';
import appReducer from './ducks/app';
import storeReducer from './ducks/store';
import productsReducer from './ducks/products';
import productReducer from './ducks/product';
import cartReducer from './ducks/cart';
import shippingReducer from './ducks/shipping';
import qrCodeReducer from './ducks/qrCode';
import { STATES, getPersistedState } from './utils';

// Combination of all reducers
const rootReducer = combineReducers({
  [STATES.APP]: appReducer,
  [STATES.STORE]: storeReducer,
  [STATES.PRODUCTS]: productsReducer,
  [STATES.PRODUCT]: productReducer,
  [STATES.CART]: cartReducer,
  [STATES.SHIPPING]: shippingReducer,
  [STATES.QR_CODE]: qrCodeReducer,
});

// List of states that should persist even after page reload
const appState = SessionStorage.getItem(STATES.APP);
const storeState = SessionStorage.getItem(STATES.STORE);
const cartState = SessionStorage.getItem(STATES.CART);
const shippingState = SessionStorage.getItem(STATES.SHIPPING);

const statesToPersist = [
  { name: STATES.APP, state: appState },
  { name: STATES.STORE, state: storeState },
  { name: STATES.CART, state: cartState },
  { name: STATES.SHIPPING, state: shippingState },
];

const persistedState = getPersistedState(statesToPersist);

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

export default store;
