import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { BaseLayout } from '../BaseLayout';
import { Footer, Header, Logo, ProgressBar } from '../../components';
import * as S from './CheckoutLayout.styles';
import { useSelector } from 'react-redux';
import { selectHeader } from '../../../store/utils';

const CheckoutLayout = ({ children, currentStep }) => {
  const { brand } = useSelector(selectHeader);

  return (
    <BaseLayout>
      <Header variant="contained">
        <Header.Left>
          <Logo brand={brand} />
        </Header.Left>
        {currentStep !== 0 && (
          <Header.Right>
            <ProgressBar success={currentStep === 2} />
          </Header.Right>
        )}
      </Header>
      {children}
      <Box display="flex" alignItems="center" justifyContent="center" my="32px">
        <S.DoubtIcon />
        <div>
          <strong>Dúvidas?</strong> Consulte um de nossos colaboradores
        </div>
      </Box>
      <Footer />
    </BaseLayout>
  );
};

CheckoutLayout.propTypes = {
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number,
};

export default CheckoutLayout;
