import { dispatchToGtm, handleString } from '../utils';

const tagProductRemovalModalButtonClick = (buttonName) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:sacola',
    eventAction: 'clique:botao:lightbox:remover-item-sacola',
    eventLabel: handleString(buttonName),
  };
  dispatchToGtm(params);
};

export { tagProductRemovalModalButtonClick };
