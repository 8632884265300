import { formatDiscountPrice } from './formatDiscountPrice';

export const calculateDiscount = (product) => {
  if (!product) return 0;
  if (!product.discountPrice) {
    const { listPrice, salePrice } = product;
    if (!product.hasDiscount) return 0;
    const discount = Number.parseFloat((listPrice - salePrice).toFixed(2));
    return discount;
  }
  return formatDiscountPrice(product);
};
