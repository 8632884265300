import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  background: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 3px;

  padding: 50px;
`;

const IconContainer = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  background: #252525;
  width: 75px;
  height: 75px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, IconContainer };
