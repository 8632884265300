import React from 'react';
import * as S from './ScrollToTopButton.styles';

const ScrollToTopButton = () => {
  const handleScrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <S.Container>
      <S.Button onClick={handleScrollToTop}>
        <S.TopArrowIcon />
      </S.Button>
      <S.Text>topo</S.Text>
    </S.Container>
  );
};

export default ScrollToTopButton;
