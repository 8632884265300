import React from 'react';
import PropTypes from 'prop-types';
import { FilterSelect } from './components';
import * as S from './FilterList.styles';

const FilterList = ({ filters, onSelect }) => {
  return (
    <S.Container>
      {filters.map((filter) => {
        return <FilterSelect key={filter.id} filter={filter} onSelect={onSelect} />;
      })}
    </S.Container>
  );
};

FilterList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      initialValue: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
  activeFilters: PropTypes.PropTypes.objectOf(PropTypes.string),
  onSelect: PropTypes.func,
};

export default FilterList;
