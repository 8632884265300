import axios from 'axios';
import ResponseStatus from '../../libs/responseStatus';
import { getOffline } from './app';
import { tagSearchError } from '../../gtm';
import { LIMIT, getSellerId, getBusinessId, getLocationId } from '../utils';
import services from '../../shared/services';
import { tagSearch, tagSearchException } from '../../gtm/ga4/events';

// Utils
const SORT_ORDER_OPTIONS = [
  {
    label: 'Lançamentos',
    value: [
      'publishingDate.earliestYear.desc',
      'publishingDate.earliestWeekYear.desc',
      'availability.desc',
      'publishingDate.earliest.desc',
    ],
  },
  { label: 'Preço: Menores primeiro', value: ['salePrice.asc'] },
  { label: 'Preço: Maiores primeiro', value: ['salePrice.desc'] },
];

const SORT_ORDER_OPTIONS_CIANO = [
  {
    label: 'Relevância',
    value: ['default'],
  },
  ...SORT_ORDER_OPTIONS,
];

const getDefaultSearchParams = ({ parameters, ...args }) => ({
  businessId: getBusinessId(parameters),
  locationId: getLocationId(parameters),
  richResult: true,
  soldOut: false,
  cached: true,
  limit: LIMIT,
  ...args,
});

// Action types
const TYPES = {
  INCREMENT_PAGE: 'products/INCREMENT_PAGE',
  DECREMENT_PAGE: 'products/DECREMENT_PAGE',
  RESET_PAGE: 'products/RESET_PAGE',
  SET_PRODUCTS: 'products/SET_PRODUCTS',
  SET_PRODUCTS_SUCCESS: 'products/SET_PRODUCTS_SUCCESS',
  SET_PRODUCTS_ERROR: 'products/SET_PRODUCTS_ERROR',
  SET_MORE_PRODUCTS: 'products/SET_MORE_PRODUCTS',
  SET_MORE_PRODUCTS_SUCCESS: 'products/SET_MORE_PRODUCTS_SUCCESS',
  SET_MORE_PRODUCTS_ERROR: 'products/SET_MORE_PRODUCTS_ERROR',
  SET_SORT_BY: 'products/SET_SORT_BY',
  CLEAR_PRODUCTS: 'products/CLEAR_PRODUCTS',
};

// Initial state
const initialState = {
  axiosSource: null,
  page: 0,
  products: [],
  productsStatus: ResponseStatus.Idle,
  productsError: null,
  moreProductsStatus: ResponseStatus.Idle,
  moreProductsError: null,
  hasNext: false,
  sortBy: SORT_ORDER_OPTIONS[0],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case TYPES.DECREMENT_PAGE:
      return {
        ...state,
        page: state.page - 1,
      };
    case TYPES.RESET_PAGE:
      return {
        ...state,
        page: 0,
      };
    case TYPES.SET_PRODUCTS:
      return {
        ...state,
        axiosSource: action.payload.axiosSource,
        products: [],
        productsStatus: ResponseStatus.Pending,
        productsError: null,
      };
    case TYPES.SET_PRODUCTS_SUCCESS:
      return {
        ...state,
        axiosSource: null,
        products: action.payload.products,
        productsStatus: ResponseStatus.Resolved,
        productsError: null,
        hasNext: action.payload.hasNext,
      };
    case TYPES.SET_PRODUCTS_ERROR:
      return {
        ...state,
        axiosSource: null,
        productsStatus: ResponseStatus.Rejected,
        productsError: action.payload.error,
      };
    case TYPES.SET_MORE_PRODUCTS:
      return {
        ...state,
        moreProductsStatus: ResponseStatus.Pending,
        moreProductsError: null,
      };
    case TYPES.SET_MORE_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...state.products, ...action.payload.products],
        moreProductsStatus: ResponseStatus.Resolved,
        moreProductsError: null,
        hasNext: action.payload.hasNext,
      };
    case TYPES.SET_MORE_PRODUCTS_ERROR:
      return {
        ...state,
        moreProductsStatus: ResponseStatus.Rejected,
        moreProductsError: action.payload.error,
      };
    case TYPES.SET_SORT_BY:
      return {
        ...state,
        sortBy: SORT_ORDER_OPTIONS.find(({ value }) => value === action.payload.sortBy),
      };
    case TYPES.CLEAR_PRODUCTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
const incrementPage = () => ({
  type: TYPES.INCREMENT_PAGE,
});

const decrementPage = () => ({
  type: TYPES.DECREMENT_PAGE,
});

const resetPage = () => ({
  type: TYPES.RESET_PAGE,
});

const setProducts = (axiosSource) => ({
  type: TYPES.SET_PRODUCTS,
  payload: { axiosSource },
});

const setProductsSuccess = (products, hasNext) => ({
  type: TYPES.SET_PRODUCTS_SUCCESS,
  payload: { products, hasNext },
});

const setProductsError = (error) => ({
  type: TYPES.SET_PRODUCTS_ERROR,
  payload: { error },
});

const setMoreProducts = () => ({
  type: TYPES.SET_MORE_PRODUCTS,
});

const setMoreProductsSuccess = (products, hasNext) => ({
  type: TYPES.SET_MORE_PRODUCTS_SUCCESS,
  payload: { products, hasNext },
});

const setMoreProductsError = (error) => ({
  type: TYPES.SET_MORE_PRODUCTS_ERROR,
  payload: { error },
});

const setSortBy = (sortBy) => ({
  type: TYPES.SET_SORT_BY,
  payload: { sortBy },
});

const clearProducts = () => ({
  type: TYPES.CLEAR_PRODUCTS,
});

// Async action creators
/**
 * @param {string} query
 * @param {string} category
 * @param {string} attributes
 * @param {string} sort
 * @param {number} page
 */
const getProducts = (query, category, attributes, sort) => async (dispatch, getState) => {
  const { store, products } = getState();
  dispatch(resetPage());
  const sellerId = getSellerId(store.store.prefix);
  // If "axiosSource" exists, it means the previous request is still running and must be canceled
  if (products.axiosSource) {
    products.axiosSource.cancel();
  }
  const source = axios.CancelToken.source();
  dispatch(setProducts(source));
  const searchParams = getDefaultSearchParams({
    seller: sellerId,
    query,
    category,
    attributes,
    sort,
    parameters: store?.store?.parameters,
  });
  try {
    const response = await services.products.getRemoteProducts(searchParams, {
      cancelToken: source.token,
    });
    const { data } = response.data;

    if (query && data.response.length > 0) tagSearch(query);

    if (query && data.response.length === 0) {
      tagSearchException({
        description: `${query}:produto não encontrado`,
        fatal: false,
      });
      tagSearchError(query, 'Nenhum resultado encontrado');
    }
    dispatch(setProductsSuccess(data.response, data.hasNext));
  } catch (error) {
    if (!axios.isCancel(error)) {
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      if (query) {
        tagSearchException({
          description: `Erro ao buscar por:${query}`,
          fatal: false,
        });
        tagSearchError(query, 'Error ao buscar produtos');
      }
      dispatch(setProductsError(error));
    }
  }
};

/**
 * @param {string} query
 * @param {string} category
 * @param {string} attributes
 * @param {string} sort
 * @param {number} page
 */
const getMoreProducts = (query, category, attributes, sort) => (dispatch, getState) => {
  const { store, products } = getState();
  dispatch(incrementPage());
  const sellerId = getSellerId(store.store.prefix);
  dispatch(setMoreProducts());
  const searchParams = getDefaultSearchParams({
    seller: sellerId,
    query,
    category,
    attributes,
    sort,
    offset: LIMIT * (products.page + 1),
    parameters: store?.store?.parameters,
  });
  services.products
    .getRemoteProducts(searchParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(setMoreProductsSuccess(data.response, data.hasNext));
    })
    .catch((error) => {
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      if (query) {
        // tagSearchException({
        //   description: `Erro ao buscar por:${query}`,
        //   fatal: false,
        // });
        tagSearchError(query, 'Error ao buscar produtos');
      }
      dispatch(setMoreProductsError(error));
    });
};

export {
  reducer as default,
  SORT_ORDER_OPTIONS,
  SORT_ORDER_OPTIONS_CIANO,
  incrementPage,
  decrementPage,
  resetPage,
  setProducts,
  setProductsSuccess,
  setProductsError,
  setMoreProducts,
  setMoreProductsSuccess,
  setMoreProductsError,
  setSortBy,
  clearProducts,
  getProducts,
  getMoreProducts,
};
