import { facadeApi } from '../../libs/axios';
import { parseRecoveryCartData } from './helpers';

const recoveryCartsService = () => {
  /**
   * @typedef {Object} SetRecoveryCartData
   * @property {string} sellerId
   * @property {string} serialNumber
   * @property {string} cartId
   * @property {string} salesChannelCode
   * @param {SetRecoveryCartData} data
   */
  const setRecoveryCart = async (data) => {
    return facadeApi.post('v4/recovery-carts', data);
  };

  /**
   *
   * @typedef {Object} GetRecoveryCartRequest
   * @property {number} seller
   * @property {number} 'page-index'
   * @property {number} 'page-size'
   * @property {string} [store]
   * @property {string} [status]
   * @property {string} [code]
   * @property {string} ['start-date']
   * @property {string} ['end-date']
   *
   * @param {GetRecoveryCartRequest} data
   */
  const getRecoveryCarts = async (data) => {
    return facadeApi
      .get('/v1/recovery-carts', {
        params: data,
      })
      .then(parseRecoveryCartData);
  };

  return {
    setRecoveryCart,
    getRecoveryCarts,
  };
};

export default recoveryCartsService;
