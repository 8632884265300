import { adminApi } from '../../libs/axios';

const storeDevicesService = () => {
  /**
   * @typedef {Object} GetDeviceBySerialNumberData
   * @property {string} serialNumber
   * @property {boolean} isDesktop
   * @param {GetDeviceBySerialNumberData} data
   */
  const getDeviceBySerialNumber = async (data) => {
    return adminApi.get('/v2/store-devices/serialNumber', {
      params: data,
    });
  };

  /**
   * @typedef {Object} SetDeviceData
   * @property {string} sellerId
   * @property {string} storeId
   * @property {string} brand
   * @property {string} model
   * @property {string} serialNumber
   * @property {string} expirationAt
   * @property {boolean} isDesktop
   * @param {SetDeviceData} data
   */
  const setDevice = async (data) => {
    return adminApi.post('/v2/store-devices', data);
  };

  /**
   * @typedef {Object} UpdateDeviceData
   * @property {string} id
   * @property {string} sellerId
   * @property {string} storeId
   * @property {string} expirationAt
   * @param {UpdateDeviceData} data
   */
  const updateDevice = async (data) => {
    return adminApi.patch(`/v2/store-devices/${data.id}`, data);
  };

  return {
    getDeviceBySerialNumber,
    setDevice,
    updateDevice,
  };
};

export default storeDevicesService;
