import { useCallback, useState } from 'react';

export default function useActiveFilters(initialFilters = {}) {
  const [filters, setFilters] = useState(initialFilters);

  const toggleFilter = useCallback(({ filter, option }) => {
    const filterKey = filter.id;
    const filterValue = option?.id;
    setFilters((activeFilters) => {
      const { [filterKey]: filterBeingToggled, ...otherActiveFilters } = activeFilters;
      const isBeingToggled = filterBeingToggled === filterValue;
      const isResetting = !option;
      if (isBeingToggled || isResetting) {
        return { ...otherActiveFilters };
      }
      return {
        ...otherActiveFilters,
        [filterKey]: {
          key: filterKey,
          param: filter.param,
          value: filterValue,
        },
      };
    });
  }, []);

  const activeFilters = getFiltersKeyValuePairs(filters);
  const attributes = getAttributeFilters(filters);
  const params = getParamFilters(filters);
  const queryFilters = { attributes, ...params };
  return [activeFilters, toggleFilter, queryFilters];
}

function getFiltersKeyValuePairs(filters) {
  return Object.keys(filters).reduce((actives, currentFilterKey) => {
    return {
      ...actives,
      [currentFilterKey]: filters[currentFilterKey].value,
    };
  }, {});
}

function isAttributeFilter(param) {
  return param.startsWith('attr-');
}

function isNotAttributeFilter(param) {
  return !isAttributeFilter(param);
}

function getAttributeFilters(filters) {
  return Object.values(filters)
    .filter((filter) => isAttributeFilter(filter.param))
    .reduce(reduceFilterToQueryParam, {});
}

function getParamFilters(filters) {
  return Object.values(filters)
    .filter((filter) => isNotAttributeFilter(filter.param))
    .reduce(reduceFilterToQueryParam, {});
}

function reduceFilterToQueryParam(queryParams, filter) {
  const paramKey = extractParamKey(filter);
  const value = extractParamValue(filter);
  return {
    ...queryParams,
    [paramKey]: value,
  };
}

/**
 * Retorna o valor no formato que vai ser utilizada no filtro baseado no tipo de parâmetro.
 * @param {Object} filter - Um filtro do catálogo
 * @param {string} filter.key - Chave do filtro, utilizado nas buscas por atributos
 * @param {string} filter.param - Nome do parâmetro ou nome do atributo com prefixo `attr-`
 * @param {string} filter.value -  valor do filtro.
 * @returns {string} Chave a ser utilizada no filtro
 */
function extractParamValue({ value }) {
  return [value];
}

/**
 * Retorna a chave que vai ser utilizada no filtro baseado no tipo de parâmetro.
 * @param {Object} filter - Um filtro do catálogo
 * @param {string} filter.key - Chave do filtro, utilizado nas buscas por atributos
 * @param {string} filter.param - Nome do parâmetro ou nome do atributo com prefixo `attr-`
 * @param {string} filter.value - Valor do filtro.
 * @returns {string} Chave a ser utilizada no filtro
 */
function extractParamKey({ param, key }) {
  return isAttributeFilter(param) ? key : param;
}
