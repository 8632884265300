import handleValue, {
  convertToLowerCase,
  removeSymbols,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  removeBrlSymbol,
  removeExcessDashes,
  useDashAsSeparator,
} from '../../../../libs/handleValue';

export const handleTagText = handleValue(
  convertToLowerCase,
  removeBrlSymbol,
  removeSymbols,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  useDashAsSeparator,
  removeExcessDashes,
);
