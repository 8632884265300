import {
  dispatchToGtm,
  getEncryptedValue,
  handleString,
  getValue,
  getSalesChannel,
} from '../utils';

const tagCustomDimensions = (isLoggedIn, registrationId, store, device, salesChannel) => {
  const params = {
    dimension1: undefined,
    dimension4: isLoggedIn ? 'logged-in' : 'logged-out',
    dimension5: getEncryptedValue(registrationId),
    dimension6: getValue(store),
    dimension7: getValue(device),
    dimension23: getSalesChannel(salesChannel),
  };
  dispatchToGtm(params);
};

const tagHeaderLinkClick = (link) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:geral',
    eventAction: 'clique:header',
    eventLabel: handleString(link),
  };
  dispatchToGtm(params);
};

const tagSearchError = (userQuery, errorMessage) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:geral',
    eventAction: 'interacao:busca',
    eventLabel: `${handleString(userQuery)}:${handleString(errorMessage)}`,
  };
  dispatchToGtm(params);
};

const tagPageLoadError = (errorMessage) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:geral',
    eventAction: 'erro:carregamento',
    eventLabel: handleString(errorMessage),
    noInteraction: '1',
  };
  dispatchToGtm(params);
};

const tagNavigationToPreviousPage = (pageName) => {
  const params = {
    event: 'event',
    eventCategory: `estore:${handleString(pageName)}`,
    eventAction: 'clique:botao',
    eventLabel: 'voltar',
  };
  dispatchToGtm(params);
};

export {
  tagCustomDimensions,
  tagHeaderLinkClick,
  tagSearchError,
  tagPageLoadError,
  tagNavigationToPreviousPage,
};
