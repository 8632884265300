import { useQuery } from 'react-query';
import { CianoService } from '../..';

export function useGetStoreFront({ path, config = {} }) {
  const cianoStoreFront = useQuery(
    ['cianoStoreFront', path],
    () => CianoService.getStoreFront({ path }),
    config,
  );

  return { ...cianoStoreFront, storeFront: cianoStoreFront.data?.data?.response };
}
