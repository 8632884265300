import styled from 'styled-components';
import { DEVICE } from '../../../../../styles/styledTheme';

const Container = styled.div`
  display: grid;
  gap: 12px;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  padding: 15px 20px;
  background-color: #efefef;
  border: 2px solid #ababab;
  border-radius: 4px;
  font-family: 'Arboria', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;
  cursor: pointer;
  outline: none;

  &.selected {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
  }

  ${DEVICE.Totem} {
    padding: 12px 16px;
    font-size: 16px;
  }
`;

export { Container, Title, ButtonGroup, Button };
