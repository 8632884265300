import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { ReactComponent as CheckoutIcon } from '../../../../assets/images/vector/checkoutIcon.svg';
import { DEVICE } from '../../../../styles/styledTheme';

const Container = styled.div`
  width: 100%;
`;

const ProductsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
`;

const DiscontContainer = styled(ProductsContainer)`
  padding-top: 0;
`;

const DiscountLabel = styled.span`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.greenHaze};
`;

const TotalContainer = styled.div`
  width: 100%;
  padding: 16px 32px;
  background-color: #f4f4f4;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

const TotalLabel = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.doveGray};
`;

const TotaValue = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

const ControlContainer = styled.div`
  width: 100%;
  padding: 16px 32px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 8px;

  ${DEVICE.Smartphone} {
    grid-auto-flow: row;
    justify-content: stretch;
  }
`;

const ContainedButton = styled(MuiButton).attrs({
  variant: 'contained',
  color: 'primary',
  size: 'large',
  fullWidth: true,
})`
  .MuiButton-label {
    text-transform: none;
    white-space: nowrap;
  }
`;

const TextButton = styled(MuiButton).attrs({
  variant: 'text',
  color: 'primary',
  size: 'large',
  fullWidth: true,
})`
  .MuiButton-label {
    text-transform: none;
    text-decoration: underline;
    white-space: nowrap;
  }
`;

const PurchaseEndingIcon = styled(CheckoutIcon)`
  width: 20px;

  path {
    fill: #ffffff;
  }
`;

export {
  Container,
  DiscontContainer,
  DiscountLabel,
  TotalContainer,
  TotalLabel,
  TotaValue,
  ControlContainer,
  ContainedButton,
  TextButton,
  ProductsContainer,
  PurchaseEndingIcon,
};
