import { adminApi } from '../../libs/axios';

const addressByZipcodeService = () => {
  /**
   * @typedef {Object} GetAddressByZipcodeData
   * @property {string} zipCode
   * @param {GetAddressByZipcodeData} data
   */
  const getAddressByZipcode = async (data) => {
    return adminApi.get('/v1/address-by-zipcode', {
      params: data,
      validateStatus: (status) => status === 200,
    });
  };

  return {
    getAddressByZipcode,
  };
};

export default addressByZipcodeService;
