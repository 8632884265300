import { useCallback, useEffect, useState } from 'react';
import {
  useActiveFilters,
  useCategoryQuery,
  usePagination,
  useProductsList,
  useSelectCategory,
  useBagDrawer,
  useSearchForm,
} from '../../hooks';
import {
  tagCategorySelection,
  tagCustomDimensions,
  tagMoreProductsButtonClick,
  tagSortOrderOptionSelection,
  tagSubcategorySelection,
} from '../../../gtm';
import { useSelector } from 'react-redux';
import { selectApp, selectStore } from '../../../store/utils';
import { tagSelectContentSearch } from '../../../gtm/ga4/events';
import { FLOW } from '../../../gtm/ga4/helpers/constants';
import { FiltersUtils, SearchUtils } from '../../utils';
import useGetOffline from '../../hooks/useGetOffline';

export function useCategoryViewModel({ categorySlug, route, originId }) {
  const [isBagDrawerOpen, toggleBagDrawer] = useBagDrawer();
  const { handleSearch } = useSearchForm();
  const [handleNetworkError] = useGetOffline();
  const { products, handleProducts } = useProductsList();
  const { page, offset, isPaginating, loadMore, resetPagination } = usePagination({
    itemsPerPage: 48,
  });
  const [activeFilters, toggleFilter, filterQueryParams] = useActiveFilters();
  const { models, operations } = useSelectCategory({ categorySlug });
  const { categories, subCategories, selectedCategories, queriedCategory } = models;
  const { setCategories, setSelectedCategoryLevel, isSelected } = operations;
  const [sortBy, setSortBy] = useState(() => {
    if (route) {
      return SearchUtils.getDefaultSortOptionCiano().value;
    }
    return SearchUtils.getDefaultSortOption().value;
  });
  const { attributes, ...paramFilters } = filterQueryParams;
  const categoryQuery = useCategoryQuery(
    {
      category: queriedCategory,
      attributes,
      offset,
      sort: SearchUtils.getSortOptionValue(sortBy),
      paramFilters,
      route,
      originId,
    },
    {
      onSuccess: (data) => {
        const remoteData = data?.data?.data;
        setCategories(remoteData?.categories);

        handleProducts(remoteData?.response, isPaginating);
      },
      onError: handleNetworkError,
    },
  );

  const filters = categoryQuery.filters
    .filter(FiltersUtils.excludeEmptyFilter)
    .map((filter) => FiltersUtils.setInitialValue(filter, activeFilters));

  // Tagueamento
  const { isLoggedIn, user } = useSelector(selectApp);
  const { store, device, salesChannel } = useSelector(selectStore);

  const handleToggleFilter = useCallback(
    ({ filter, option }) => {
      resetPagination();
      toggleFilter({ filter, option });
    },
    [resetPagination, toggleFilter],
  );

  const handleLoadMore = () => {
    tagMoreProductsButtonClick();
    loadMore();
  };

  const updateSelectedCategory = (id, level) => {
    resetPagination();
    setSelectedCategoryLevel(id, level);

    if (isSelected(id)) return;

    tagSelectContentSearch({
      content_type: id,
      flow: FLOW.PLP,
    });
  };

  const onChangeCategory = (id) => {
    updateSelectedCategory(id, 1);

    if (isSelected(id)) return;

    tagCategorySelection(id);
  };

  const onChangeSubcategory = (id) => {
    updateSelectedCategory(id, 2);

    if (isSelected(id)) return;

    tagSubcategorySelection(id);
  };

  const handleSort = (selectedSortByValue) => {
    resetPagination();
    const selectedSortOption = SearchUtils.sortOrder.find(
      ({ value }) => value === selectedSortByValue,
    );
    tagSortOrderOptionSelection(selectedSortOption.label);
    setSortBy(selectedSortByValue);
  };

  // TODO: Talvez mover para a home
  useEffect(
    function tagCategoryEffect() {
      tagSelectContentSearch({
        content_type: categorySlug,
        flow: FLOW.HOME,
      });
    },
    [categorySlug],
  );

  useEffect(
    function tagCustomDimensionsEffect() {
      tagCustomDimensions(
        isLoggedIn,
        user?.registration,
        store?.label,
        device?.serialNumber,
        salesChannel,
      );
    },
    [isLoggedIn, user?.registration, store?.label, device?.serialNumber, salesChannel],
  );

  return {
    models: {
      isLoading: categoryQuery.isLoading,
      isFetching: categoryQuery.isFetching,
      isSuccess: categoryQuery.isSuccess,
      isError: categoryQuery.isError,
      categories,
      currentPage: page,
      filters,
      hasNextPage: categoryQuery?.data?.data?.data?.hasNext || false,
      selectedCategories,
      subCategories,
      products,
      sortBy,
      isBagDrawerOpen,
      hasCianoFilter: !!route,
    },
    operations: {
      onChangeCategory,
      onChangeSubcategory,
      toggleFilter: handleToggleFilter,
      loadMore: handleLoadMore,
      handleSort,
      toggleBagDrawer,
      handleSearch,
    },
  };
}
