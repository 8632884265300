import { format, parseISO } from '../../../utils/dates';

const getDateAndTime = (dateISO) => {
  const parsedDate = parseISO(dateISO);

  const formattedDate = format(parsedDate, 'dd/MM/yy - H:mm');

  return formattedDate;
};

const createQrCode = (dto) => {
  const { id, code, createdAt, salesChannelCode, status, orderCode } = dto;

  return {
    id,
    code: `${code}`,
    createdDate: getDateAndTime(createdAt),
    channel: salesChannelCode,
    status,
    orderCode,
  };
};

export const parseRecoveryCartData = (response) => {
  const qrCodes = response?.data?.data?.items?.map(createQrCode);

  return {
    ...response,
    data: {
      ...response.data,
      data: {
        ...response.data.data,
        items: qrCodes,
      },
    },
  };
};
