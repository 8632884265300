import styled from 'styled-components';
import { DEVICE } from '../../../styles/styledTheme';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template: 'left center right';
  justify-content: space-between;
  align-content: start;
  gap: 20px;

  ${DEVICE.Tablet} {
    grid-template:
      'left right'
      'center center';
  }
`;

export { Container };
