import { useSelector } from 'react-redux';
import { selectStore, getLocationId, getSellerId, getBusinessId } from '../../store/utils';
import { SearchUtils } from '../utils';

export function useSearchDefaultParams() {
  const { store } = useSelector(selectStore);

  const sellerId = getSellerId(store?.prefix);

  return {
    ...SearchUtils.defaultParameters,
    businessId: getBusinessId(store?.parameters),
    locationId: getLocationId(store?.parameters),
    seller: sellerId,
  };
}
