import styled from 'styled-components';
import { SoldAndDeliveredBy as SoldAndDeliveredByToClone } from '../SoldAndDeliveredBy';

const Container = styled.div`
  width: 100%;
  max-width: 280px;
  cursor: pointer;
  display: grid;
  align-content: start;
  gap: 8px;
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1.245;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  align-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
`;

const Image = styled.img.attrs({
  loading: 'lazy',
})`
  width: 100%;
`;

const DiscountContainer = styled.div`
  padding: 4px 8px;
  background-color: #000000;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
`;

const DiscountText = styled.p`
  font-size: 12px;
  color: #ffffff;
`;

const TagContainer = styled.div`
  width: 100%;
  padding: 4px 8px;
  background-color: ${({ color }) => color || '#000000'};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const TagText = styled.p`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
`;

const PriceContainer = styled.div`
  display: grid;
  gap: 12px;
`;

const ListPrice = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.black};
`;

const SalePrice = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: ${({ theme, hasDiscount }) =>
    hasDiscount ? theme.colors.cedarChest : theme.colors.black};
`;

const ColorList = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 8px;
`;

const ColorItem = styled.li`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ hexColor }) => hexColor};
`;

const ColorText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray};
`;

const SoldAndDeliveredBy = styled(SoldAndDeliveredByToClone)`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: inherit;

  span {
    font-weight: 500;
    color: inherit;
  }
`;

export {
  Container,
  ImageContainer,
  Image,
  DiscountContainer,
  DiscountText,
  TagContainer,
  TagText,
  Title,
  PriceContainer,
  SalePrice,
  ListPrice,
  ColorList,
  ColorItem,
  ColorText,
  SoldAndDeliveredBy,
};
