const excludeEmptyFilter = ({ filters, param }) => filters.length > 0 && param !== 'category';

const setInitialValue = (filter, activeFilters) => {
  return {
    ...filter,
    initialValue: activeFilters[filter.id],
  };
};

export const FiltersUtils = {
  excludeEmptyFilter,
  setInitialValue,
};
