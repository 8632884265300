import { useRef, useEffect } from 'react';

const useBeforeUnload = (callbackFunction) => {
  const onBeforeUnloadRef = useRef(callbackFunction);

  useEffect(() => {
    const onBeforeUnload = onBeforeUnloadRef.current;
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnloadRef]);
};

export default useBeforeUnload;
