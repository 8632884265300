import { dispatchToGtm } from '../utils';

const tagQrCodeCloseButtonClick = () => {
  const params = {
    event: 'event',
    eventCategory: 'estore:qr-code',
    eventAction: 'clique:botao',
    eventLabel: 'fechar',
  };
  dispatchToGtm(params);
};

export { tagQrCodeCloseButtonClick };
