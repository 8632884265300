import React from 'react';
import PropTypes from 'prop-types';
import { tagHeaderLinkClick } from '../../../gtm';
import * as S from './Logo.styles';

import RiachueloLogoImage from '../../../assets/images/vector/riachueloLogo.svg';
import FanLabLogoImage from '../../../assets/images/raster/logoFanlab.png';

const Logo = ({ onClick, brand = 'rchlo' }) => {
  // Initial value
  const isClickable = typeof onClick === 'function';

  // Reacts to click
  const handleClick = () => {
    if (isClickable) {
      tagHeaderLinkClick('Riachuelo');
      onClick();
    }
  };

  return (
    <S.Container onClick={handleClick} isClickable={isClickable}>
      <S.BrandLogo src={getBrandImage(brand)} />
    </S.Container>
  );
};

Logo.propTypes = {
  onClick: PropTypes.func,
  brand: PropTypes.oneOf(['rchlo', 'fanlab', 'carters']),
};

function getBrandImage(brand) {
  if (brand === 'fanlab') return FanLabLogoImage;
  return RiachueloLogoImage;
}

export default Logo;
