/* eslint-disable react-hooks/rules-of-hooks */
import { useExceptionDispatch, useSearchDispatch, useSelectContentDispatch } from '@estore/gtm-v4';
import { EVENTNAMES } from '../helpers/constants';
import { GtmException } from '../utils';

export const tagSearch = (param) => {
  try {
    useSearchDispatch({
      search_term: param,
    });
  } catch (error) {
    throw new GtmException(error, 'tagSearch');
  }
};

export const tagSearchException = (params) => {
  try {
    useExceptionDispatch({
      ...params,
      flow: EVENTNAMES.SEARCH,
    });
  } catch (error) {
    throw new GtmException(error, 'tagSearchException');
  }
};

export const tagSelectContentSearch = (params) => {
  try {
    useSelectContentDispatch(params);
  } catch (error) {
    throw new GtmException(error, 'tagSelectContentSearch');
  }
};
