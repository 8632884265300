import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Footer.styles';

const Footer = ({
  productsPrice,
  onKeepBuying,
  onProceedToCheckout,
  totalPrice,
  discountPrice,
  productsQuantity,
}) => {
  return (
    <S.Container>
      {productsQuantity && (
        <S.ProductsContainer>
          <span>{`${productsQuantity} ${productsQuantity > 1 ? 'produtos' : 'produto'}`}</span>
          <span>{productsPrice}</span>
        </S.ProductsContainer>
      )}
      {discountPrice && (
        <S.DiscontContainer>
          <S.DiscountLabel>{`- ${discountPrice}`}</S.DiscountLabel>
        </S.DiscontContainer>
      )}
      <S.TotalContainer>
        <S.TotalLabel>Total</S.TotalLabel>
        <S.TotaValue>{totalPrice}</S.TotaValue>
      </S.TotalContainer>
      <S.ControlContainer>
        <S.TextButton onClick={onKeepBuying}>Continuar comprando</S.TextButton>
        <S.ContainedButton startIcon={<S.PurchaseEndingIcon />} onClick={onProceedToCheckout}>
          Finalizar compra
        </S.ContainedButton>
      </S.ControlContainer>
    </S.Container>
  );
};

Footer.propTypes = {
  productsPrice: PropTypes.string.isRequired,
  onKeepBuying: PropTypes.func.isRequired,
  onProceedToCheckout: PropTypes.func.isRequired,
};

export default Footer;
