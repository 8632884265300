import { removeDuplicateTerm } from './dedupe';
import { getProperty } from './getProperty';

export function createItemProductLifestyle(items, splitter = ':') {
  if (items.length === 1) {
    return getProperty(items?.[0]?.product?.attributes, 'lifestyle', 'code')?.toLowerCase();
  }
  const text = items.reduce((acc, { product }) => {
    const value = getProperty(product?.attributes, 'lifestyle', 'code');
    return `${acc}${!value ? '' : `${value}${splitter}`}`;
  }, '');
  return !text ? undefined : removeDuplicateTerm(text, splitter);
}

export function listItemProductLifestyleWithSellers(sellers, splitter = ':') {
  if (sellers.length === 1) {
    return createItemProductLifestyle(sellers?.[0].items, splitter);
  }
  const getText = sellers.reduce((acc, { items }) => {
    const text = createItemProductLifestyle(items, splitter);
    return `${acc}${!text ? '' : `${text}${splitter}`}`;
  }, '');
  return !getText ? undefined : removeDuplicateTerm(getText, splitter);
}
