import { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { clearCart } from '../../store/ducks/cart';
import { clearShipping } from '../../store/ducks/shipping';
import { clearQrCode } from '../../store/ducks/qrCode';

const useClearCheckoutStores = () => {
  const dispatch = useDispatch();
  const clearCheckoutStores = useCallback(() => {
    batch(() => {
      dispatch(clearCart());
      dispatch(clearShipping());
      dispatch(clearQrCode());
    });
  }, [dispatch]);

  return clearCheckoutStores;
};

export default useClearCheckoutStores;
