import { catalogApi } from '../../libs/axios';

const categoriesService = () => {
  /**
   * @typedef {Object} GetSubCategoriesData
   * @property {string} parent-category
   * @property {number} [page-index]
   * @property {number} [page-size]
   * @param {GetSubCategoriesData} data
   */
  const getRemoteCategories = async (data) => {
    const DEFAULT_PAGE_SIZE = 100;
    const DEFAULT_PAGE_INDEX = 0;

    return catalogApi.get('v3/categories/sub-categories', {
      params: {
        'page-size': DEFAULT_PAGE_SIZE,
        'page-index': DEFAULT_PAGE_INDEX,
        ...data,
      },
    });
  };

  return {
    getRemoteCategories,
  };
};

export default categoriesService;
