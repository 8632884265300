import { useCallback, useState } from 'react';

export const useModal = () => {
  const [modalData, setModalData] = useState(null);

  const setContent = useCallback((data) => {
    setModalData(data);
  }, []);

  const removeContent = useCallback(() => {
    setModalData(null);
  }, []);

  return [modalData, setContent, removeContent];
};
