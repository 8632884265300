import React from 'react';
import PropTypes from 'prop-types';
import { Center, Left, Right } from './components';
import * as S from './Header.styles';

const Header = ({ variant = 'ghost', children }) => {
  const slots = new Map();
  const allowedTypes = [Left, Center, Right];
  const content = React.Children.map(children, (item) => {
    if (allowedTypes.includes(item?.type)) {
      if (!slots.has(item?.type)) {
        slots.set(item?.type, 0);
      }
      const items = slots.get(item?.type);
      slots.set(item?.type, items + 1);
      return item;
    }
    return null;
  });

  return (
    <S.Container variant={variant}>
      <S.InnerContainer>{content}</S.InnerContainer>
    </S.Container>
  );
};

Header.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Header.Left = Left;

Header.Center = Center;

Header.Right = Right;

export default Header;
