import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Header.styles';

const Header = ({ onCloseDrawerIconClick }) => {
  return (
    <S.Container>
      <S.Title>Sua sacola</S.Title>
      <S.CloseDrawerIcon onClick={onCloseDrawerIconClick} />
      <S.Line />
    </S.Container>
  );
};

Header.propTypes = {
  onCloseDrawerIconClick: PropTypes.func.isRequired,
};

export default Header;
