import React from 'react';
import PropTypes from 'prop-types';
import { NetworkChecker } from '../../components';

const BaseLayout = ({ children }) => {
  return (
    <>
      <div>{children}</div>
      <NetworkChecker />
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
