import { useCallback, useState } from 'react';

const getQueriedCategory = (categories) =>
  categories.length > 0 ? categories.slice(-1) : undefined;
const getSubCategories = (categoryId, categories) => {
  const category = categories?.find(({ id }) => id === categoryId);

  if (!category) return [];

  return category.categories;
};

export default function useSelectCategory({ categorySlug }) {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(() =>
    categorySlug ? [categorySlug] : [],
  );
  const isSelected = (category) => categories.includes(category);

  const setSelectedCategoryLevel = useCallback((subCategorySlug, subCategoryLevel) => {
    setSelectedCategories((prevSelectedCategories) => {
      const higherLevelCategories = prevSelectedCategories.slice(0, subCategoryLevel);

      if (prevSelectedCategories.includes(subCategorySlug)) {
        return [...higherLevelCategories];
      }

      return [...higherLevelCategories, subCategorySlug];
    });
  }, []);

  const queriedCategory = getQueriedCategory(selectedCategories);

  return {
    models: {
      categories,
      subCategories: getSubCategories(selectedCategories?.[1], categories),
      selectedCategories,
      queriedCategory,
    },
    operations: {
      isSelected,
      setCategories,
      setSelectedCategoryLevel,
    },
  };
}
