import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BagIcon } from '../../../assets/images/vector/bagIcon.svg';
import { tagHeaderLinkClick } from '../../../gtm';
import * as S from './ShoppingBag.styles';

const ShoppingBag = ({ itemsQuantity, onBagClick, message = 'Bem-vindo à Riachuelo' }) => {
  const handleClick = () => {
    tagHeaderLinkClick('Sacola');
    onBagClick();
  };

  return (
    <S.Container>
      <S.Text>{message}</S.Text>
      <S.BagContainer>
        <BagIcon onClick={handleClick} />
        {itemsQuantity > 0 && <S.Badge>{itemsQuantity}</S.Badge>}
      </S.BagContainer>
    </S.Container>
  );
};

ShoppingBag.propTypes = {
  itemsQuantity: PropTypes.number.isRequired,
  onBagClick: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default ShoppingBag;
