import styled from 'styled-components';
import { Limit } from '../Limit';
import { ReactComponent as EstoreLogo } from '../../../assets/images/vector/estoreLogo.svg';

const Container = styled.header`
  background-color: #f4f4f4;
`;

const InnerContainer = styled(Limit)`
  height: 50px;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const Logo = styled(EstoreLogo)`
  width: 280px;
`;

export { Container, InnerContainer, Logo };
