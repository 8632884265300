import React from 'react';
import PropTypes from 'prop-types';
import { allowedAsElements, variations } from './Typography.utils';
import * as S from './Typography.styles';

const Typography = ({ children, variation = variations.BODY1, as = allowedAsElements.p }) => {
  return (
    <S.Typography as={as} variation={variation}>
      {children}
    </S.Typography>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOf(Object.values(allowedAsElements)),
  variation: PropTypes.oneOf(Object.values(variations)),
};

export default Typography;
