import styled from 'styled-components';
import { SoldAndDeliveredBy as SoldAndDeliveredByToClone } from '../SoldAndDeliveredBy';

const Container = styled.div`
  width: 100%;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  gap: 12px;
`;

const SoldAndDeliveredBy = styled(SoldAndDeliveredByToClone)`
  font-size: 13px;
`;

const ShippingCount = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

export { Container, ShippingCount, SoldAndDeliveredBy };
