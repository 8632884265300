import styled from 'styled-components';
import MuiAlert from '@material-ui/lab/Alert';
import { ReactComponent as TrashIcon } from '../../../assets/images/vector/trashIcon.svg';

const Container = styled.div`
  display: grid;
  gap: 8px;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

const ImageContainer = styled.div`
  width: 140px;
  aspect-ratio: 1 / 1;
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  align-content: center;
  overflow: hidden;
`;

export const RemoveIconContainer = styled.div(() => ({
  gridColumnStart: 4,
  gridColumnEnd: 5,
  gridRow: '1 / 3',
  justifySelf: 'end',
}));

const Image = styled.img`
  width: 100%;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 112px 1fr 88px 30px;
  grid-template-rows: auto;
  justify-items: start;
  align-content: space-between;
  gap: 8px 4px;
`;

const TagText = styled.p`
  grid-column-start: 1;
  grid-column-end: 4;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`;

const Name = styled.p`
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.miniShaft};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VariationContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  display: grid;
  gap: 4px;
`;

export const RemainingUnitsContainer = styled.div(() => ({
  width: '100%',
  maxWidth: 184,
  gridColumnEnd: 4,
  gridColumnStart: 1,
  '& div': {
    fontSize: 12,
    height: 20,
  },
  '& svg': {
    height: 12,
  },
}));

export const CounterContainer = styled.div(() => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  alignSelf: 'end',
  width: '100%',
}));

const Variation = styled.p`
  font-size: 13px;
  color: #717171;
`;

const VariationValue = styled.span`
  color: #000000;
`;

const EndContainer = styled.div`
  display: grid;
  justify-items: end;
  align-content: space-between;
`;

const RemoveIcon = styled(TrashIcon).attrs({
  title: 'Remover item',
})`
  width: 22px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.doveGray};
  }
`;

const PriceGroup = styled.div`
  display: grid;
  justify-items: end;
  gap: 4px;
  justify-self: end;
  grid-column-end: 5;
  grid-column-start: 3;
`;

const PriceContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 4px;
`;

const ListPrice = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.doveGray};
  text-decoration: line-through;
  white-space: nowrap;
`;

const SalePrice = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
`;

const DiscountText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
`;

const ErrorAlert = styled(MuiAlert).attrs({
  severity: 'error',
  icon: false,
})``;

export {
  Container,
  InnerContainer,
  ImageContainer,
  Image,
  Info,
  TagText,
  Name,
  VariationContainer,
  Variation,
  VariationValue,
  EndContainer,
  RemoveIcon,
  PriceGroup,
  PriceContainer,
  ListPrice,
  SalePrice,
  DiscountText,
  ErrorAlert,
};
