import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';

export const InactivityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 100px;
  max-width: 1050px;
  height: fit-content;
  width: fit-content;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #5d5d5d;
  border-radius: 8px;
  box-shadow: 0px 16px 60px #00000029;
  z-index: 100000;
  padding: 24px 40px;

  @media (max-width: 904px) {
    flex-direction: column;
    padding: 16px 24px;
  }

  @media (max-width: 480px) {
    padding: 16px;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 100%;

  @media (max-width: 904px) {
    margin: 0 0 32px 0;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const TimerCircle = styled.div`
  height: 80px;
  width: 80px;
  flex-shrink: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  @media (max-width: 480px) {
    margin: 0 0 16px 0;
  }
`;

export const Message = styled.p`
  font-size: 22px;
  color: #fff;
  min-width: 288px;
`;

export const Button = styled(MuiButton).attrs({
  width: '100%',
  fontSize: '1rem',
  height: '3rem',
})``;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .continueConnected {
    width: 100%;
    background-color: #ffffff;
    color: #707070;
    margin-right: 0.4rem;
    min-width: 187px;
    &:hover {
      background-color: #ffffff;
      color: #707070;
    }
  }

  .finish {
    width: 100%;
    background-color: #727272;
    color: #ffffff;
    margin-left: 0.4rem;
    min-width: 187px;
    &:hover {
      background-color: #727272;
      color: #ffffff;
    }
  }

  @media (max-width: 904px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    .continueConnected {
      margin: 0 0 16px 0;
    }

    .finish {
      margin: 0;
    }
  }
`;
