import { SHIPPING_ORIGINS } from '../../store/utils';
import {
  dispatchToGtm,
  getErrorMessageFormattedTagFreight,
  handleString,
  getDataFromAllMethods,
  getShippingOriginsErrorMessage,
} from '../utils';

const tagBagCallback = (isResolved, errorMessage) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${handleString(errorMessage)}`;
  const params = {
    event: 'event',
    eventCategory: 'estore:modal-sacola',
    eventAction: 'callback:modal-sacola',
    eventLabel,
  };
  dispatchToGtm(params);
};

const tagBagButtonClick = (isCheckout, buttonName) => {
  const eventCategory = isCheckout ? 'estore:sacola' : 'estore:modal-sacola';
  const params = {
    event: 'frete',
    eventCategory,
    eventAction: 'clique:botao',
    eventLabel: handleString(buttonName),
  };
  dispatchToGtm(params);
};

const tagFreightException = (errorMessage) => {
  const eventLabel = getErrorMessageFormattedTagFreight(errorMessage);

  const params = {
    event: 'callback',
    eventCategory: 'estore:sacola',
    eventAction: 'sacola:frete',
    eventLabel,
  };

  dispatchToGtm(params);
};

const handleFreightExceptions = (freightData) => {
  const shippingOriginsList = Object.keys(SHIPPING_ORIGINS);

  const exceptions = freightData.filter(({ origin }) => shippingOriginsList.includes(origin));

  if (exceptions.length === 0) {
    return;
  }

  exceptions.forEach(({ origin }) => {
    const messageFormatted = getShippingOriginsErrorMessage(origin);

    tagFreightException(messageFormatted);
  });
};

const tagFreightAvailable = (freightData) => {
  const { formattedFreightDataList } = getDataFromAllMethods(freightData);

  const hasFreightOptions =
    Array.isArray(formattedFreightDataList) && formattedFreightDataList.length;

  if (hasFreightOptions) {
    formattedFreightDataList.forEach(({ eventAction, eventLabelsFormatted: eventLabel }) => {
      const params = {
        event: 'event',
        eventCategory: 'estore:sacola',
        eventAction: `sacola:${eventAction}`,
        eventLabel,
      };
      dispatchToGtm(params);
    });
  }
};

const handleFreightAvailable = (freightData) => {
  const shippingOriginsList = Object.keys(SHIPPING_ORIGINS);

  const availables = freightData.filter(({ origin }) => !shippingOriginsList.includes(origin));

  if (availables.length === 0) {
    return;
  }

  tagFreightAvailable(availables);
};

const handleFreightSuccess = (freightData) => {
  handleFreightExceptions(freightData);
  handleFreightAvailable(freightData);
};

export {
  tagBagCallback,
  tagBagButtonClick,
  handleFreightSuccess,
  tagFreightAvailable,
  tagFreightException,
};
