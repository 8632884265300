import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOutsideClick } from '../../hooks';
import * as S from './Select.styles';

const Select = ({ label, value, children, onReset }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionsVisibility = useCallback((evt) => {
    evt.stopPropagation();
    setIsOpen((openStatus) => !openStatus);
  }, []);

  useEffect(() => {
    const matchedOption = children.find(({ props }) => props.value === value);
    setSelectedOption(matchedOption);
    setIsOpen(false);
  }, [value]);

  const handleResetButtonClick = useCallback((evt) => {
    evt.stopPropagation();
    onReset();
  }, []);

  const containerRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <S.Container ref={containerRef}>
      <S.LabelContainer onClick={handleOptionsVisibility} isOpen={isOpen}>
        <S.Label>{selectedOption ? selectedOption.props.children : label}</S.Label>
        {!selectedOption && <S.DownArrow />}
        {selectedOption && (
          <S.ResetButton onClick={handleResetButtonClick}>
            <S.ResetIcon />
          </S.ResetButton>
        )}
      </S.LabelContainer>
      <S.OptionList isOpen={isOpen}>{children}</S.OptionList>
    </S.Container>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onReset: PropTypes.func.isRequired,
};

export default Select;
