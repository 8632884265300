import { SORT_ORDER_OPTIONS, SORT_ORDER_OPTIONS_CIANO } from '../../store/ducks/products';
import { CORPORATE_CATALOG_BUSINESS_ID, LOCATION_ID } from '../../store/utils';

const productsPerPage = 48;

const getDefaultSortOption = () => {
  return SORT_ORDER_OPTIONS?.[0];
};

const getDefaultSortOptionCiano = () => {
  return SORT_ORDER_OPTIONS_CIANO?.[0];
};

const getSortOptionValue = (sort) => {
  if (isDefaultFilter(sort)) return undefined;
  return sort;
};

const isDefaultFilter = (value) => {
  return !!value.find((item) => item === 'default');
};

const defaultParameters = {
  businessId: CORPORATE_CATALOG_BUSINESS_ID,
  locationId: LOCATION_ID,
  richResult: true,
  soldOut: false,
  cached: true,
  limit: productsPerPage,
  sort: getDefaultSortOption()?.value,
};

export const SearchUtils = {
  productsPerPage: 48,
  sort: getDefaultSortOption().value,
  defaultParameters,
  sortOrder: SORT_ORDER_OPTIONS_CIANO,
  getDefaultSortOption,
  getDefaultSortOptionCiano,
  getSortOptionValue,
  isDefaultFilter,
};
