import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './Counter.styles';

const Counter = ({
  value,
  minValue,
  maxValue,
  disableMin,
  disableMax,
  onDecrement,
  onIncrement,
  onReachMin,
  onReachMax,
}) => {
  const [count, setCount] = useState(value);

  // Decrements the value
  const handleDecrement = () => {
    if (
      typeof minValue === 'number' &&
      typeof onReachMin === 'function' &&
      value === minValue + 1
    ) {
      onReachMin();
    } else {
      onDecrement();
    }
  };

  // Increments the value
  const handleIncrement = () => {
    if (typeof maxValue === 'number' && typeof onReachMax === 'function' && value === maxValue) {
      onReachMax();
    } else {
      onIncrement();
    }
  };

  const handleChange = (event) => {
    setCount(event.target.value);
  };

  /**
   * Função que verifica se o usuário está incrementando ou decrementando ao utilizar o input de quantidade.
   * Manipula o valor digitado, incrementando ou decrementando a quantidade.
   * @param {*} desiredQuantity quantidade desejada que é informada no input.
   * @param {*} currentQuantity quantidade atual que está no input.
   * @returns Realiza o incremento ou decremento da quantidade de um determinado produto.
   */
  const handleChangeQuantity = (desiredQuantity, currentQuantity) => {
    const quantity = Math.abs(desiredQuantity - currentQuantity);

    if (desiredQuantity === 0) {
      onReachMin();
      setCount(currentQuantity);
      return;
    }

    if (desiredQuantity > maxValue) {
      onReachMax({ remainingUnits: maxValue });
      setCount(currentQuantity);
      return;
    }

    if (desiredQuantity < currentQuantity) {
      onDecrement(quantity);
      return;
    }

    return onIncrement(quantity);
  };

  const checkDesiredQuantityEqualCurrent = () => Number(count) === Number(value);

  const handleSubmitQuantity = () => {
    if (checkDesiredQuantityEqualCurrent()) return;
    handleChangeQuantity(Number(count), Number(value));
  };

  return (
    <S.Container>
      <S.Button disabled={disableMin || minValue >= value} onClick={handleDecrement}>
        <S.DecrementIcon />
      </S.Button>
      <S.InputValue
        type="number"
        value={count}
        onChange={handleChange}
        onBlur={handleSubmitQuantity}
      />
      <S.Button disabled={disableMax || maxValue <= value} onClick={handleIncrement}>
        <S.IncrementIcon />
      </S.Button>
    </S.Container>
  );
};

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disableMin: PropTypes.bool,
  disableMax: PropTypes.bool,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onReachMin: PropTypes.func,
  onReachMax: PropTypes.func,
};

export default Counter;
