import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  width: 77px;
  height: 1px;
  margin-right: 5px;
  background-color: #999999;
`;

export { Container, Line };
