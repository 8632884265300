import styled from 'styled-components';

const Form = styled.form`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
`;

const TextInput = styled.input.attrs({
  type: 'text',
})`
  min-width: 80px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  justify-self: stretch;
  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  justify-self: end;

  svg {
    height: 20px;
    outline: none;
    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export { Form, TextInput, SubmitButton };
