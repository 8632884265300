import styled from 'styled-components';

const Container = styled.div`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const BrandLogo = styled.img`
  width: 160px;
  outline: none;
`;

export { Container, BrandLogo };
