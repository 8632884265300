const {
  REACT_APP_ADMIN_BASE_URL,
  REACT_APP_FACADE_BASE_URL,
  REACT_APP_CATALOG_BASE_URL,
  REACT_APP_CHECKOUT_URL,
  REACT_APP_KEYCLOACK_INTERVAL_IN_MINUTES,
  REACT_APP_SECONDS,
  REACT_APP_TOTEM_NAMESPACE,
  REACT_APP_CIANO_BASE_URL,
  REACT_APP_CIANO_ROUTE_ORIGIN_ID,
} = process.env;

const ADMIN_BASE_URL = REACT_APP_ADMIN_BASE_URL;

const CATALOG_BASE_URL = REACT_APP_CATALOG_BASE_URL;

const FACADE_BASE_URL = REACT_APP_FACADE_BASE_URL;

const ENGINE_BASE_URL = `${FACADE_BASE_URL}/engine`;

const CIANO_BASE_URL = `${REACT_APP_CIANO_BASE_URL}/riachuelo/ciano`;

const MSITE_URL = REACT_APP_CHECKOUT_URL;

const SECONDS = REACT_APP_SECONDS || 60;
const KEYCLOACK_INTERVAL_IN_MINUTES = REACT_APP_KEYCLOACK_INTERVAL_IN_MINUTES || 3;

const INTERVAL = SECONDS * KEYCLOACK_INTERVAL_IN_MINUTES;

const TOTEM_NAMESPACE = REACT_APP_TOTEM_NAMESPACE;

const CIANO_ROUTE_ORIGIN_ID = REACT_APP_CIANO_ROUTE_ORIGIN_ID;

export {
  ADMIN_BASE_URL,
  CATALOG_BASE_URL,
  FACADE_BASE_URL,
  ENGINE_BASE_URL,
  CIANO_BASE_URL,
  MSITE_URL,
  SECONDS,
  KEYCLOACK_INTERVAL_IN_MINUTES,
  INTERVAL,
  TOTEM_NAMESPACE,
  CIANO_ROUTE_ORIGIN_ID,
};
