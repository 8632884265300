/**
 * The purpose of this library is to help the application handle
 * the different states of a response to an API
 */

const ResponseStatus = {
  Idle: 'IDLE',
  Pending: 'PENDING',
  Resolved: 'RESOLVED',
  Rejected: 'REJECTED',
};

const compareResponseStatus = (status, type) => status === type;

const isIdle = (status) => compareResponseStatus(status, ResponseStatus.Idle);

const isPending = (status) => compareResponseStatus(status, ResponseStatus.Pending);

const isResolved = (status) => compareResponseStatus(status, ResponseStatus.Resolved);

const isRejected = (status) => compareResponseStatus(status, ResponseStatus.Rejected);

export default ResponseStatus;
export { compareResponseStatus, isIdle, isPending, isResolved, isRejected };
