import { useLocation } from 'react-router-dom';

const useQueryStringParams = (paramKey) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(paramKey);

  return paramValue;
};

export default useQueryStringParams;
