import styled from 'styled-components';
import { ReactComponent as NetworkIssueIcon } from '../../assets/images/vector/networkIssueIcon.svg';
import { DEVICE } from '../../styles/styledTheme';

const PageHeader = styled.div`
  margin: 20px 0 60px;
`;

const WithoutInternetIcon = styled(NetworkIssueIcon)`
  width: 40px;

  path {
    fill: #ffffff;
  }
`;

const ButtonGroup = styled.div`
  margin: auto;
  padding-top: 24px;
  border-top: 1px solid #e0e0e0;
  width: 160px;
`;

const StackContent = styled.div`
  flex: 1;
`;

const Stack = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;

  ${DEVICE.TotemVertical},
  ${DEVICE.Totem} {
    flex-direction: column;
  }
`;

const StackMain = styled(StackContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StackIllustration = styled(StackContent)`
  text-align: center;

  svg {
    width: 100%;
    transition: all 0.3s ease-in;

    ${DEVICE.Totem} {
      width: 75%;
    }
  }
`;

const Description = styled.p`
  font-size: 28px;
  transition: all 0.3s ease-in;

  ${DEVICE.Totem} {
    font-size: 18px;
  }
`;

export {
  PageHeader,
  WithoutInternetIcon,
  ButtonGroup,
  Stack,
  StackMain,
  StackIllustration,
  Description,
};
