import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../assets/images/vector/verticalArrowIcon.svg';

const Container = styled.div`
  display: grid;
  justify-items: center;
  gap: 4px;
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 888;

  button {
    background-color: ${({ theme }) => theme.colors.gray};
    transition: background-color 400ms ease-out;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
    transition: color 400ms ease-out;
  }

  &:hover button {
    background-color: ${({ theme }) => theme.colors.tundora};
  }

  &:hover span {
    color: ${({ theme }) => theme.colors.tundora};
  }
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  width: 56px;
  height: 56px;
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
  display: grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
`;

const TopArrowIcon = styled(ArrowIcon)`
  width: 24px;
  transform: rotateZ(180deg);

  path {
    fill: #ffffff;
  }
`;

const Text = styled.span`
  font-size: 20px;
`;

export { Container, Button, TopArrowIcon, Text };
