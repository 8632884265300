import { cianoApi } from '../../libs/axios';

function getStoreFront({ path }) {
  return cianoApi.get(`v1/routes/RCHLO/ESTORE/${path}`, {
    params: {
      channel: 'WEB',
    },
    timeout: 30 * 1000,
  });
}

function getImagesRestScreen({ brand, channel }) {
  return cianoApi.get(`v1/routes/RCHLO/ESTORE/${brand}-START`, {
    params: {
      channel,
    },
    timeout: 30 * 1000,
  });
}

export const CianoService = {
  getStoreFront,
  getImagesRestScreen,
};
