import { dispatchToGtm, handleString } from '../utils';

const tagPaymentInstructionsButtonClick = (buttonName) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:instrucoes-qr-code',
    eventAction: 'clique:botao',
    eventLabel: handleString(buttonName),
  };
  dispatchToGtm(params);
};

export { tagPaymentInstructionsButtonClick };
