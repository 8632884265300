import { createSelector } from 'reselect';

// Constants
const STATES = {
  APP: 'app',
  STORE: 'store',
  PRODUCTS: 'products',
  CATEGORY: 'category',
  FILTERS: 'filters',
  PRODUCT: 'product',
  CART: 'cart',
  SHIPPING: 'shipping',
  QR_CODE: 'qrCode',
  REDIRECT_ROUTE: 'redirectRoute',
};
const CORPORATE_CATALOG_BUSINESS_ID = 'RCHLO';
const LOCATION_ID = 'L400';
const LIMIT = 48;
const SALES_CHANNELS = {
  DESKTOP: 'Desktop',
  WHATSAPP: 'Whatsapp',
};
const SALES_CHANNELS_LABELS = {
  [SALES_CHANNELS.DESKTOP]: 'Venda em loja',
  [SALES_CHANNELS.WHATSAPP]: 'Venda pelo WhatsApp',
};
const SHIPPING_ORIGINS = {
  SOLDOUT: 'SOLDOUT',
  UNAVAILABLE: 'UNAVAILABLE',
  BREAKED: 'BREAKED',
};
const PICK_UP_METHOD = {
  BOPIS: 'bopis',
  PICK_UP_STORE: 'pickUpStore',
};

// Selectors
const selectApp = (state) => state[STATES.APP];
const selectStore = (state) => state[STATES.STORE];
const selectProducts = (state) => state[STATES.PRODUCTS];
const selectCategory = (state) => state[STATES.CATEGORY];
const selectFilters = (state) => state[STATES.FILTERS];
const selectProduct = (state) => state[STATES.PRODUCT];
const selectCart = (state) => state[STATES.CART];
const selectShipping = (state) => state[STATES.SHIPPING];
const selectQrCode = (state) => state[STATES.QR_CODE];

const selectCartProducts = createSelector(selectCart, (cartState) => {
  const cartProducts = [];
  cartState.sellers.forEach(({ seller, items }) => {
    if (Array.isArray(items) && items.length > 0) {
      items.forEach(({ product, ...rest }) => {
        cartProducts.push({
          ...product,
          ...rest,
          seller,
        });
      });
    }
  });
  return cartProducts;
});

const selectSoldOutShippingGroups = createSelector(selectCart, ({ shippingGroups }) => {
  const soldOutShippingGroups = shippingGroups.filter(
    ({ origin }) => origin === SHIPPING_ORIGINS.SOLDOUT,
  );
  return soldOutShippingGroups;
});

const selectUnavailableShippingGroups = createSelector(selectCart, ({ shippingGroups }) => {
  const unavailableShippingGroups = shippingGroups.filter(
    ({ origin }) => origin === SHIPPING_ORIGINS.UNAVAILABLE,
  );
  return unavailableShippingGroups;
});

const selectBreakedShippingGroups = createSelector(selectCart, ({ shippingGroups }) => {
  const breakedShippingGroups = shippingGroups.filter(
    ({ origin }) => origin === SHIPPING_ORIGINS.BREAKED,
  );
  return breakedShippingGroups;
});

const selectWholeShippingGroups = createSelector(selectCart, ({ shippingGroups }) => {
  let wholeShippingGroups = shippingGroups.filter(
    ({ origin }) => !Object.values(SHIPPING_ORIGINS).includes(origin),
  );
  const wholeShippingGroupsLength = wholeShippingGroups.length;
  if (wholeShippingGroupsLength > 0) {
    wholeShippingGroups = wholeShippingGroups.map((unbrokenShippingGroup, index) => ({
      ...unbrokenShippingGroup,
      shippingGroupNumber: index + 1,
      shippingGroupsQuantity: wholeShippingGroupsLength,
    }));
  }
  return wholeShippingGroups;
});

const selectAllShippingGroups = createSelector(selectCart, ({ shippingGroups }) => shippingGroups);

const selectUserRegistration = createSelector(selectApp, ({ user }) => user.registration);
const selectCurrentStorePrefix = createSelector(selectStore, ({ store }) => store.prefix);

const selectHeader = createSelector(selectStore, ({ store }) => {
  const brand = store?.brand?.toLowerCase();
  const defaultName = 'Riachuelo';

  const brands = {
    rchlo: defaultName,
    fanlab: 'FANLAB',
    carters: "Carter's",
  };

  const storeBrandText = brands[brand] || defaultName;

  return {
    brand,
    welcomeMessage: `Bem-vindo à ${storeBrandText}`,
  };
});

// Functions
const getSellerId = (storePrefix) => {
  const enabledStores = process.env.REACT_APP_MARKETPLACE_ON;
  if (enabledStores === 'ALL') {
    return undefined;
  }
  if (enabledStores.split(',').includes(storePrefix)) {
    return undefined;
  }
  return ['RCHLO'];
};

const getPersistedState = (statesToPersist) => {
  const persistedState = {};
  statesToPersist.forEach(({ name, state }) => {
    if (state) {
      Object.assign(persistedState, {
        [name]: state,
      });
    }
  });
  return persistedState;
};

const getBusinessId = (parameters) => {
  const businessId = parameters?.find(({ parameterKey }) => parameterKey === 'BusinessID');
  return businessId?.parameterValue || CORPORATE_CATALOG_BUSINESS_ID;
};

const getLocationId = (parameters) => {
  const businessId = parameters?.find(({ parameterKey }) => parameterKey === 'LocationID');
  return businessId?.parameterValue || LOCATION_ID;
};

export {
  STATES,
  CORPORATE_CATALOG_BUSINESS_ID,
  LOCATION_ID,
  LIMIT,
  SALES_CHANNELS,
  SALES_CHANNELS_LABELS,
  SHIPPING_ORIGINS,
  PICK_UP_METHOD,
  selectApp,
  selectStore,
  selectProducts,
  selectCategory,
  selectFilters,
  selectProduct,
  selectCart,
  selectShipping,
  selectQrCode,
  selectCartProducts,
  selectSoldOutShippingGroups,
  selectUnavailableShippingGroups,
  selectBreakedShippingGroups,
  selectWholeShippingGroups,
  selectAllShippingGroups,
  selectUserRegistration,
  selectCurrentStorePrefix,
  selectHeader,
  getSellerId,
  getPersistedState,
  getBusinessId,
  getLocationId,
};
