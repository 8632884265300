import React from 'react';
import PropTypes from 'prop-types';
import { PICK_UP_METHOD } from '../../../store/utils';
import { ShippingMethodItem } from '../ShippingMethodItem';
import * as S from './ShippingMethodList.styles';

const ShippingMethodList = ({ methods, shippingGroup, onSearchStoresForPickUp }) => {
  return (
    <S.Container>
      {methods.bopis.length > 0 && (
        <ShippingMethodItem
          type="Retira rápido"
          deliveryForecast="A partir de 4h após confirmação de pagamento"
          cost="GRÁTIS"
          onSearchStoresForPickUp={() =>
            onSearchStoresForPickUp(shippingGroup, PICK_UP_METHOD.BOPIS)
          }
        />
      )}
      {methods.pickUpStore.map(({ freightId, description, arrivalDateStore }) => (
        <ShippingMethodItem
          key={freightId}
          type={description}
          deliveryForecast={`A partir de ${arrivalDateStore?.slice(0, 10)}`}
          cost="GRÁTIS"
          onSearchStoresForPickUp={() =>
            onSearchStoresForPickUp(shippingGroup, PICK_UP_METHOD.PICK_UP_STORE)
          }
        />
      ))}
      {methods.shippingToAddress.map(
        ({
          freightId,
          description,
          deliveryEstimateDateExact,
          deliveryEstimateBusinessDay,
          finalShippingCostAsText,
        }) => (
          <ShippingMethodItem
            key={freightId}
            type={description}
            deliveryForecast={`Previsto até ${
              deliveryEstimateDateExact?.slice(0, 10) || deliveryEstimateBusinessDay?.slice(0, 10)
            }`}
            cost={finalShippingCostAsText}
          />
        ),
      )}
    </S.Container>
  );
};

ShippingMethodList.propTypes = {
  methods: PropTypes.shape({
    bopis: PropTypes.array.isRequired,
    pickUpStore: PropTypes.array.isRequired,
    shippingToAddress: PropTypes.array.isRequired,
  }).isRequired,
  shippingGroup: PropTypes.object.isRequired,
  onSearchStoresForPickUp: PropTypes.func.isRequired,
};

export default ShippingMethodList;
