import { dispatchToGtm, handleString } from '../utils';

const tagCategoryCardClick = (categoryName) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:home',
    eventAction: 'clique:card-departamento',
    eventLabel: handleString(categoryName),
  };
  dispatchToGtm(params);
};

export { tagCategoryCardClick };
