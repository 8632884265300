import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectApp, selectStore, selectCart } from '../../../store/utils';
import { tagAddToCart, tagRemoveFromCart, tagViewItem } from '../events';
import { getAffiliation } from '../helpers/product';
import { prepareItemProduct } from '../helpers/product/prepareItemProduct';
import { getItemListName } from '../helpers/search';
import { getProperty, getTagsText, getValue } from '../helpers/text';

export const useGtagProduct = () => {
  const { store } = useSelector(selectStore);
  const { user } = useSelector(selectApp);
  const { cartId } = useSelector(selectCart);
  const history = useHistory();
  const historyState = history.location?.state;

  const handleGtagViewItem = (product, index = historyState?.index || 0) => {
    const keyAttributes = 'key';
    const params = {
      value: product.listPrice,
      store: getValue(store?.label),
      registration_id: getValue(user?.registration),
      item_product_lifestyle: getValue(getProperty(product.attributes, 'lifestyle', keyAttributes)),
      item_product_tag: getTagsText(product.tags),
      original_price: product?.listPrice,
      items: [
        {
          ...prepareItemProduct(
            product,
            keyAttributes,
            getAffiliation(product.sellers[0].seller),
            undefined,
            index,
          ),
          quantity: undefined,
          item_list_name: getItemListName(),
        },
      ],
    };
    tagViewItem(params);
  };

  const handleGtagAddToCart = (product, seller, keyAttributes = 'key', quantity = 1) => {
    if (product) {
      const params = {
        value: product.listPrice,
        store: getValue(store?.label),
        registration_id: getValue(user?.registration),
        item_product_lifestyle: getValue(
          getProperty(product.attributes, 'lifestyle', keyAttributes),
        ),
        item_product_tag: getTagsText(product.tags),
        original_price: product.listPrice,
        cart_id: getValue(cartId),
        items: [
          prepareItemProduct(
            product,
            keyAttributes,
            seller.slug,
            quantity,
            historyState?.index || 0,
          ),
        ],
      };
      tagAddToCart(params);
    }
  };

  const handleGtagRemoveFromCart = (product, seller, keyAttributes = 'key', quantity = 1) => {
    if (product) {
      const params = {
        value: product.listPrice,
        store: getValue(store?.label),
        registration_id: getValue(user?.registration),
        item_product_lifestyle: getValue(
          getProperty(product.attributes, 'lifestyle', keyAttributes),
        ),
        item_product_tag: getTagsText(product.tags),
        original_price: product.listPrice,
        cart_id: getValue(cartId),
        items: [
          prepareItemProduct(
            product,
            keyAttributes,
            seller.slug,
            quantity,
            historyState?.index || 0,
          ),
        ],
      };
      tagRemoveFromCart(params);
    }
  };

  return { handleGtagAddToCart, handleGtagRemoveFromCart, handleGtagViewItem };
};
