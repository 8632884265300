import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Limit.styles';

const Limit = ({ children, ...rest }) => {
  return <S.Container {...rest}>{children}</S.Container>;
};

Limit.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Limit;
