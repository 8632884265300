import styled from 'styled-components';
import { DEVICE } from '../../../styles/styledTheme';

const Option = styled.li`
  padding: 10px 20px;
  border-bottom: 1px solid #ababab;
  font-family: 'Arboria', sans-serif;
  font-size: 18px;
  color: #000000;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: #ffffff;
    background-color: #000000;
  }

  ${DEVICE.Totem} {
    padding: 8px 16px;
    font-size: 16px;
  }
`;

export { Option };
