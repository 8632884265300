/* eslint-disable react-hooks/rules-of-hooks */
import { useLoginDesktop, useExceptionDispatch } from '@estore/gtm-v4';
import { GtmException } from '../utils';

export const tagLogin = (params) => {
  try {
    useLoginDesktop(params);
  } catch (error) {
    throw new GtmException(error, 'tagLogin');
  }
};

export const tagLoginException = (params) => {
  try {
    useExceptionDispatch({
      ...params,
      flow: 'login',
    });
  } catch (error) {
    throw new GtmException(error, 'tagLoginException');
  }
};
