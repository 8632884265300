import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Center.styles';

const Center = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Center;
