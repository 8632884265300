import styled from 'styled-components';
import { ReactComponent as MinusSymbolIcon } from '../../../assets/images/vector/minusSymbolIcon.svg';
import { ReactComponent as PlusSymbolIcon } from '../../../assets/images/vector/plusSymbolIcon.svg';

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: 28px 48px 28px;
  gap: 4px;
`;

const Button = styled.button.attrs({
  type: 'button',
})`
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 4px;
  background-color: #252525;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  display: grid;
  justify-items: center;
  align-items: center;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

const DecrementIcon = styled(MinusSymbolIcon)`
  width: 12px;

  path {
    fill: #ffffff;
  }
`;

const IncrementIcon = styled(PlusSymbolIcon)`
  width: 12px;

  path {
    fill: #ffffff;
  }
`;

const ValueContainer = styled.form`
  width: 48px;
  border-radius: 4px;
  background-color: #f4f4f4;
  display: grid;
  justify-items: center;
  align-items: center;
`;

const Value = styled.span`
  font-size: 18px;
  color: #545454;
`;

const InputValue = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  background-color: #f4f4f4;
  text-align: center;
  font-size: 18px;
  color: #545454;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    transition: 1s all;
    border: 0.5px solid;
  }
`;

export { Container, Button, DecrementIcon, IncrementIcon, ValueContainer, Value, InputValue };
