import { dispatchToGtm, handleString } from '../utils';

const tagSecurityModalButtonClick = (buttonName) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:sacola:modal-seguranca',
    eventAction: 'clique:botao',
    eventLabel: handleString(buttonName),
  };
  dispatchToGtm(params);
};

export { tagSecurityModalButtonClick };
