import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import GlobalStyle from '../styles/GlobalStyle';
import NoInternetConnection from '../containers/NoInternetConnection';
import { ScrollRestoration, PublicRoute, PrivateRoute } from './components';
import { ROUTES } from './utils';
import '../index.css';

const routes = [
  {
    path: ROUTES.ROOT,
    exact: true,
    component: lazy(() => import('../containers/Root')),
    isPrivate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    component: lazy(() => import('../containers/Login')),
    isPrivate: false,
  },
  {
    path: ROUTES.HOME,
    exact: true,
    component: lazy(() => import('../containers/Home')),
    isPrivate: true,
  },
  {
    path: ROUTES.PRODUCTS,
    component: lazy(() => import('../containers/Products')),
    isPrivate: true,
  },
  {
    path: ROUTES.CATEGORY.concat('/:categoryId'),
    component: lazy(() => import('../containers/Category')),
    isPrivate: true,
  },
  {
    path: `/route/:cianoRoute+`,
    component: lazy(() => import('../containers/CategoryByRoute')),
    isPrivate: true,
  },
  {
    path: ROUTES.PRODUCT,
    exact: true,
    component: lazy(() => import('../containers/Product')),
    isPrivate: true,
  },
  {
    path: ROUTES.QRCODE_REPORT,
    exact: true,
    component: lazy(() => import('../containers/QrCodesReport')),
    isPrivate: true,
  },
  {
    path: ROUTES.CHECKOUT,
    exact: true,
    component: lazy(() => import('../containers/Checkout')),
    isPrivate: true,
  },
  {
    path: ROUTES.PAYMENT_INSTRUCTIONS,
    component: lazy(() => import('../containers/PaymentInstructions')),
    isPrivate: true,
  },
  {
    path: ROUTES.REST_SCREEN,
    exact: true,
    component: lazy(() => import('../containers/RestScreen')),
    isPrivate: true,
  },
  {
    path: ROUTES.QR_CODE,
    component: lazy(() => import('../containers/QrCode')),
    isPrivate: true,
  },
  {
    path: ROUTES.NO_INTERNET_CONNECTION,
    component: NoInternetConnection,
    isPrivate: false,
  },
];

const Fallback = () => <div />;

const Routes = () => (
  <Router>
    <Suspense fallback={<Fallback />}>
      <>
        <GlobalStyle />
        <ScrollRestoration />
        <Switch>
          {routes.map(({ path, exact, component, isPrivate }) => {
            if (isPrivate) {
              return <PrivateRoute key={path} path={path} exact={exact} component={component} />;
            }
            return <PublicRoute key={path} path={path} exact={exact} component={component} />;
          })}
        </Switch>
      </>
    </Suspense>
  </Router>
);

export default Routes;
