import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as S from './ProductGroup.styles';
import { tagProductClick, tagProductsImpression } from '../../../gtm';
import { LIMIT } from '../../../store/utils';
import { ProductCard } from '../ProductCard';
import { useGtagProducts } from '../../../gtm/ga4/hooks/useGtagProducts';

const ProductGroup = ({ page, products, categories, onSelect }) => {
  // tagueamento
  const { handleGtagSelectItem } = useGtagProducts();

  useEffect(() => {
    if (products.length > 0) {
      const initialIndex = LIMIT * (page - 1);
      const finalIndex = initialIndex + LIMIT;
      tagProductsImpression(products.slice(initialIndex, finalIndex), initialIndex, categories);
    }
  }, [products, page, categories]);

  const handleProductClick = (product, index) => {
    handleGtagSelectItem(product, index);
    tagProductClick(product, index, categories);
    onSelect(product.skuSubGroup || product.skuGroup, index);
  };

  return (
    <S.Container>
      {products.map((product, index) => (
        <ProductCard
          key={`${product.skuGroup}-${index}`}
          product={product}
          onClick={() => handleProductClick(product, index)}
        />
      ))}
    </S.Container>
  );
};

ProductGroup.propTypes = {
  page: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.shape({
    firstLevel: PropTypes.string,
    secondLevel: PropTypes.string,
    thirdLevel: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
};

export default ProductGroup;
