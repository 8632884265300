import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectApp } from '../../../store/utils';
import { ROUTES } from '../../../routes/utils';

const NetworkChecker = () => {
  // Initial values
  const history = useHistory();
  const { isOnline } = useSelector(selectApp);

  // Check if is offline and navigate to "/no-internet-connection"
  useEffect(() => {
    if (!isOnline) {
      history.push(ROUTES.NO_INTERNET_CONNECTION);
    }
  }, [isOnline]);

  return null;
};

export default NetworkChecker;
