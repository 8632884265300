import { engineApi } from '../../libs/axios';

const additionalInformationsService = () => {
  /**
   * @typedef {Object} GetStoresAvailableForPickUpData
   * @property {string} freightModality
   * @property {string} cartId
   * @param {GetStoresAvailableForPickUpData} data
   */
  const getStoresAvailableForPickUp = async (data) => {
    return engineApi.get('/v4/freights/guest-additional-informations', {
      params: data,
      validateStatus: (status) => status === 200,
    });
  };

  return {
    getStoresAvailableForPickUp,
  };
};

export default additionalInformationsService;
