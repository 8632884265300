function isNetworkErrorPageEnabled() {
  return process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true';
}

function isNetWorkError(error) {
  return error.message === 'Network Error';
}

function shouldShowOfflinePage(error) {
  return isNetworkErrorPageEnabled() && isNetWorkError(error);
}

export const ErrorUtils = {
  isNetWorkError,
  isNetworkErrorPageEnabled,
  shouldShowOfflinePage,
};
