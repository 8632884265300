import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../../store/utils';
import { tagSelectItem, tagViewListItem } from '../events';
import { getAffiliation } from '../helpers/product';
import { prepareItemProduct } from '../helpers/product/prepareItemProduct';
import { getItemListName } from '../helpers/search';
import { getProperty, getTagsText, getValue } from '../helpers/text';

export const useGtagProducts = () => {
  const { products } = useSelector(selectProducts);
  const { search } = window.location;
  const keyAttributes = 'key';

  const handleGtagSelectItem = (product, index) => {
    const params = {
      item_list_name: getItemListName(),
      item_product_lifestyle: getValue(getProperty(product.attributes, 'lifestyle', keyAttributes)),
      item_product_tag: getTagsText(product.tags),
      original_price: product?.listPrice,
      items: [
        {
          ...prepareItemProduct(
            product,
            keyAttributes,
            getAffiliation(product.sellers[0].seller),
            undefined,
            index,
          ),
          quantity: undefined,
          item_list_name: getItemListName(),
        },
      ],
    };
    tagSelectItem(params);
  };

  const handleGtagViewItemList = () => {
    const items = products?.map((product, index) => ({
      ...prepareItemProduct(
        product,
        keyAttributes,
        getAffiliation(product.sellers[0].seller),
        undefined,
        index,
      ),
      quantity: undefined,
      item_list_name: getItemListName(),
    }));
    const params = {
      item_list_name: getItemListName(),
      items,
    };

    tagViewListItem(params);
  };

  useEffect(() => {
    if (!search && products.length > 0) handleGtagViewItemList();
    if (search && products.length > 1) handleGtagViewItemList();
  }, [products]);

  return { handleGtagSelectItem };
};
