export const getAppliedCoupon = (coupons) => {
  return coupons?.[0]?.coupon;
};

export const getValidCoupon = (coupons) => {
  const status = coupons?.[0]?.status;
  if (status === 'SUCCESS' || status === 'SUCCESS_WITHOUT_DISCOUNT') {
    return coupons?.[0]?.coupon;
  }
  return;
};

export const filterValidCoupon = (coupons) => {
  return (
    coupons?.filter(
      ({ status }) => status === 'SUCCESS' || status === 'SUCCESS_WITHOUT_DISCOUNT',
    ) || []
  );
};

export const getMessage = (coupons) => {
  return coupons?.[0]?.reason;
};

export const getErrorMessage = (coupons) => {
  const status = coupons?.[0]?.status === 'INVALID';
  if (status) return coupons?.[0]?.reason;
  return '';
};

export const hasWarning = (coupons) => {
  const status = coupons?.[0]?.status === 'SUCCESS_WITHOUT_DISCOUNT';
  if (status) return coupons?.[0]?.reason;
  return false;
};
