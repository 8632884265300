import styled from 'styled-components';

const Container = styled.li`
  padding: 8px 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: grid;
  justify-items: start;
  gap: 4px;
`;

const RightContainer = styled.div`
  display: grid;
  justify-items: end;
  align-items: end;
`;

const Type = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

const DeliveryForecast = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.black};
`;

const StoreSearch = styled.span`
  color: ${({ theme }) => theme.colors.cerulean};
  text-decoration: underline;
  cursor: pointer;
`;

const Cost = styled.p`
  font-size: 14px;
  color: ${({ isFree, theme }) => (isFree ? theme.colors.greenHaze : theme.colors.black)};
`;

export { Container, LeftContainer, RightContainer, Type, DeliveryForecast, StoreSearch, Cost };
