import styled from 'styled-components';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as ArrowIcon } from '../../../assets/images/vector/verticalArrowIcon.svg';

const Container = styled(MuiFormControl)``;

const Select = styled(MuiSelect).attrs({
  disableUnderline: true,
})`
  .MuiSelect-icon {
    top: calc(50% - 10px);
  }
`;

const Option = styled(MuiMenuItem)``;

const DropdownIcon = styled(ArrowIcon)`
  width: 20px;

  path {
    fill: #707070;
  }
`;

export { Container, Select, Option, DropdownIcon };
