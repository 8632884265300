import styled from 'styled-components';
import MuiFab from '@material-ui/core/Fab';
import { ReactComponent as ArrowIcon } from '../../../assets/images/vector/horizontalArrowIcon.svg';

const Button = styled(MuiFab).attrs({
  color: 'primary',
})``;

const PreviousIcon = styled(ArrowIcon)`
  width: 22px;
  transform: rotateY(180deg);

  path {
    fill: #ffffff;
  }
`;

export { Button, PreviousIcon };
