import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as NoNetworkIllustration } from '../../assets/images/vector/noNetworkIllustration.svg';
import { tagPageLoadError } from '../../gtm';
import { HighlightedContent, Limit, Typography } from '../../shared/components';
import { CheckoutLayout } from '../../shared/layouts';
import { getOnline } from '../../store/ducks/app';
import { ROUTES } from '../../routes/utils';
import services from '../../shared/services';
import * as S from './NoInternetConnection.styles';

const NoInternetConnection = () => {
  // Initial values
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // Initialize the page by tagging the page load error
  useEffect(() => {
    tagPageLoadError('Sem Internet');
  }, []);

  // Check for Internet connection and navigate to "/"
  const checkNetwork = () => {
    setIsLoading(true);
    services.stores
      .getRemoteStoreList()
      .then(() => {
        setIsLoading(false);
        if (process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true') {
          dispatch(getOnline());
        }
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message !== 'Network Error') {
          if (process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true') {
            dispatch(getOnline());
          }
          history.push(ROUTES.HOME);
        }
      });
  };

  return (
    <CheckoutLayout currentStep={0}>
      <Limit>
        <S.PageHeader>
          <Typography as="h2" variation="MAIN_TITLE">
            Sem internet
          </Typography>
          <Typography as="h3" variation="BODY2">
            Este dispositivo não está conectado à Internet e não irá funcionar corretamente
          </Typography>
        </S.PageHeader>
        <HighlightedContent icon={<S.WithoutInternetIcon />}>
          <S.Stack>
            <S.StackMain>
              <Typography as="h3" variation="MAIN_TITLE">
                Dispositivo sem Internet
              </Typography>
              <S.Description>
                Por favor, consulte um de nossos
                <br />
                colaboradores ou tente novamente
              </S.Description>
            </S.StackMain>
            <S.StackIllustration>
              <NoNetworkIllustration />
            </S.StackIllustration>
          </S.Stack>
          <S.ButtonGroup>
            <Button onClick={checkNetwork} disabled={isLoading} size="large" fullWidth>
              {!isLoading && <span>Atualizar</span>}
              {isLoading && <CircularProgress size={32} color="secondary" />}
            </Button>
          </S.ButtonGroup>
        </HighlightedContent>
      </Limit>
    </CheckoutLayout>
  );
};

export default NoInternetConnection;
