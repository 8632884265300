import * as DateFNS from 'date-fns';

export const parseISO = (date) => {
  return DateFNS.parseISO(date);
};

export const format = (date, dateFormat, options) => {
  return DateFNS.format(date, dateFormat, options);
};

export const subDays = (date, amount) => {
  return DateFNS.subDays(date, amount);
};

export const startOfDay = (date) => {
  return DateFNS.startOfDay(date);
};

export const endOfDay = (date) => {
  return DateFNS.endOfDay(date);
};

export const formatISO = (date, options) => {
  return DateFNS.formatISO(date, options);
};
