import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './SoldAndDeliveredBy.styles';

export const SoldAndDeliveredBy = forwardRef(function SoldAndDeliveredBy({ name, ...props }, ref) {
  return (
    <S.Container {...props} ref={ref}>
      Vendido e entregue por: <span>{name}</span>
    </S.Container>
  );
});

SoldAndDeliveredBy.propTypes = {
  name: PropTypes.string.isRequired,
};
