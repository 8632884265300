import styled from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import MuiButton from '@material-ui/core/Button';
import { ReactComponent as CloseThinIcon } from '../../../assets/images/vector/closeThinIcon.svg';

const Container = styled(MuiModal).attrs({
  disableAutoFocus: true,
  disableEnforceFocus: true,
})``;

const Content = styled.div`
  width: 400px;
  height: 200px;
  padding: 40px 48px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  display: grid;
  align-content: space-between;
`;

const CloseModalIcon = styled(CloseThinIcon)`
  width: 16px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const Message = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.tundora};
`;

const ControlContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.disableButtonCancel ? '1fr' : '1fr 1fr')};
  gap: 8px;
`;

const ContainedButton = styled(MuiButton).attrs({
  variant: 'contained',
  color: 'primary',
  size: 'large',
  fullWidth: true,
})`
  .MuiButton-label {
    text-transform: none;
    white-space: nowrap;
  }
`;

const TextButton = styled(MuiButton).attrs({
  variant: 'text',
  color: 'primary',
  size: 'large',
  fullWidth: true,
})`
  .MuiButton-label {
    text-transform: none;
    text-decoration: underline;
    white-space: nowrap;
  }
`;

export {
  Container,
  CloseModalIcon,
  Content,
  Message,
  ControlContainer,
  ContainedButton,
  TextButton,
};
