import { dispatchToGtm, getEncryptedValue, handleString, getSalesChannel } from '../utils';

const tagSellerFieldFilling = (field) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:lightbox-identificacao',
    eventAction: 'interacao:campo',
    eventLabel: `preencheu:${handleString(field)}`,
  };
  dispatchToGtm(params);
};

const tagAuthButtonClick = (registrationId, salesChannel) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:lightbox-identificacao',
    eventAction: 'clique:botao',
    eventLabel: 'acessar',
    dimension7: getEncryptedValue(registrationId),
    dimension23: getSalesChannel(salesChannel),
  };
  dispatchToGtm(params);
};

const tagLoginCallback = (registrationId, salesChannel, isResolved, errorMessage) => {
  const eventLabel = isResolved ? 'sucesso' : `erro:${handleString(errorMessage)}`;
  const params = {
    event: 'event',
    eventCategory: 'estore:lightbox-identificacao',
    eventAction: 'callback:login',
    eventLabel,
    dimension7: getEncryptedValue(registrationId),
    dimension23: getSalesChannel(salesChannel),
  };
  dispatchToGtm(params);
};

export { tagSellerFieldFilling, tagAuthButtonClick, tagLoginCallback };
