import styled from 'styled-components';

const Container = styled.ul`
  list-style: none;

  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.silver};
  }
`;

export { Container };
