import { useQuery } from 'react-query';
import { CianoService } from '../..';

export function useGetRestScreen({ brand, channel, config = {} }) {
  const cianoStoreFront = useQuery(
    ['cianoRestScreen', brand],
    () => CianoService.getImagesRestScreen({ brand, channel }),
    config,
  );

  return {
    ...cianoStoreFront,
    restScreenImages: cianoStoreFront?.data?.data?.response.components?.[0].children || [],
  };
}
