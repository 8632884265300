import ResponseStatus from '../../libs/responseStatus';
import { getOffline } from './app';
import { getSellerId, getBusinessId, getLocationId } from '../utils';
import services from '../../shared/services';

// Action types
const TYPES = {
  SET_PRODUCT_GROUP: 'product/SET_PRODUCT_GROUP',
  SET_PRODUCT_GROUP_SUCCESS: 'product/SET_PRODUCT_GROUP_SUCCESS',
  SET_PRODUCT_GROUP_ERROR: 'product/SET_PRODUCT_GROUP_ERROR',
  SET_PRODUCT: 'product/SET_PRODUCT',
  CLEAR_PRODUCT: 'product/CLEAR_PRODUCT',
};

// Initial state
const initialState = {
  productGroup: null,
  product: null,
  productStatus: ResponseStatus.Idle,
  productError: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PRODUCT_GROUP:
      return {
        ...state,
        productStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroup: action.payload.productGroup,
        productStatus: ResponseStatus.Resolved,
        productError: null,
      };
    case TYPES.SET_PRODUCT_GROUP_ERROR:
      return {
        ...state,
        productGroup: null,
        product: null,
        productStatus: ResponseStatus.Rejected,
        productError: action.payload.error,
      };
    case TYPES.SET_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case TYPES.CLEAR_PRODUCT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
const setProductGroup = () => ({
  type: TYPES.SET_PRODUCT_GROUP,
});

const setProductGroupSuccess = (productGroup) => ({
  type: TYPES.SET_PRODUCT_GROUP_SUCCESS,
  payload: { productGroup },
});

const setProductGroupError = (error) => ({
  type: TYPES.SET_PRODUCT_GROUP_ERROR,
  payload: { error },
});

const setProduct = (product) => ({
  type: TYPES.SET_PRODUCT,
  payload: { product },
});

const clearProduct = () => ({
  type: TYPES.CLEAR_PRODUCT,
});

// Async action creators
/**
 * @param {string} skuGroup
 */
const getProductGroup = (skuGroup) => (dispatch, getState) => {
  const { store } = getState();
  const sellerId = getSellerId(store.store.prefix);
  dispatch(setProductGroup());
  services.products
    .getRemoteProducts({
      businessId: getBusinessId(store?.store?.parameters),
      locationId: getLocationId(store?.store?.parameters),
      seller: sellerId,
      skuGroup,
    })
    .then((response) => {
      const { data } = response.data;
      if (data.response.length === 0) {
        throw new Error('Nenhum dado recebido');
      }
      dispatch(setProductGroupSuccess(data.response[0]));
    })
    .catch((error) => {
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      dispatch(setProductGroupError(error));
    });
};

export {
  reducer as default,
  setProductGroup,
  setProductGroupSuccess,
  setProductGroupError,
  getProductGroup,
  setProduct,
  clearProduct,
};
