import crypto from 'crypto';
import handleValue, {
  convertToLowerCase,
  removeSymbols,
  removePunctuationMarks,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  removeBrlSymbol,
  removeExcessDashes,
  useDashAsSeparator,
} from '../libs/handleValue';
import { SHIPPING_ORIGINS } from '../store/utils';

const GTM_SEPARATOR = ':';

const CheckoutStep = {
  Bag: '1',
  PaymentInstructions: '2',
  QrCode: '3',
  Delivery: '4',
  Payment: '5',
};

const dispatchToGtm = (params) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(params);
};

const getSha1Hash = (data) => {
  const sha1Hash = crypto.createHash('sha1').update(data).digest('hex');
  return sha1Hash;
};

const getEncryptedValue = (param) => {
  if (typeof param !== 'string') {
    return undefined;
  }
  return getSha1Hash(param);
};

const handleString = handleValue(
  convertToLowerCase,
  removeSymbols,
  removePunctuationMarks,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  useDashAsSeparator,
  removeExcessDashes,
);

const handleTagText = handleValue(
  convertToLowerCase,
  removeBrlSymbol,
  removeSymbols,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  useDashAsSeparator,
  removeExcessDashes,
);

const getValue = (param) => {
  if (typeof param !== 'string') {
    return undefined;
  }
  return handleString(param);
};

const getSalesChannel = (value) => {
  if (typeof value !== 'string') {
    return undefined;
  }
  return `canal-${handleString(value)}`;
};

const getCurrencyValue = (number) => number?.toFixed(2)?.replace('.', ',');

const getProperty = (properties, param, key) => {
  let property;
  if (properties) {
    property = properties.find((curProperty) => curProperty[key] === param);
  }
  return property?.value;
};

const getCategory = (category) => category.replace(/\//g, GTM_SEPARATOR);

const getList = (categories) => {
  let list = 'busca';
  if (
    typeof categories !== 'undefined' &&
    categories !== null &&
    Object.keys(categories).length > 0
  ) {
    list = Object.values(categories)
      .filter((category) => typeof category !== 'undefined' && category !== null)
      .at(-1)
      ?.replace(/\//g, GTM_SEPARATOR);
  }
  return list;
};

const getFirstLevelCategory = (categories) => {
  if (!Array.isArray(categories) || categories.length === 0) {
    return undefined;
  }
  const firstLevelCategory = categories.find(({ tree }) => !tree?.includes('/'))?.slug;
  return firstLevelCategory;
};

const getEcommerceExclusivity = (seller) => {
  if (
    typeof seller === 'undefined' ||
    seller === null ||
    !Object.prototype.hasOwnProperty.call(seller, 'id') ||
    !Object.prototype.hasOwnProperty.call(seller, 'businessDisplayName')
  ) {
    return undefined;
  }
  const { id, businessDisplayName } = seller;
  const exclusivityAdverb = id === 'RCHLO' ? 'sim' : 'nao';
  const ecommerceExclusivity = `${exclusivityAdverb}:${handleString(businessDisplayName)}`;
  return ecommerceExclusivity;
};

const getTagsText = (tags) => {
  if (!Array.isArray(tags) || tags.length === 0) {
    return undefined;
  }
  const tagsText = tags.map(({ text }) => `tag:${handleTagText(text)}`).join(';');
  return tagsText;
};

const getCurrencyToNumberFormat = (currencyValue) => {
  const regexExpression = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}|\d{1}))?\D*$/;
  const valueParts = regexExpression.exec(currencyValue);

  if (!valueParts) {
    return '0.00';
  }

  const integerPart = valueParts[1].replace(/\D/, '').replace(/\D/, '');
  const decimalPart = valueParts[2] ? valueParts[2] : '00';

  const number = Number(`${integerPart}.${decimalPart}`);

  const parsedNumber = number.toFixed(2);

  if (Number.isNaN(parsedNumber)) {
    return '0.00';
  }

  return parsedNumber;
};
const createTagEventLabel = (description, forecast, price) => {
  const formattedDescription = handleString(description);

  const formattedPrice = getCurrencyToNumberFormat(price);

  return `${formattedDescription}-${forecast}-${formattedPrice}`;
};

const getEventLabelFromBopis = (bopisList) => {
  let formattedEventLabel = '';

  const hasBopisMethod = !!bopisList[0];

  if (hasBopisMethod) {
    const description = 'retira-rapido';
    const forecast = '4horas';
    const price = '0.00';

    formattedEventLabel = createTagEventLabel(description, forecast, price);
  }

  return [formattedEventLabel];
};

const getEventLabelFromPickUpStore = (pickUpStoreList) => {
  const formattedEventLabels = [];

  pickUpStoreList.forEach(({ description, arrivalDateStore }) => {
    const forecast = arrivalDateStore?.slice(0, 10);
    const price = '0.00';

    formattedEventLabels.push(createTagEventLabel(description, forecast, price));
  });

  return formattedEventLabels;
};

const getEventLabelFromShippingToAddress = (shippingToAddressList) => {
  const formattedEventLabels = [];

  shippingToAddressList.forEach(
    ({
      description,
      deliveryEstimateDateExact,
      deliveryEstimateBusinessDay,
      finalShippingCostAsText,
    }) => {
      const forecast =
        deliveryEstimateDateExact?.slice(0, 10) || deliveryEstimateBusinessDay?.slice(0, 10);

      const price = finalShippingCostAsText;

      formattedEventLabels.push(createTagEventLabel(description, forecast, price));
    },
  );

  return formattedEventLabels;
};

const parseEventLabelsListToString = (formattedEventLabels) => {
  const mergedLists = [];

  formattedEventLabels.forEach((eventLabels) => {
    const hasValue = !!String(eventLabels).length;

    if (hasValue) {
      mergedLists.push(...eventLabels);
    }
  });

  const eventLabelFormatted = mergedLists.join(':');

  return eventLabelFormatted;
};

const getShippingOriginsErrorMessage = (origin) => {
  switch (origin) {
    case SHIPPING_ORIGINS.UNAVAILABLE:
      return 'estoque-inferior-ao-solicitado';
    case SHIPPING_ORIGINS.BREAKED:
      return 'produto-indisponivel-para-regiao';
    default:
      return 'produto-indisponivel';
  }
};

const getDataFromAllMethods = (freightData) => {
  const hasFreightData = Array.isArray(freightData) && freightData.length;

  let formattedFreightDataList = [];

  if (hasFreightData) {
    formattedFreightDataList = freightData.map(({ methods }, index) => {
      const shippingOption = ++index;

      const formattedEventLabels = [];

      if (methods) {
        const { bopis, pickUpStore, shippingToAddress } = methods;

        formattedEventLabels.push(getEventLabelFromBopis(bopis));

        formattedEventLabels.push(getEventLabelFromPickUpStore(pickUpStore));

        formattedEventLabels.push(getEventLabelFromShippingToAddress(shippingToAddress));

        const eventLabelsFormatted = parseEventLabelsListToString(formattedEventLabels);
        const eventAction = `entrega-${shippingOption}-de-${freightData.length}`;

        return { eventAction, eventLabelsFormatted };
      }
    });
  }
  return { formattedFreightDataList };
};

const getErrorMessageFormattedTagFreight = (errorMessage) => {
  return handleString(errorMessage);
};

export {
  GTM_SEPARATOR,
  CheckoutStep,
  dispatchToGtm,
  getSha1Hash,
  getEncryptedValue,
  handleString,
  getValue,
  getSalesChannel,
  getCurrencyValue,
  getProperty,
  getCategory,
  getList,
  getFirstLevelCategory,
  getEcommerceExclusivity,
  getTagsText,
  getEventLabelFromShippingToAddress,
  getEventLabelFromPickUpStore,
  getEventLabelFromBopis,
  getCurrencyToNumberFormat,
  parseEventLabelsListToString,
  getErrorMessageFormattedTagFreight,
  getDataFromAllMethods,
  getShippingOriginsErrorMessage,
};
