import React from 'react';
import PropTypes from 'prop-types';
import * as S from './ShippingMethodItem.styles';

const ShippingMethodItem = ({ type, deliveryForecast, cost, onSearchStoresForPickUp }) => {
  return (
    <S.Container>
      <S.LeftContainer>
        <S.Type>{type}</S.Type>
        {typeof onSearchStoresForPickUp === 'function' ? (
          <S.DeliveryForecast>
            {deliveryForecast}{' '}
            <S.StoreSearch onClick={onSearchStoresForPickUp}>
              Consultar lojas &rsaquo;
            </S.StoreSearch>
          </S.DeliveryForecast>
        ) : (
          <S.DeliveryForecast>{deliveryForecast}</S.DeliveryForecast>
        )}
      </S.LeftContainer>
      <S.RightContainer>
        <S.Cost isFree={cost === 'GRÁTIS'}>{cost}</S.Cost>
      </S.RightContainer>
    </S.Container>
  );
};

ShippingMethodItem.propTypes = {
  type: PropTypes.string.isRequired,
  deliveryForecast: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  onSearchStoresForPickUp: PropTypes.func,
};

export default ShippingMethodItem;
