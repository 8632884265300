import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  justify-items: center;
  gap: 20px;
`;

const IconContainer = styled.div`
  width: 200px;
  height: 200px;
  background-color: #000000;
  border-radius: 50%;
  display: grid;
  justify-items: center;
  align-items: center;

  svg {
    width: 100px;

    path {
      fill: #ffffff;
    }
  }
`;

const Title = styled.h3`
  font-size: 40px;
  line-height: 1.5;
  text-align: center;
`;

const Description = styled.p`
  font-size: 28px;
  line-height: 1.5;
  text-align: center;
`;

export { Container, IconContainer, Title, Description };
