import React from 'react';
import { legalTerms } from './LegalTerms.utils';
import * as S from './LegalTerms.styles';

const LegalTerms = () => {
  return (
    <S.Container>
      {legalTerms.map((legalTerm, index) => (
        <S.Paragraph key={index}>{legalTerm}</S.Paragraph>
      ))}
    </S.Container>
  );
};

export default LegalTerms;
