import ResponseStatus from '../../libs/responseStatus';
import services from '../../shared/services';

// Action types
const TYPES = {
  // Shipping address
  SET_SHIPPING_ADDRESS: 'shipping/SET_SHIPPING_ADDRESS',
  SET_SHIPPING_ADDRESS_SUCCESS: 'shipping/SET_SHIPPING_ADDRESS_SUCCESS',
  SET_SHIPPING_ADDRESS_ERROR: 'shipping/SET_SHIPPING_ADDRESS_ERROR',
  CLEAR_SHIPPING_ADDRESS: 'shipping/CLEAR_SHIPPING_ADDRESS',
  // Additional informations
  SET_ADDITIONAL_INFORMATIONS: 'shipping/SET_ADDITIONAL_INFORMATIONS',
  SET_ADDITIONAL_INFORMATIONS_SUCCESS: 'shipping/SET_ADDITIONAL_INFORMATIONS_SUCCESS',
  SET_ADDITIONAL_INFORMATIONS_ERROR: 'shipping/SET_ADDITIONAL_INFORMATIONS_ERROR',
  CLEAR_ADDITIONAL_INFORMATIONS: 'shipping/CLEAR_ADDITIONAL_INFORMATIONS',
  // General
  CLEAR_SHIPPING: 'shipping/CLEAR_SHIPPING',
};

// Initial state
const initialState = {
  // Shipping address
  shippingAddress: null,
  shippingAddressStatus: ResponseStatus.Idle,
  shippingAddressError: null,
  // Additional informations
  additionalInformations: null,
  additionalInformationsStatus: ResponseStatus.Idle,
  additionalInformationsError: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Shipping address
    case TYPES.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddressStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        shippingAddress: action.payload.shippingAddress,
        shippingAddressStatus: ResponseStatus.Resolved,
      };
    case TYPES.SET_SHIPPING_ADDRESS_ERROR:
      return {
        ...initialState,
        shippingAddress: null,
        shippingAddressStatus: ResponseStatus.Rejected,
        shippingAddressError: action.payload.error,
      };
    case TYPES.CLEAR_SHIPPING_ADDRESS:
      return {
        ...initialState,
        additionalInformations: state.additionalInformations,
        additionalInformationsStatus: state.additionalInformationsStatus,
        additionalInformationsError: state.additionalInformationsError,
      };
    // Additional informations
    case TYPES.SET_ADDITIONAL_INFORMATIONS:
      return {
        ...state,
        additionalInformationsStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_ADDITIONAL_INFORMATIONS_SUCCESS:
      return {
        ...state,
        additionalInformations: {
          searchTrigger: action.payload.searchTrigger,
          freightModality: action.payload.freightModality,
          ...action.payload.additionalInformations,
        },
        additionalInformationsStatus: ResponseStatus.Resolved,
      };
    case TYPES.SET_ADDITIONAL_INFORMATIONS_ERROR:
      return {
        ...state,
        additionalInformationsStatus: ResponseStatus.Rejected,
        additionalInformationsError: action.payload.error,
      };
    case TYPES.CLEAR_ADDITIONAL_INFORMATIONS:
      return {
        ...initialState,
        shippingAddress: state.shippingAddress,
        shippingAddressStatus: state.shippingAddressStatus,
        shippingAddressError: state.shippingAddressError,
      };
    // General
    case TYPES.CLEAR_SHIPPING:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
// Shipping address
const setShippingAddress = () => ({
  type: TYPES.SET_SHIPPING_ADDRESS,
});

const setShippingAddressSuccess = (shippingAddress) => ({
  type: TYPES.SET_SHIPPING_ADDRESS_SUCCESS,
  payload: { shippingAddress },
});

const setShippingAddressError = (error) => ({
  type: TYPES.SET_SHIPPING_ADDRESS_ERROR,
  payload: { error },
});

const clearShippingAddress = () => ({
  type: TYPES.CLEAR_SHIPPING_ADDRESS,
});

// Additional informations
const setAdditionalInformations = () => ({
  type: TYPES.SET_ADDITIONAL_INFORMATIONS,
});

const setAdditionalInformationsSuccess = (
  searchTrigger,
  freightModality,
  additionalInformations,
) => ({
  type: TYPES.SET_ADDITIONAL_INFORMATIONS_SUCCESS,
  payload: { searchTrigger, freightModality, additionalInformations },
});

const setAdditionalInformationsError = (error) => ({
  type: TYPES.SET_ADDITIONAL_INFORMATIONS_ERROR,
  payload: { error },
});

const clearAdditionalInformations = () => ({
  type: TYPES.CLEAR_ADDITIONAL_INFORMATIONS,
});

// General
const clearShipping = () => ({
  type: TYPES.CLEAR_SHIPPING,
});

// Async action creators
// Shipping address
/**
 * @param {string} zipCode
 */
const getShippingAddress = (zipCode) => (dispatch) => {
  dispatch(setShippingAddress());
  services.addressByZipcode
    .getAddressByZipcode({ zipCode })
    .then((response) => {
      const { data } = response;
      dispatch(setShippingAddressSuccess(data?.data));
    })
    .catch((error) => {
      dispatch(setShippingAddressError(error));
    });
};

// Additional informations
const getAdditionalInformations = (searchTrigger, freightModality) => (dispatch, getState) => {
  dispatch(setAdditionalInformations());
  const { cart } = getState();
  services.additionalInformations
    .getStoresAvailableForPickUp({ freightModality, cartId: cart.cartId })
    .then((response) => {
      const { data } = response;
      dispatch(setAdditionalInformationsSuccess(searchTrigger, freightModality, data?.data));
    })
    .catch((error) => {
      dispatch(setAdditionalInformationsError(error));
    });
};

export default reducer;
export {
  // Shipping address
  setShippingAddress,
  setShippingAddressSuccess,
  setShippingAddressError,
  clearShippingAddress,
  getShippingAddress,
  // Additional informations
  setAdditionalInformations,
  setAdditionalInformationsSuccess,
  setAdditionalInformationsError,
  clearAdditionalInformations,
  getAdditionalInformations,
  // General
  clearShipping,
};
