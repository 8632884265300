import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SORT_ORDER_OPTIONS, SORT_ORDER_OPTIONS_CIANO } from '../../../store/ducks/products';
import * as S from './SortBy.styles';

const SortBy = ({ defaultValue, onSelect, hasCianoFilter }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    onSelect(value);
  };

  const options = useMemo(() => {
    if (hasCianoFilter) return SORT_ORDER_OPTIONS_CIANO;
    return SORT_ORDER_OPTIONS;
  });

  return (
    <S.Container>
      <S.Select value={defaultValue} onChange={handleChange} IconComponent={S.DropdownIcon}>
        {options.map(({ label, value }) => (
          <S.Option key={label} value={value}>
            {label}
          </S.Option>
        ))}
      </S.Select>
    </S.Container>
  );
};

SortBy.propTypes = {
  defaultValue: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  hasCianoFilter: PropTypes.bool,
};

export default SortBy;
