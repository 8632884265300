import { useState, useCallback } from 'react';
import { LocationType } from './utils';

const STATES = new Map();

STATES.set('AC', 'Acre');
STATES.set('AL', 'Alagoas');
STATES.set('AP', 'Amapá');
STATES.set('AM', 'Amazonas');
STATES.set('BA', 'Bahia');
STATES.set('CE', 'Ceará');
STATES.set('DF', 'Distrito Federal');
STATES.set('ES', 'Espírito Santo');
STATES.set('GO', 'Goiás');
STATES.set('MA', 'Maranhão');
STATES.set('MT', 'Mato Grosso');
STATES.set('MS', 'Mato Grosso do Sul');
STATES.set('MG', 'Minas Gerais');
STATES.set('PA', 'Pará');
STATES.set('PB', 'Paraíba');
STATES.set('PR', 'Paraná');
STATES.set('PE', 'Pernambuco');
STATES.set('PI', 'Piauí');
STATES.set('RJ', 'Rio de Janeiro');
STATES.set('RN', 'Rio Grande do Norte');
STATES.set('RS', 'Rio Grande do Sul');
STATES.set('RO', 'Rondônia');
STATES.set('RR', 'Roraima');
STATES.set('SC', 'Santa Catarina');
STATES.set('SP', 'São Paulo');
STATES.set('SE', 'Sergipe');
STATES.set('TO', 'Tocantins');

const getStateNameByAbbreviation = (abbreviation) => STATES.get(abbreviation);

const createOption = ({ label, value }) => ({
  label,
  value,
});

const createStatesOptions = (regions) => {
  return regions.map(({ region }) => {
    const label = getStateNameByAbbreviation(region);
    return createOption({ label, value: region });
  });
};

const getSelectedState = (state, regions) => {
  return regions.find(({ region }) => region === state);
};

const createCitiesOptions = (region) => {
  if (!region) return [];
  return region.cities.map((city) => createOption({ label: city, value: city }));
};

const getAvailableStores = (stores, selected) => {
  const availableStores = stores.filter(({ address }) => {
    const { state, city } = address || {};
    if (selected.state && selected.city) {
      return state === selected.state && city === selected.city;
    }
    if (selected.state) {
      return state === selected.state;
    }
    return true;
  });
  return availableStores;
};

const useStoresForPickUp = ({ regions, stores, defaults }) => {
  const [selectedRegion, setSelectedRegion] = useState(() => ({
    state: '',
    city: '',
    ...defaults,
  }));
  const states = createStatesOptions(regions);
  const currentState = getSelectedState(selectedRegion.state, regions);
  const cities = createCitiesOptions(currentState);
  const availableStores = getAvailableStores(stores, selectedRegion);
  const regionOptions = {
    states,
    cities,
  };
  const selectRegion = useCallback((key, value) => {
    setSelectedRegion((previous) => {
      if (key === LocationType.State) {
        return {
          state: value,
          city: '',
        };
      }
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);
  return [regionOptions, selectRegion, availableStores];
};

export default useStoresForPickUp;
