import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const ErrorContainer = styled.div`
  text-align: center;
`;

const ErrorText = styled.span`
  color: #d64c46;
`;

export { Container, ErrorContainer, ErrorText };
