import { catalogApi } from '../../libs/axios';

const filtersService = () => {
  /**
   * @typedef {Object} GetFiltersData
   * @property {string} query
   * @property {number} [page-index]
   * @property {number} [page-size]
   * @param {GetFiltersData} data
   */
  const getRemoteFilters = async (data, config = {}) => {
    const DEFAULT_PAGE_SIZE = 100;
    const DEFAULT_PAGE_INDEX = 0;

    return catalogApi.get('v3/filters', {
      ...config,
      params: {
        'page-size': DEFAULT_PAGE_SIZE,
        'page-index': DEFAULT_PAGE_INDEX,
        ...data,
      },
    });
  };

  return {
    getRemoteFilters,
  };
};

export default filtersService;
