import React from 'react';
import PropTypes from 'prop-types';
import * as S from './CategoryCard.styles';

const CategoryCard = ({ path, label, imageUrl, onClick }) => {
  return (
    <S.Container key={path} onClick={onClick}>
      <S.Image src={imageUrl} alt={label} />
      <S.Title>{label}</S.Title>
    </S.Container>
  );
};

CategoryCard.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CategoryCard;
