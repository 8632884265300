import styled from 'styled-components';
import { ReactComponent as VerticalArrowIcon } from '../../../assets/images/vector/verticalArrowIcon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/vector/closeIcon.svg';
import { DEVICE } from '../../../styles/styledTheme';

const Container = styled.div`
  position: relative;
`;

const LabelContainer = styled.div`
  padding: 15px 20px;
  background-color: ${({ isOpen }) => (isOpen ? '#ffffff' : '#efefef')};
  border: 1px solid ${({ isOpen }) => (isOpen ? '#ababab' : 'transparent')};
  font-size: 16px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  button {
    cursor: pointer;
  }

  ${DEVICE.Totem} {
    padding: 12px 16px;
  }
`;

const Label = styled.span`
  font-size: 18px;
  font-weight: 500;

  ${DEVICE.Totem} {
    font-size: 16px;
  }
`;

const OptionList = styled.ul`
  max-height: 200px;
  position: absolute;
  background-color: #ffffff;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding-left: 0;
  border: 1px solid #ababab;
  list-style: none;
  overflow: auto;
  z-index: 999;
`;

const DownArrow = styled(VerticalArrowIcon).attrs({
  title: 'Abrir',
})`
  width: 20px;
  cursor: pointer;

  path {
    fill: #000000;
  }
`;

const ResetButton = styled.button.attrs({
  type: 'button',
})`
  width: 20px;
  height: 20px;
  background-color: #000000;
  border: none;
  border-radius: 10px;
  display: grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
`;

const ResetIcon = styled(CloseIcon).attrs({
  title: 'Resetar',
})`
  width: 10px;

  path {
    fill: #ffffff;
  }
`;

export { Container, LabelContainer, Label, OptionList, DownArrow, ResetButton, ResetIcon };
