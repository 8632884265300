import { dispatchToGtm, getValue, getCategory } from '../utils';

const tagCategorySelection = (category) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:plp',
    eventAction: 'clique:filtro-categoria',
    eventLabel: getCategory(category),
  };
  dispatchToGtm(params);
};

const tagSubcategorySelection = (subcategory) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:plp',
    eventAction: 'clique:filtro-subcategoria',
    eventLabel: getCategory(subcategory),
  };
  dispatchToGtm(params);
};

const tagFilterSelection = (label, option) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:plp',
    eventAction: 'clique:filtro-produto',
    eventLabel: `${getValue(label)}:${getValue(option)}`,
  };
  dispatchToGtm(params);
};

const tagSortOrderOptionSelection = (sortOrder) => {
  const params = {
    event: 'event',
    eventCategory: 'estore:plp',
    eventAction: 'clique:ordenacao',
    eventLabel: getValue(sortOrder),
  };
  dispatchToGtm(params);
};

const tagMoreProductsButtonClick = () => {
  const params = {
    event: 'event',
    eventCategory: 'estore:plp',
    eventAction: 'clique:botao',
    eventLabel: 'carregar-mais',
  };
  dispatchToGtm(params);
};

export {
  tagCategorySelection,
  tagSubcategorySelection,
  tagFilterSelection,
  tagSortOrderOptionSelection,
  tagMoreProductsButtonClick,
};
