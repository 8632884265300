const DEVICE = {
  Smartphone: '@media screen and (max-width: 576px)',
  Tablet: '@media screen and (max-width: 768px)',
  Totem: '@media screen and (max-width: 1024px)',
  TotemVertical: '@media screen and (max-width: 1080px) and (orientation: portrait)',
  Desktop: '@media screen and (max-width: 1440px)',
};

const styledTheme = {
  fonts: {
    default: ['Arboria', 'sans-serif'].join(','),
  },
  colors: {
    black: '#000000',
    white: '#ffffff',
    tundora: '#484848',
    wildSand: '#f4f4f4',
    brightRed: '#b20000',
    cedarChest: '#C6564C',
    silverChalice: '#a5a1a1',
    miniShaft: '#252525',
    doveGray: '#717171',
    greenHaze: '#008f46',
    gray: '#838383',
    valencia: '#d64c46',
    fairPink: '#ffeded',
    silver: '#cccccc',
    silverChaliceB: '#ababab',
    wildSandB: '#f5f5f5',
    silverChaliceC: '#aaaaaa',
    nobel: '#b5b5b5',
    cerulean: '#0071a3',
  },
  margins: {
    default: '0 140px',
    customBag: '0 30px',
  },
  input: {
    defaults: {
      border: 'solid 1px black',
      borderError: 'solid 1px #b20000',
      borderRadius: '5px',
    },
  },
};

export { DEVICE };
export default styledTheme;
