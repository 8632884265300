import React from 'react';
import * as S from './Toast.styles';

function Toast({ id, open = false, onClose, message, action, key }) {
  return open ? (
    <S.Snackbar id={id} key={key} open={open} onClose={onClose}>
      <S.Alert action={action}>{message}</S.Alert>
    </S.Snackbar>
  ) : null;
}

Toast.displayName = 'Toast';

Toast.defaultProps = {
  open: false,
};

export default Toast;
