import womensDepartmentThumbnailUrl from '../../assets/images/raster/womensDepartmentThumbnail.jpg';
import kidsDepartmentThumbnailUrl from '../../assets/images/raster/kidsDepartmentThumbnail.jpg';
import homeDepartmentThumbnailUrl from '../../assets/images/raster/homeDepartmentThumbnail.jpg';
import newsDepartmentThumbnailUrl from '../../assets/images/raster/newsDepartmentThumbnail.jpg';
import footwearDepartmentThumbnailUrl from '../../assets/images/raster/footwearDepartmentThumbnail.jpg';
import mensDepartmentThumbnailUrl from '../../assets/images/raster/mensDepartmentThumbnail.jpg';
import accessoriesDepartmentThumbnailUrl from '../../assets/images/raster/accessoriesDepartmentThumbnail.jpg';
import geekDepartmentThumbnailUrl from '../../assets/images/raster/geekDepartmentThumbnail.jpg';
import electronicsDepartmentThumbnailUrl from '../../assets/images/raster/electronicsDepartmentThumbnail.jpg';
import outletDepartmentThumbnailUrl from '../../assets/images/raster/outletDepartmentThumbnail.jpg';
import underwearDepartmentThumbnailUrl from '../../assets/images/raster/underwearDepartmentThumbnail.jpg';
import beautyDepartmentThumbnailUrl from '../../assets/images/raster/beautyDepartmentThumbnail.jpg';

const defaultCategories = [
  {
    subType: 'GALERIA',
    children: [
      {
        path: '/category/feminino',
        label: 'Feminino',
        imageUrl: womensDepartmentThumbnailUrl,
      },
      {
        path: '/category/infantil',
        label: 'Infantil',
        imageUrl: kidsDepartmentThumbnailUrl,
      },
      {
        path: '/category/moda-casa',
        label: 'Casa Riachuelo',
        imageUrl: homeDepartmentThumbnailUrl,
      },
      {
        path: '/category/novidades',
        label: 'Novidades',
        imageUrl: newsDepartmentThumbnailUrl,
      },
      {
        path: '/category/calcados',
        label: 'Calçados',
        imageUrl: footwearDepartmentThumbnailUrl,
      },
      {
        path: '/category/masculino',
        label: 'Masculino',
        imageUrl: mensDepartmentThumbnailUrl,
      },
      {
        path: '/category/acessorios',
        label: 'Acessórios e relógios',
        imageUrl: accessoriesDepartmentThumbnailUrl,
      },
      {
        path: '/category/rchlo-geek',
        label: 'Geek',
        imageUrl: geekDepartmentThumbnailUrl,
      },
      {
        path: '/category/eletronicos',
        label: 'Eletrônicos',
        imageUrl: electronicsDepartmentThumbnailUrl,
      },
      {
        path: '/category/outlet',
        label: 'Outlet',
        imageUrl: outletDepartmentThumbnailUrl,
      },
      {
        path: '/category/moda-intima',
        label: 'Moda íntima',
        imageUrl: underwearDepartmentThumbnailUrl,
      },
      {
        path: '/category/beleza-e-perfume',
        label: 'Beleza e perfume',
        imageUrl: beautyDepartmentThumbnailUrl,
      },
    ],
  },
];

export { defaultCategories };
