import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RemainingUnitsUseCase } from '@estore/business-layer';

import { Counter } from '../Counter';
import { SHIPPING_ORIGINS } from '../../../store/utils';
import { getImageUrl } from '../../utils';
import { getShippingErrorMessage } from './ProductItem.utils';
import * as S from './ProductItem.styles';
import { RemainingUnits } from '../RemainingUnits';

const ProductItem = ({
  seller,
  product,
  exception,
  onIncrement,
  onDecrement,
  onRemovalWithConfirmation,
  onReachMax,
}) => {
  // Initial values
  const imageUrl = getImageUrl(product?.medias, 'square', 110);
  const shouldDisableMin = [SHIPPING_ORIGINS.SOLDOUT, SHIPPING_ORIGINS.BREAKED].includes(exception);
  const shouldDisableMax = [
    SHIPPING_ORIGINS.SOLDOUT,
    SHIPPING_ORIGINS.UNAVAILABLE,
    SHIPPING_ORIGINS.BREAKED,
  ].includes(exception);

  const remainingUnitsMessage = useMemo(() => {
    const message = RemainingUnitsUseCase.execute({ remainingUnits: product.remainingUnits });
    return message;
  }, [product]);

  // Increments the quantity of the product
  const handleIncrement = (quantity = 1) => {
    onIncrement({ product, seller }, quantity);
  };

  // Decrements the quantity of the product
  const handleDecrement = (quantity = 1) => {
    onDecrement({ product, seller }, quantity);
  };

  // Removes the product with confirmation
  const handleRemove = () => {
    onRemovalWithConfirmation({ product, seller });
  };

  return (
    <S.Container>
      <S.InnerContainer>
        <S.ImageContainer>
          <S.Image src={imageUrl} />
        </S.ImageContainer>
        <S.Info>
          <S.RemoveIconContainer>
            <S.RemoveIcon onClick={handleRemove} />
          </S.RemoveIconContainer>
          {product?.tags?.map(({ id, text }) => (
            <S.TagText key={id}>{text}</S.TagText>
          ))}
          <S.Name>{product?.title}</S.Name>
          <S.VariationContainer>
            {product?.variations?.map(({ key, name, value }) => (
              <S.Variation key={key}>
                {name}: <S.VariationValue>{value}</S.VariationValue>
              </S.Variation>
            ))}
          </S.VariationContainer>
          <S.RemainingUnitsContainer>
            <RemainingUnits message={remainingUnitsMessage} />
          </S.RemainingUnitsContainer>
          <S.CounterContainer>
            <Counter
              value={product?.quantity}
              minValue={0}
              maxValue={product?.remainingUnits}
              disableMin={shouldDisableMin}
              disableMax={shouldDisableMax}
              onDecrement={handleDecrement}
              onIncrement={handleIncrement}
              onReachMin={handleRemove}
              onReachMax={onReachMax}
            />
          </S.CounterContainer>
          <S.PriceGroup>
            {product?.hasDiscount && (
              <S.PriceContainer>
                <S.ListPrice>{product?.listPriceAsText}</S.ListPrice>
                <S.DiscountText>
                  {`(${(product?.percentageDiscount * 100)?.toFixed(0)}% off)`}
                </S.DiscountText>
              </S.PriceContainer>
            )}
            <S.PriceContainer>
              <S.SalePrice>{product?.totalAsText}</S.SalePrice>
            </S.PriceContainer>
          </S.PriceGroup>
        </S.Info>
      </S.InnerContainer>
      {!!exception && (
        <S.ErrorAlert>{getShippingErrorMessage(exception, product?.remainingUnits)}</S.ErrorAlert>
      )}
    </S.Container>
  );
};

ProductItem.propTypes = {
  seller: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  exception: PropTypes.string,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onRemovalWithConfirmation: PropTypes.func.isRequired,
  onReachMax: PropTypes.func,
};

export default ProductItem;
