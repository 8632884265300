/**
 * The purpose of this library is to allow the creation of pipelines of functions
 * that manipulate a certain value in a certain order
 */

// Main function
const handleValue =
  (...pipeline) =>
  (value) =>
    pipeline.reduce((acc, cur) => cur(acc), value);

// String handlers
const convertToLowerCase = (str) => str.toLowerCase();

const convertToUpperCase = (str) => str.toUpperCase();

const removeSymbols = (str) => str.replace(/[@#$%*+=§°]/g, '');

const removePunctuationMarks = (str) => str.replace(/[,.:;!?'"]/g, '');

const removeAccentMarks = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const removeSuperscriptNumbers = (str) => str.replace(/[⁰¹²³⁴⁵⁶⁷⁸⁹]/g, '');

const removeSubscriptNumbers = (str) => str.replace(/[₀₁₂₃₄₅₆₇₈₉]/g, '');

const removeOrdinalIndicators = (str) => str.replace(/[ªº]/g, '');

const removeBrlSymbol = (str) => str.replace(/R\$\s?/gi, '');

const removeExcessWhitespace = (str) => str.replace(/\s{2,}/g, ' ');

const removeExcessDashes = (str) => str.replace(/-{2,}/g, '-');

const removeExcessUnderscore = (str) => str.replace(/_{2,}/g, '_');

const removeExcessDot = (str) => str.replace(/\.{2,}/g, '.');

const useWhitespaceAsSeparator = (str) => str.replace(/(-|_|\.)/g, ' ');

const useDashAsSeparator = (str) => str.replace(/(\s|_|\.)/g, '-');

const useUnderscoreAsSeparator = (str) => str.replace(/(\s|-|\.)/g, '_');

const useDotAsSeparator = (str) => str.replace(/(\s|-|_)/g, '.');

export default handleValue;
export {
  convertToLowerCase,
  convertToUpperCase,
  removeSymbols,
  removePunctuationMarks,
  removeAccentMarks,
  removeSuperscriptNumbers,
  removeSubscriptNumbers,
  removeOrdinalIndicators,
  removeBrlSymbol,
  removeExcessWhitespace,
  removeExcessDashes,
  removeExcessUnderscore,
  removeExcessDot,
  useWhitespaceAsSeparator,
  useDashAsSeparator,
  useUnderscoreAsSeparator,
  useDotAsSeparator,
};
