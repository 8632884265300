import React from 'react';
import PropTypes from 'prop-types';
import * as S from './BackButton.styles';

const BackButton = ({ onBack }) => {
  return (
    <S.Button onClick={onBack}>
      <S.PreviousIcon />
    </S.Button>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default BackButton;
