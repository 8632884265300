import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import * as S from './Timer.styles';

const Timer = ({ interval, shouldRedirect }) => {
  const [seconds, setSeconds] = useState(interval ? interval.split(':')[1] : '59');
  const [minutes, setMinute] = useState(interval ? Number(interval.split(':')[0]) : 0);

  useEffect(() => {
    if (seconds.toString().length === 1) {
      setSeconds(`0${seconds}`);
    }
  }, [interval, minutes, seconds]);

  useEffect(() => {
    let handleSeconds = null;
    let handleMinutes = null;
    handleSeconds = setInterval(() => {
      setSeconds(() => seconds - 1);
    }, 1000);
    if (minutes !== 0 && seconds === '00') {
      handleMinutes = setInterval(() => {
        setSeconds(() => 59);
        setMinute(() => minutes - 1);
      }, 1000);
    }
    if (minutes === 0 && seconds === '00') {
      clearInterval(handleSeconds);
      clearInterval(handleMinutes);
      shouldRedirect();
    }
    return () => {
      clearInterval(handleSeconds);
      clearInterval(handleMinutes);
    };
  }, [minutes, seconds, shouldRedirect]);

  return (
    <S.CountdownContainer>
      <S.Countdown>{`${minutes}:${seconds}`}</S.Countdown>
    </S.CountdownContainer>
  );
};

Timer.propTypes = {
  interval: Proptypes.string,
  shouldRedirect: Proptypes.func,
};

export default Timer;
