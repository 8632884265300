import React from 'react';
import PropTypes from 'prop-types';
import { CategoryCard } from '../CategoryCard';
import * as S from './CategoryGroup.styles';

const CategoryGroup = ({ categories, onCategoryCardClick }) => {
  return (
    <S.Container>
      {categories.map(({ path, label, imageUrl, target }) => (
        <CategoryCard
          key={path}
          path={path}
          label={label}
          imageUrl={imageUrl}
          onClick={() => onCategoryCardClick({ path, label, target })}
        />
      ))}
    </S.Container>
  );
};

CategoryGroup.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCategoryCardClick: PropTypes.func.isRequired,
};

export default CategoryGroup;
