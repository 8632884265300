import { useSelector } from 'react-redux';
import { SALES_CHANNELS, selectStore } from '../../store/utils';

const useSalesChannels = () => {
  const { salesChannel } = useSelector(selectStore);
  const isWhatsappSalesChannel = salesChannel === SALES_CHANNELS.WHATSAPP;

  return { isWhatsappSalesChannel };
};

export default useSalesChannels;
