import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
`;

const Text = styled.span`
  color: #6e6e6e;
  white-space: nowrap;
`;

const BagContainer = styled.div`
  cursor: pointer;
  position: relative;

  svg {
    height: 36px;
    path {
      fill: #6e6e6e;
    }
  }
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;

export { Container, Text, BagContainer, Badge };
