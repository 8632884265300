import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from '../../../../../';
import { tagFilterSelection } from '../../../../../../../gtm';

const FilterSelect = ({ filter, onSelect }) => {
  const handleSelect =
    ({ filter, option }) =>
    (value) => {
      tagFilterSelection(filter.label, value);
      onSelect?.({ filter, option });
    };

  const handleReset =
    ({ filter }) =>
    () => {
      onSelect?.({ filter });
    };

  return (
    <Select label={filter.label} value={filter.initialValue} onReset={handleReset({ filter })}>
      {filter.filters.map((option) => (
        <Option key={option.id} value={option.id} onSelect={handleSelect({ filter, option })}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

FilterSelect.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  onSelect: PropTypes.func,
};

export default FilterSelect;
