import React from 'react';
import PropTypes from 'prop-types';
import { Step } from './components';
import * as S from './ProgressBar.styles';

const ProgressBar = ({ success }) => {
  return (
    <S.Container>
      <Step name="Sacola" stepNumber="1" isComplete />
      <S.Line />
      <Step name="Finalização" stepNumber="2" isComplete={success} />
    </S.Container>
  );
};

ProgressBar.propTypes = {
  success: PropTypes.bool,
};

export default ProgressBar;
