import styled from 'styled-components';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import { DEVICE } from '../../styles/styledTheme';

const Container = styled(MuiDrawer).attrs({
  anchor: 'right',
})`
  .MuiDrawer-paper {
    width: 480px;
  }

  ${DEVICE.Smartphone} {
    .MuiDrawer-paper {
      width: 100vw;
    }
  }
`;

const Divider = styled.hr`
  margin: auto 24px 24px 24px;
  border-top: 1px solid #cdcdcd;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 40px 0;
`;

const Message = styled.p`
  font-size: 18px;
  text-align: center;
`;

const ErrorAlert = styled(MuiAlert).attrs({
  severity: 'error',
})`
  &.MuiAlert-root {
    border-radius: 0;
  }
`;

const ErrorAlertTitle = styled(MuiAlertTitle)``;

export { Container, Divider, MessageContainer, Message, ErrorAlert, ErrorAlertTitle };
