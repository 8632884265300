import React from 'react';
import PropTypes from 'prop-types';
import { ProductItem } from '../ProductItem';
import * as S from './ProductList.styles';

const ProductList = ({
  seller,
  products,
  exception,
  onIncrement,
  onDecrement,
  onRemovalWithConfirmation,
  onReachMax,
}) => {
  return (
    <S.Container>
      {products.map((product) => (
        <ProductItem
          key={product?.sku}
          seller={seller}
          product={product}
          exception={exception}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onRemovalWithConfirmation={onRemovalWithConfirmation}
          onReachMax={onReachMax}
        />
      ))}
    </S.Container>
  );
};

ProductList.propTypes = {
  seller: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  exception: PropTypes.string,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onRemovalWithConfirmation: PropTypes.func.isRequired,
  onReachMax: PropTypes.func,
};

export default ProductList;
