import styled from 'styled-components';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ paddingY }) => paddingY || 0} 0;
  display: grid;
  justify-items: center;
  align-items: center;
`;

const Spinner = styled(MuiCircularProgress).attrs({
  color: 'primary',
})``;

export { Container, Spinner };
