import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 200ms;
  }

  &:hover::before {
    opacity: 0.2;
  }
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  text-shadow: 1px 1px 2px #000000;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export { Container, Title };
