import React from 'react';
import PropTypes from 'prop-types';
import * as S from './InfoCard.styles';

const InfoCard = ({ title, children }) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfoCard;
