import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Proptypes from 'prop-types';
import { Timer } from './components';
import { useClearCheckoutStores } from '../../hooks';
import { tagSecurityModalButtonClick } from '../../../gtm';
import { ROUTES } from '../../../routes/utils';
import { useIdle, useIdlePopUp } from './hooks';
import * as S from './IdleTimer.styles';

const IdleTimer = ({ routesWithIdlePopUp, shouldNotRedirectRoutes }) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const { isIdle, handleResetTimer, isEnabledIdleFunctions } = useIdle(shouldNotRedirectRoutes);

  const { isEnabledShowIdlePopUp, formattedTimePopUp } = useIdlePopUp(routesWithIdlePopUp);

  const [showPopUp, setShowPopUp] = useState(false);

  const clearCheckoutStores = useClearCheckoutStores();

  const handleRedirect = useCallback(() => {
    clearCheckoutStores();
    if (pathname !== ROUTES.REST_SCREEN) {
      history.push(ROUTES.REST_SCREEN);
    }
  }, [history, clearCheckoutStores]);

  const handleContinueConnected = () => {
    setShowPopUp(false);
    handleResetTimer();
  };

  const handleTimerRedirect = () => {
    tagSecurityModalButtonClick('Finalizar');
    handleRedirect();
  };

  const triggerIdleActions = useCallback(() => {
    const isEnabledIdleActions = isEnabledIdleFunctions() && isIdle;

    if (isEnabledIdleActions) {
      if (isEnabledShowIdlePopUp) {
        setShowPopUp(true);
        return;
      }

      if (process.env.REACT_APP_FF_EXPIRATION_INACTIVITY_REDIRECT === 'true') handleRedirect();
    }
  }, [isIdle, isEnabledShowIdlePopUp, setShowPopUp, isEnabledIdleFunctions, handleRedirect]);

  useEffect(() => {
    triggerIdleActions();
  }, [triggerIdleActions]);

  return (
    <>
      {showPopUp && (
        <S.InactivityContainer>
          <S.TimerContainer>
            <S.TimerCircle>
              <Timer
                interval={formattedTimePopUp}
                shouldRedirect={() => {
                  handleTimerRedirect();
                }}
              />
            </S.TimerCircle>
            <S.Message>
              Para manter a segurança
              <br /> vamos finalizar a sua sacola.
            </S.Message>
          </S.TimerContainer>
          <S.ButtonsContainer>
            <S.Button
              className="continueConnected"
              onClick={() => {
                handleContinueConnected();
              }}
            >
              Continuar conectado
            </S.Button>

            <S.Button
              className="finish"
              onClick={() => {
                handleTimerRedirect();
              }}
            >
              Finalizar
            </S.Button>
          </S.ButtonsContainer>
        </S.InactivityContainer>
      )}
    </>
  );
};

IdleTimer.propTypes = {
  routesWithIdlePopUp: Proptypes.arrayOf(Proptypes.string).isRequired,
  shouldNotRedirectRoutes: Proptypes.arrayOf(Proptypes.string).isRequired,
};

export default IdleTimer;
