import services from '../../shared/services';
import ResponseStatus from '../../libs/responseStatus';
import { getOffline } from './app';
import { getCartParameters, getValidCoupon } from '../../shared/utils';

// Action types
const TYPES = {
  SET_QR_CODE: 'qrCode/SET_QR_CODE',
  SET_QR_CODE_SUCCESS: 'qrCode/SET_QR_CODE_SUCCESS',
  SET_QR_CODE_ERROR: 'qrCode/SET_QR_CODE_ERROR',
  CLEAR_QR_CODE: 'qrCode/CLEAR_QR_CODE',
};

// Initial state
const initialState = {
  qrCodeUrl: '',
  qrCodeStatus: ResponseStatus.Idle,
  qrCodeError: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_QR_CODE:
      return {
        ...state,
        qrCodeStatus: ResponseStatus.Pending,
      };
    case TYPES.SET_QR_CODE_SUCCESS:
      return {
        ...state,
        qrCodeUrl: action.payload.qrCodeUrl,
        qrCodeStatus: ResponseStatus.Resolved,
        qrCodeError: null,
      };
    case TYPES.SET_QR_CODE_ERROR:
      return {
        ...state,
        qrCodeUrl: '',
        qrCodeStatus: ResponseStatus.Rejected,
        qrCodeError: action.payload.error,
      };
    case TYPES.CLEAR_QR_CODE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Sync action creators
const setQrCode = () => ({
  type: TYPES.SET_QR_CODE,
});

const setQrCodeSuccess = (qrCodeUrl) => ({
  type: TYPES.SET_QR_CODE_SUCCESS,
  payload: { qrCodeUrl },
});

const setQrCodeError = (error) => ({
  type: TYPES.SET_QR_CODE_ERROR,
  payload: { error },
});

const clearQrCode = () => ({
  type: TYPES.CLEAR_QR_CODE,
});

// Async action creators
const generateQrCode = () => (dispatch, getState) => {
  const checkoutUrl = process.env.REACT_APP_CHECKOUT_URL;
  const { app, store, cart, shipping } = getState();
  const body = {
    sellerId: app.user?.registration,
    serialNumber: store.serialNumber,
    cartId: cart.cartId,
    salesChannelCode: store.salesChannel,
    cartParameters: getCartParameters([
      { parameterKey: 'COUPON', parameterValue: getValidCoupon(cart?.coupons) },
      { parameterKey: 'FREIGHT', parameterValue: shipping.shippingAddress?.postalCode },
    ]),
  };
  dispatch(setQrCode());
  services.recoveryCarts
    .setRecoveryCart(body)
    .then((response) => {
      const { data } = response.data;
      dispatch(setQrCodeSuccess(data.shortenedLink || `${checkoutUrl}${data.code}`));
    })
    .catch((error) => {
      // If the app goes offline, change the network status accordingly
      if (
        process.env.REACT_APP_FF_NETWORK_ERROR_PAGE === 'true' &&
        error.message === 'Network Error'
      ) {
        dispatch(getOffline());
      }
      dispatch(setQrCodeError(error));
    });
};

export {
  reducer as default,
  setQrCode,
  setQrCodeSuccess,
  setQrCodeError,
  clearQrCode,
  generateQrCode,
};
