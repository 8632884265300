import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectApp } from '../../../store/utils';
import NetworkErrorBoundary from '../../../containers/NetworkErrorBoundary';
import { IdleTimer } from '../../../shared/components';
import { ROUTES } from '../../utils';
import SessionStorage from '../../../libs/sessionStorage';
import { Loader } from '../../../shared/components';
import { STATES } from '../../../store/utils';
import * as S from './PrivateRoute.styles';

const PrivateRoute = ({ path, exact = false, component: Component }) => {
  // Initial values
  const history = useHistory();
  const { isLoggedIn, authEvent } = useSelector(selectApp);
  const sessioStoreValue = SessionStorage.getItem(STATES.STORE);
  const hasStoreSelected = !!sessioStoreValue?.store?.id;
  const isLoading = !isLoggedIn || !hasStoreSelected || !authEvent;

  // Useful variables
  const shouldNotRedirectRoutes = [ROUTES.LOGIN];
  const routesWithIdlePopUp = [ROUTES.CHECKOUT, ROUTES.PAYMENT_INSTRUCTIONS, ROUTES.QR_CODE];
  useEffect(
    function redirectToGetASession() {
      if (authEvent && !isLoggedIn) {
        setRedirectRoute();
        history.push(ROUTES.ROOT);
        return;
      }

      if (authEvent && !hasStoreSelected) {
        setRedirectRoute();
        history.push(ROUTES.LOGIN);
      }
    },
    [authEvent, isLoggedIn, hasStoreSelected],
  );

  function setRedirectRoute() {
    const [, path] = window.location.href.split(window.location.host);
    SessionStorage.setItem(STATES.REDIRECT_ROUTE, path);
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (isLoading) {
          return (
            <S.Container>
              <Loader size={100} />
            </S.Container>
          );
        }
        return (
          <NetworkErrorBoundary {...props}>
            <Component {...props} />
            <IdleTimer
              shouldNotRedirectRoutes={shouldNotRedirectRoutes}
              routesWithIdlePopUp={routesWithIdlePopUp}
            />
          </NetworkErrorBoundary>
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
