import Keycloak from 'keycloak-js';
import { INTERVAL } from '../environments';

const config = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/auth`,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const defaultInitOptions = {
  checkLoginIframeInterval: INTERVAL,
  checkLoginIframe: true,
  onLoad: 'check-sso',
  redirectUri: window.location.href,
};

const keycloak = Keycloak(config);

export default keycloak;
